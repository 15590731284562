.page-admin-panel,
.page-session-engagement,
.page-session-overview,
.page-sessison-virtual,
.page-session-zoom,
.page-session,
.page-virtual-exhibitor {
	/* header styles
	==============================================================================*/

	.custom-modal {
		position: absolute;
	}

	/*sidebar tabs
	==============================================================================*/

	a.action-item {
		display: inline-block;
		padding: 10px 8px;
		text-decoration: none;
		font-size: 14px;
	}

	a.action-item:focus, a.action-item.display {
		outline: none;
		font-weight: bold;
	}

	#action-panel-nav {
		white-space: nowrap;
		overflow: hidden;
		overflow-x: auto;
		background: #fff;
	}

	#action-panel-tabs {
		padding: 20px;
	}

	.action-tab {
		display: none;
	}

	.action-tab.display, .with-action-tab .action-tab {
		display: block !important;
	}

	.action-tab:focus {
		border: none;
		outline: none;
	}

	/** Remove browser outline **/

	.action-tab.display:focus,
	.action-tab.display *:focus,
	.action-tab.display *:focus-within {
		outline: none;
	}

	#action-tab-3 .media img {
		border: 1px solid #ccc;
	}

	/*minimal scroll bars*/

	#action-panel-tabs::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */

	#action-panel-tabs::-webkit-scrollbar-track {
		background: #ddd;
	}

	/* Handle */

	#action-panel-tabs::-webkit-scrollbar-thumb {
		background: #ccc;
	}

	/* Handle on hover */

	#action-panel-tabs::-webkit-scrollbar-thumb:hover {
		background: #bbb;
	}

	#action-panel-nav::-webkit-scrollbar {
		height: 10px;
	}

	/* Track */

	#action-panel-nav::-webkit-scrollbar-track {
		background: #ddd;
	}

	/* Handle */

	#action-panel-nav::-webkit-scrollbar-thumb {
		background: #ccc;
	}

	/* Handle on hover */

	#action-panel-nav::-webkit-scrollbar-thumb:hover {
		background: #bbb;
	}

	.list-group-item:first-child,
	a.list-group-item:first-child {
		margin-top: 0;
	}

	/*recommended videos
	==============================================================================*/

	.video-item-column p {
		font-size: 16px;
		line-height: 18px;
	}

	.video-item {
		position: relative;
	}

	.video-item i.fa-fire {
		position: absolute;
		bottom: 10px;
		left: 10px;
		font-size: 22px;
		color: #fff;
	}

	.video-item img {
		background-color: #ddd;
		border: 1px solid #ccc;
		display: block;
		width: 450px;
	}

	.video-item:hover {
		cursor: pointer;
	}

	.video-info {
		height: 80px;
		overflow: hidden;
	}

	.play-btn {
		display: block;
		width: 60px;
		height: 40px;
		border-radius: 3px;
		text-align: center;
		position: absolute;
		bottom: 10px;
		right: 5px;
		padding: 5px 10px;
		background: rgba(0, 162, 141, 0.7);
		font-size: 20px;
		color: #fff;
	}

	/*list groups session speakers
	==============================================================================*/

	.page .list-group-item.session-speaker a {
		padding: 0 0 0 0;
	}

	.list-group-item.session-speaker .media-left {
		max-width: 80px;
	}

	/*base layout
	==============================================================================*/

	/*** Fix to layout bug on collab ***/

	@media screen and (max-height: 1200px) {
		#virtual-main-content.session-type-collaborative,
		#virtual-main-content.session-type-collaborative-workshop {
			overflow-y: auto;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch;
		}
	}

	iframe {
		margin: 0 0 0 0;
	}

	.video-bg-wrapper {
		position: relative;
	}

	.video-bg-wrapper .video-start-buffer-message {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		padding: 50px 15px;
		background: rgba(200, 200, 200, 0.98);
		letter-spacing: 2px;
		line-height: 1em border 1px solid #444;
	}

	.video-bg-wrapper .video-start-buffer-message p {
		color: #555;
		font-size: 20px;
		line-height: 1.5em;
		text-align: center;
	}

	/*Conversation wall style chat
	==============================================================================*/

	#discussion-filter {
		padding: 5px;
	}

	.switch {
		position: relative;
		display: inline-block;
		width: 60px;
		height: 34px;
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	.label-success {
		background-color: #d92228;
	}

	input:checked + .slider {
		background-color: #2196f3;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #2196f3;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(26px);
		-ms-transform: translateX(26px);
		transform: translateX(26px);
	}

	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}

	.mt-2 {
		margin-top: 20px;
	}

	#panel-upper-content h3:first-child {
		margin-top: 0;
	}

	.like-link.liked i,
	.like-link.liked {
		color: #003357;
	}

	#post-reply-form button {
		margin-top: 0;
	}

	h4.sm-thick-title {
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		margin-top: 20px;
	}

	h5.sm-thick-title {
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
		margin-top: 20px;
	}

	.media-heading {
		font-size: 15px;
		color: #003357;
		font-weight: bold;
		line-height: 18px;
	}

	.media-body {
		padding-right: 25px !important;
	}

	.media a {
		display: block;
		width: 70px;
	}

	/* ==========================================================================
	Live Chat
	========================================================================== */

	#session-chat-history {
		margin-top: 2em;
		padding-right: 5px;
		/* max-height: 400px;
		overflow: hidden;
		overflow-y: auto; */
		position: relative;
	}

	.session-chat-item {
		position: relative;
		margin: 1em 0;
	}

	.session-chat-item.unapproved {
		margin: 0;
		padding: 0;
		width: 1px;
		height: 1px;
		border: none;
		display: none;
	}

	.session-chat-item + .session-chat-item {
		margin-top: 2em;
	}

	.session-chat-item-header .profile-img {
		width: 3.825em;
		height: 3.825em;
		border-radius: 1.9125em;
	}

	.session-chat-item-header .chat-username {
		font-weight: 700;
		font-size: 1em;
		line-height: 1em;
		padding: 0 0 0 1em;
	}

	.session-chat-item-header .like-sessionChat {
		background: transparent;
		margin-left: 5px;
	}

	.session-chat-item-comment {
		font-size: 1em;
		font-weight: 300;
		padding: 0.25em 0;
		position: relative;
	}

	/* Footer
	========================================================================== */

	.session-chat-item-footer:before,
	.session-chat-item-footer:after {
		content: "";
		display: table;
	}

	.session-chat-item-footer:after {
		clear: both;
	}

	/* Reply to chat
	========================================================================== */

	.form-chat-reply {
		position: absolute;
		left: 50px;
		right: 0;
		background: rgba(238, 238, 238, .1);
		padding: 1em;
		border-radius: 4px;
		margin: 0 0 2em 0;
	}


	.session-chat-item.has-replies:before {
		content: "";
		position: absolute;
		display: block;
		width: 2px;
		top: 0;
		bottom: 0;
		left: 30px;
		background: #eee;
		margin-left: -1px;
	}

	.condensed .session-chat-item.has-replies:before {
		left: 17px;
	}

	/* ==========================================================================
	Polls
	========================================================================== */

	.poll-sidebar-wrapper {
		position: relative;
		background: #eee;
	}

	.poll-sidebar-wrapper .question-header legend {
		font-size: 1.3em;
	}

	.poll-sidebar-wrapper .poll-nav .btn {
		font-size: 13px;
		letter-spacing: 0;
	}

	/* .poll-sidebar-content {
		position: absolute;
		top: 65px;
		right: 0;
		bottom: 94px;
		left: 0;
		width: 100%;
		height: auto;
		background: #fff;
		z-index: 99;
		overflow-y: auto;
	} */

	.list-group-item .disabled {
		opacity: 0.5;
		background: #ddd;
		cursor: not-allowed;
		pointer-events: none;
	}

	.poll-sidebar-content .answer-list {
		background: #fff;
	}

	#sidebar-panel-overlay {
		position: absolute;
		z-index: 5000;
		background: #fff;
		left: 105%;
		right: 0;
		bottom: 0;
		top: 0;
		overflow-y: auto;
		width: 100%;
		pointer-events: none;
	}

	/* ==========================================================================
	JS Helpers
	========================================================================== */

	@keyframes pulse {
		0% {
			box-shadow: 0 0 0 0px rgba(0, 51, 87, 0.5);
		}
		100% {
			box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
		}
	}

	@-webkit-keyframes pulse {
		0% {
			box-shadow: 0 0 0 0px rgba(0, 51, 87, 0.5);
		}
		100% {
			box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
		}
	}

	.pulsing {
		animation: pulse 0.5s infinite;
		-webkit-animation: pulse 0.5s infinite;
	}

	/*video overlays*/

	#video-container {
		position: relative;
	}

	#virtual-main-content-container {
		position: relative;
	}

	#interstitial-image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 9999;
		overflow: hidden;
		background: rgba(255, 255, 255, 0.8);
		backdrop-filter: blur(2px);
	}

	#interstitial-image img {
		display: block;
		margin: 20px auto;
		max-width: 800px;
	}
	#interstitial-image:focus {
		outline: none;
	}
	#video-continue {
		background: #000;
		border-color: #000;
		color: #fff;
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 1px;
		text-transform: uppercase;
	}

	/*slider*/

	.rslides {
		position: relative;
		list-style: none;
		overflow: hidden;
		width: 100%;
		padding: 0;
		margin: 0;
	}

	.rslides li {
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		position: absolute;
		display: none;
		width: 100%;
		left: 0;
		top: 0;
	}

	.rslides li:first-child {
		position: relative;
		display: block;
		float: left;
	}

	.rslides img {
		display: block;
		height: auto;
		float: left;
		width: 100%;
		border: 0;
	}

	/* ==========================================================================
	video embeds
	========================================================================== */

	.embed-responsive-16by9 {
		padding-bottom: 56.25%;
	}

	#virtual-content-column .embed-responsive {
		position: relative;
		display: block;
		height: 0;
		overflow: hidden;
	}

	.embed-responsive .embed-responsive-item,
	.embed-responsive embed,
	.embed-responsive iframe,
	.embed-responsive object,
	.embed-responsive video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}

	/*collaborative*/

	#toggle-speaker {
		margin-bottom: 2px;
	}

	#sidebar-panel .live-feed-container {
		margin-bottom: 0;
	}

	.live-feed-title .title {
		font-size: 20px;
	}

	.video-status.no-video {
		position: absolute;
		top: 25%;
		left: 25%;
		width: 50%;
		height: 50%;
		background: #ccc;
		border-radius: 5%;
		color: #000;
	}
	.video-status.no-video span {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		text-align: center;
		font-size: 10em;
		margin: -0.5em;
		color: #666;
		text-shadow: 1px 1px #eee;
	}
	.presenter-feed .video-status.no-video,
	#liveFeed-presenter .video-status.no-video {
		font-size: 0.5em;
	}

	/*new modals*/

	.outer-modal.show .modal-dialog {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	.outer-modal.fade .modal-dialog {
		transition: -webkit-transform 0.3s ease-out;
		transition: transform 0.3s ease-out;
		transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
		-webkit-transform: translate(0, -25%);
		transform: translate(0, -25%);
	}

	.outer-modal {
		background: #fff;
		border: 1px solid #dadada;
		-webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
			0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
			0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	}

	.modal-close {
		font-size: 26px;
		width: 100%;
		text-align: right;
		text-decoration: none !important;
		color: #000;
		position: relative;
		height: auto;
		display: block;
	}

	h3.modal-title {
		margin-top: -40px;
	}

	@media (max-width: 750px) {
		#virtual-main-content {
			width: 100%;
			padding-right: 0;
		}
		#page-session.desktop #session-action-btns {
			margin-right: 0;
			margin-top: 0;
		}
		#session-action-btns a {
			width: auto;
		}
		#page-session.desktop .page-header h3 {
			padding-right: 15px;
		}
		#virtual-main-content {
			height: auto !important;
			top: 0 !important;
		}
	}

	.response-container {
		padding: 10px;
	}

	.admin-responses {
		margin-top: 5px;
		padding: 10px;
		background: #eee;
	}

	.admin-responses p {
		margin: 0 0 10px 0;
		font-size: 12px;
		line-height: 18px;
	}

	.admin-responses {
		display: none;
	}

	#responses-content p {
		margin: 0 0 0 0;
		padding: 15px 0;
		font-size: 14px;
		line-height: 20px;
	}

	/* ==========================================================================
	Live Questions
	========================================================================== */

	#live-questions {
		margin: 0;
		padding: 1px 0;
		background: transparent;
	}

	#live-questions .question {
		margin: 0;
		padding: 15px 10px 5px;
		border: none;
		position: relative;
		margin-bottom: 20px;
		background: transparent;
		box-shadow: none;
	}

	#live-questions .question + .question {
		border-top: 1px dashed rgba(0, 0, 0, 0.2);
		margin-top: 20px;
	}

	#live-questions .question-actions {
		text-align: center;
		font-weight: bold;
		position: relative;
		overflow: hidden;
		width: 35%;
		padding: 3px 0;
		float: right;
		margin-top: 0;
	}

	.votes-count {
		display: block;
		margin-top: 8px;
	}


	#live-questions .responses {
		padding: 3px 15px 0;
		overflow: hidden;
		background: #f5f5f5;
		border-radius: 8px;
		color: #202020;
		height: 30px;
	}

	#live-questions .responses.toggled {
		height: auto;
	}

	#live-questions .responses .response-toggle {
		height: 30px;
		background: transparent;
		color: #202020;
		display: block;
		text-align: center;
	}

	#live-questions .response-item {
		padding: 10px 0;
	}

	#live-questions .responses .response-toggle:hover,
	#live-questions .responses .response-toggle:focus,
	#live-questions .responses .response-toggle:active {
		text-decoration: none;
		background: transparent;
	}

	#live-questions .response-item + .response-item {
		margin-top: 20px;
	}

	#live-questions .responses .response-toggle .fa {
		margin-right: 5px;
	}

	#live-questions .response-item .header {
		padding: 0;
		line-height: 15px;
	}

	#live-questions .response-item .question-content {
		font-size: 1.15em;
	}

	/*size toggling*/

	#toggle-chat-size {
		margin-right: 10px;
		color: #000;
		display: inline-block;
		padding: 3px 8px;
		border-radius: 3px;
	}

	#toggle-chat-size:hover {
		background: #ddd;
	}

	.video-bg-wrapper.small {
		height: 200px;
	}

	.embed-responsive.small {
		padding-bottom: 0;
		height: 200px;
	}

	#session-chat-history.large,
	#session-comments-wrapper.large {
		height: 400px;
		height: 50vh;
	}

	/*Presenter Availability*/

	#presenter-video {
		position: relative;
	}

	#presenter-video.not-live {
		background: url(https://hbint20.mpeventapps.com/includes/images/virtual/presenter-away.jpg)
			no-repeat center center;
		background-size: cover;
		height: 1px !important;
	}

	#presenter-video.live {
		cursor: pointer;
		background: url(https://hbint20.mpeventapps.com/includes/images/virtual/presenter-live.jpg)
			no-repeat center center;
		background-size: cover;
		height: 200px !important;
	}

	#presenter-video.live.isPresenter {
		background: #000;
	}

	p#loading-message {
		position: absolute;
		top: 60px;
		left: 0;
		right: 0;
		z-index: 9999;
		font-family: sans-serif;
		padding: 5px;
		color: #000;
		font-weight: bold;
		text-align: center;
	}

	#btn-go-live {
		border-radius: 0 0 0 0;
		-webkit-border-radius: 0 0 0 0;
	}

	#availability-controls {
		padding: 5px;
	}

	#availability-controls select {
		margin-top: 5px;
	}

	#audio-bar {
		height: 10px;
		background: #e4e4e4;
		margin-top: 5px;
	}

	#audio-bar-inner {
		height: 10px;
		background: #28e828;
		width: 0;
	}

	/* ==========================================================================
	help tutorial
	========================================================================== */

	.help-tutorial {
		padding: 5px 15px;
		text-align: center;
		color: #666;
		font-size: 24px;
	}

	.help-tutorial:hover {
		color: #666;
		text-decoration: none;
	}

	/** Action Panel **/

	#live-questions .question.admin-enabled {
		margin-bottom: 120px;
	}

	#live-questions .action-panel {
		position: absolute;
		left: 0;
		top: 100%;
		right: 0;
		padding: 15px 0;
		margin: 20px 0 0 0;
		background: #eee;
		border-top: 1px solid #ccc;
		box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);
	}

	/*presenter availability ui*/

	#video-control-options {
		padding: 10px;
	}

	#video-control-options select {
		margin-top: 5px;
	}

	#presenter-video {
		position: relative;
		text-align: center;
	}

	#presenter-video-wrapper {
		text-align: center;
		background: #000;
	}

	.presenter-video-item {
		display: inline-block;
		margin: 0 0 0 0;
		width: 100%;
		height: 200px;
	}

	.presenter-video-item.half {
		display: inline-block;
		width: 49%;
		margin: 0 0 0 0;
		height: 100px;
	}

	#online-status {
		color: #fff;
		font-style: italic;
	}

	#join-feed {
		font-weight: bold;
		color: #fff;
		text-decoration: underline;
	}

	#local-feed {
		position: relative;
	}

	#local-feed p {
		position: absolute;
		left: 0;
		bottom: 0;
		font-size: 12px;
		margin: 0 0 0 0;
		color: #fff;
		font-weight: bold;
		z-index: 200;
		background: rgba(0, 0, 0, 0.5);
		padding: 3px;
	}

	.presenter-video-item {
		background: #000;
	}

	.presenter-video-item video {
		left: 0;
	}

	p#loading-message {
		position: absolute;
		top: 60px;
		left: 0;
		right: 0;
		z-index: 9999;
		font-family: sans-serif;
		padding: 5px;
		color: #000;
		font-weight: bold;
		text-align: center;
	}

	#btn-go-live {
		border-radius: 0 0 0 0;
		-webkit-border-radius: 0 0 0 0;
	}

	#availability-controls {
		padding: 5px;
	}

	#availability-controls select {
		margin-top: 5px;
	}

	#audio-bar {
		height: 10px;
		background: #e4e4e4;
		margin-top: 5px;
	}

	#audio-bar-inner {
		height: 10px;
		background: #28e828;
		width: 0;
	}

	/*Theatre mode*/

	#enable-theatre {
		text-decoration: none;
	}

	body.theatre-on #sidebar-left {
		display: none;
	}

	body.theatre-on #global-container {
		padding-left: 0;
	}

	body.theatre-on #global-container {
		max-width: 100%;
	}

	/* end Theatre mode*/

	/*end responsive container*/

	.poll-sidebar-wrapper {
		z-index: 99;
	}

	.live-feed-wrapper {
		position: relative;
	}
	.live-feed-wrapper[data-feed="users"] {
		background: none;
	}
	.toggle-feed-size {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}
	.session-type-collaborative-workshop .toggle-feed-size {
		max-height: 100px;
	}
	@media (max-width: 900px) {
		#interstitial-image img {
			max-width: 100%;
		}
	}

	/* new theatre on styles */

	body.theatre-on #sidebar-left {
		display: none;
	}

	body.theatre-on #global-container {
		padding-left: 0;
	}

	body.theatre-on #global-container {
		max-width: 100%;
	}

	#sidebar-panel::-webkit-scrollbar-track {
		background: transparent;
	}
	/* Condensed
	========================================================================== */
	/** Form **/

	.condensed form .row,
	.condensed form .col-thin {
		padding: 0;
	}

	.condensed form label {
		display: none;
	}

	.condensed form .container-input,
	.condensed form .container-submit {
		width: 100%;
		flex: none;
		max-width: 100%;
	}

	.condensed form .container-submit {
		margin-top: 0.5em;
	}
	/** Chat block **/

	.condensed .session-chat-item {
		font-size: 0.85em;
		padding: 0;
		margin: 1.5em 0;
		position: relative;
	}
	/** Chat block Header **/

	.condensed .session-chat-item .session-chat-item-header {
		min-height: 20px;
		margin-bottom: 0.3em;
		line-height: 1.5em;
	}

	.condensed .session-chat-item-header .profile-img {
		width: 2.5em;
		height: 2.5em;
		border-radius: 1.25em;
	}

	.condensed .session-chat-item .session-chat-item-header .chat-username {
		line-height: 1.25em;
		display: block;
	}

	.condensed .chat-username .chat-company {
		display: block;
		font-style: italic;
		font-weight: normal;
		opacity: 0.75;
	}

	.condensed .chat-username .chat-company:before {
		display: none;
	}
	/** Chat block Comment **/

	.condensed .session-chat-item-comment {
		font-size: 16px;
		margin: -5px 0 0 45px;
		position: relative;
		margin-top: -5px;
	}
	/** Chat block Footer **/


	.condensed
	.session-chat-item
	.session-chat-item-footer
	.chat-relativeTimestamp {
		display: none!important;
	}
	/** Chat block reply form **/

	.condensed .form-chat-reply {
		font-size: 0.85em;
		left: 0;
	}

	.condensed .btn.btn-sm {
		padding: .15rem .15rem;
		border: none;
	}

	.condensed div.chatname {
		font-size: .9em;
	}

	.condensed .form-chat-reply [type="submit"],
	.condensed .form-chat-reply [type="text"] {
		font-size: 0.9em;
	}

	.condensed .session-chat-item-replies {
		margin: 1.5em 0 0 -1em;
	}
	.condensed .session-chat-item-replies .session-chat-item {
		margin: 1em 0;
	}

	.condensed .session-chat-item-reply .profile-img {
		left: 1em;
	}

	.condensed .session-chat-item-reply .session-chat-item-comment {
		margin-bottom: 1em;
	}

	.condensed .form-chat-reply {
		margin-top: 20px;
	}

	.minimized-likes {
		display: none;
	}

	.condensed .minimized-likes {
		display: block;
		position: absolute;
		top: 20px;
		left: 20px;
	}

	.condensed .minimized-likes i {
		margin-right: 5px;
		display: inline-block;
	}

	.minimized-likes-inner {
		position: absolute;
		bottom: -5px;
		right: -5px;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background: rgba(0, 0, 0, 0.6);
		color: #fff;
		text-align: center;
		line-height: 15px;
		font-size: 10px;
	}
	button span {
		margin-left: 3px;
	}

	.condensed button span {
		display: none;
	}

	.star-rating a {
		width: 25px;
		height: 25px;
		margin-right: 10px;
		float: none;
		display: inline-block;
	}

	/* ==========================================================================
	New Rating Module
	========================================================================== */
	.rating-block {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
	.rating-header {
		text-align: center;
	}
	.rating-block .rating-header h3 {
		text-transform: uppercase;
		letter-spacing: 2px;
		color: #000;
		font: 700 1.2em/1.25em 'Lato', sans-serif;
		margin: 0 0 .5em;
	}
	.rating-block .rating-header p {
		font: 400 1em/1.4em 'Lato', sans-serif;
		color: #666;
		margin: 0;
	}
	.rating-block .rating-content {
		display: none;
	}
	.rating-block .star-rating {
		display: block;
		padding: 10px 0;
		text-align:  center;
	}
	.rating-block .star-rating a {
		font-size: 30px;
		width: 40px;
		height: 40px;
		color: #ccc;
		float: none;
		display: inline-block;
	}
	.rating-block .star-rating a.active .fa-star {
		font-weight: 700;
		color: gold;
	}
	.rating-block .star-rating a:hover {
		color: #aaa;
	}
	.rating-block .rating-content {
		display: none;
		background: #eee;
		box-shadow: inset 0 0 10px rgba(0,0,0,.25);
		border: 1px solid #ccc;
		margin: 0 -16px;
	}
	.rating-block .rating-content label {
		display: block;
		margin: 20px 2.5% 0;
	}
	.rating-block .rating-content textarea {
		width: 95%;
		margin: 10px 2.5%;
		padding: 10px 1%;
		border: 1px solid #ccc;
	}
	.rating-block .rating-content .btn {
		width: 95%;
		display: block;
		margin: 10px 2.5% 20px;
		text-align: center;
	}
	.custom-modal {
		position: absolute;
	}
	.rating-block .star-rating a:focus span{
		color:#000;
	}



	@media (min-width: 751px) {
		#mobile-flyout {
			display: none !important;
		}
	}
	@media (max-width: 750px) {
		#mobile-flyout {
			display: inline-block !important;
		}
		#sidebar-column.expanded {
			top: 60px !important;
		}
		#page-virtual-exhibitor #sidebar-column.expanded {
			margin-top: 115px !important;
		}
		.session-chat-item-footer {
			display: block !important;
		}
	}

	/* ZOOMING ISSUE ON SESSIONCHAT/HYBRID - do NOT change if using hybrid apps*/
	.emojionearea,
	.emojionearea.form-control {
		font-size: 16px !important;
		width: auto!important;
	}

	/*** Custom loader ***/
	.loader {
		--duration: 3s;
		width: 48px;
		height: 44px;
		margin: 1rem auto;
		display: block;
		position: relative;
	}
	.loader:before {
		content: "";
		width: 6px;
		height: 6px;
		border-radius: 50%;
		position: absolute;
		display: block;
		background: var(--primary-bc);
		top: 20px;
		left: 2px;
		transform: translate(0, 0);
		-webkit-animation: dotTriangle var(--duration)
			cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
		animation: dotTriangle var(--duration)
			cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
	}
	.loader svg {
		display: block;
		width: 100%;
		height: 100%;
	}
	.loader polygon {
		fill: none;
		stroke: var(--secondary-bc);
		stroke-width: 10px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-dasharray: 145 76 145 76;
		stroke-dashoffset: 0;
		-webkit-animation: pathTriangle var(--duration)
			cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
		animation: pathTriangle var(--duration)
			cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
	}
	@-webkit-keyframes pathTriangle {
		33% {
			stroke-dashoffset: 74;
		}
		66% {
			stroke-dashoffset: 147;
		}
		100% {
			stroke-dashoffset: 221;
		}
	}

	@keyframes pathTriangle {
		33% {
			stroke-dashoffset: 74;
		}
		66% {
			stroke-dashoffset: 147;
		}
		100% {
			stroke-dashoffset: 221;
		}
	}
	@-webkit-keyframes dotTriangle {
		33% {
			transform: translate(0, 15px);
		}
		66% {
			transform: translate(35px, 0);
		}
		100% {
			transform: translate(-10px, -18px);
		}
	}
	@keyframes dotTriangle {
		33% {
			transform: translate(17px, 8px);
		}
		66% {
			transform: translate(17px, -10px);
		}
		100% {
			transform: translate(0, 0);
		}
	}

	/** chat slide in **/
	.fade-move,
	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
		transform: scaleY(0.01) translate(30px, 0);
	}

	.fade-leave-active {
		position: absolute;
	}

	#session-nav-tabContent .tab-pane {
		min-height: 400px;
		padding-top: 15px;
	}

	#sidebar-column {
		height: calc(100vh - 55px);
		overflow-y: auto;
		overflow-x: hidden;
	}

	.close-sidebar {
		display: block;
	}

	@media (max-width: 991px) {
		#session-nav-tabContent .tab-pane {
			min-height: 0;
		}

		#sidebar-column {
			height: auto;
		}
	}

	.addeventatc {
		z-index: 999!important;
	}

	.session-chat-item-replies .minimized-likes-inner {
		display: none;
	}
}