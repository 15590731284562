.page-home-full {
	/* begin smaller navigation tiles (pulse) for virtual */
	.page-contents {
		background: transparent;
	}

	#hf-nav-container {
		margin-top: 95px;
	}

	#hf-session-container {
		overflow: auto;
	}

	body #hf-nav-container .tile {
		padding: 20px 20px 20px 20px !important;
		width: 164px;
		height: 140px;
		border-radius: 6px;
	}

	#events-scroll {
		height: calc(100vh - 110px);
		overflow-y: auto;

		.sidebar-logo {
			max-height: 80px;
			filter: grayscale(1) invert(1);
		}
	} 

	body #hf-nav-container .tile-icon {
		/* To center icon, subtract half width from top & left */
		margin: -25px 0 0 -25px;
		/* background is set in _tiles.cfm */
		/* background: rgba(0,0,0,.2); */
		border-radius: 50%;
		-webkit-border-radius: 50%;
		font-size: 22px;
		width: 50px;
		height: 50px;
		line-height: 50px;
	}

	body #hf-nav-container .tile-icon.tile-hPosition-left {
		left: 0%;
		margin-left: 15px;
	}

	body #hf-nav-container .tile-icon.tile-hPosition-right {
		left: auto;
		right: 0%;
		margin-left: 0;
		margin-right: 15px;
	}

	body #hf-nav-container .tile-icon.tile-vPosition-top {
		top: 0;
		margin-top: 15px;
	}

	body #hf-nav-container .tile-icon.tile-vPosition-bottom {
		top: auto;
		bottom: 0;
		margin-top: 0;
		margin-bottom: 15px;
	}

	body #hf-nav-container .tile-footer {
		text-align: left;
		padding-left: 20px;
		padding-right: 20px;
		bottom: 20px;

	}
}