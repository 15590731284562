.relative {
	position: relative;
}

.o-hidden {
	overflow: hidden;
}

.sticky-top {
	top: rem(15px);
}
// Left navigation sidebar -------------------------------------------
.sidebar {
	position: fixed;
	z-index: 999;
	top: 0;
	bottom: 0;
	transition: transform 0.4s ease;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

#sidebar-left {
	right: auto;
	left: 0;
	width: 250px;
	overflow-y: hidden;
	height: 100%;
	-o-transition: 0.2s ease-in-out transform;
	-webkit-transition: 0.2s ease-in-out transform;
	transition: 0.2s ease-in-out transform;

	&:hover {
		overflow-y: auto;
	}
}

#close-sidebar-background-mask {
	opacity: 0;
	visibility: hidden;
	transition: 0.2s ease-in-out all;
}

#left-panel {

	.list-group {
		margin-bottom: 0px;
	}

	a {
		&.list-group-item {
			font-size: 13px;
			line-height: 22px;
			border: none;
		}
	}

	.list-group-item {
		display: block;
		font-weight: 400;
		position: relative;
		min-height: 45px;
		padding-left: 50px;
		background: transparent;
		border: none;

		span {
			position: absolute;
			top: 50%;
			left: 10px;
			width: 26px;
			height: 26px;
			text-align: center;
			line-height: 25px;
			font-size: 18px;
			margin-top: -13px;
		}

		img {
			position: absolute;
			top: 50%;
			left: 10px;
			width: 26px;
			height: 26px;
			text-align: center;
			line-height: 25px;
			font-size: 18px;
			margin-top: -13px;
			padding: 0!important;
		}

	}
}

#search-wrap {
	display: none;
}

// General Page Layout -------------------------------------------

body.sidebar-in {
	position: fixed;
	overflow: hidden;
}

#global-container {
	max-width: 1440px;
	padding-left: 250px;
	margin: 0 auto;
	position: relative;
}

.page-contents {
	padding: 20px;
	/* setting a min height to help prevent noticeable flicker on load for pages light on content...  */
	min-height: 500px;
}

#skip-to {
    margin: 0 15px;
}

// .page-header {
// 	border-radius: 4px 4px 0 0;
// }

// Header to be used with left sidenav -------------------------------------------

#app-header {
	#left-sidebar-toggle {
		display: none;
	}

	a {
		display: inline-block;
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		font-size: 20px;
	}
}

#searchForm {
	display: inline-block;
	position: relative;
}

#search {
	border: none;
	outline: none;
	border-radius: 30px 30px 30px 30px;
	font-size: 14px;
	padding-top: 4px;
	padding-bottom: 4px;
}

#search-btn {
	position: absolute;
	top: 0;
	right: 2px;
	font-size: 12px;
	z-index: 10;
}

#app-header {
	background: transparent;
	padding: 6px 15px 10px 10px;
}

#app-header a.header-btn {
	display: inline-block;
	position: relative;
	font-size: 12px;
	border-radius: 20px;
	-webkit-border-radius: 20px;
	padding: 7px 10px;
	width: auto;
	height: auto;
	line-height: 100%;
	margin-left: 5px;
	top: -2px;
	text-decoration: none;
}

#app-header a#header-edit-profile {
	padding: 14px;
	top: 8px;
}

.header-btn span.count {
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: rgba(0,0,0,.4);
	font-size: 11px;
	color: #fff;
	text-align: center;
	line-height: 15px;
	margin-left: 5px;
	top: -1px;
}

// ADA layout link -------------------------------------------

.skip-link {
	position: absolute;
	top: -240px;
	left: 0;
	color: white !important;
	background: #353535;
	padding: 8px;
	z-index: 9999;
}

.skip-link:focus {
	top: 0;
}