.page-sessison-virtual {
	.embed-responsive-item {
		position: relative;
	}

	#s-live-container .video-js .vjs-tech {
		pointer-events: none;
	}

	.live-tag {
		position: absolute;
		bottom: 3px;
		right: 3px;
		padding: 3px 6px;
		background: black;
		color: white;
		text-transform: uppercase;
		font-size: 12px;
		display: none;
	}

	#s-live-vid-wrapper {
		min-height: 200px;
		overflow: hidden;
		background: #000000;
	}

	#vid-processing {
		background: #000;
		color: #fff;
		font-size: 18px;
		z-index: 1000;
		text-align: center;
		line-height: 120px;
		overflow: hidden;
	}

	#s-live-vid-wrapper p {
		color: #fff;
		margin-top: 23%;
	}

	#countdown-timer {
		font-size: 32px;
		font-weight: bold;
		color: #fff;
	}


	/*.video-js .vjs-control-bar {
		background: transparent;
	}*/

	.video-js .vjs-big-play-button {
		font-size: 3em;
		line-height: 60px;
		height: 60px;
		width: 60px;
		margin-left: -30px;
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 0;
		margin-top: -30px;
		cursor: pointer;
		opacity: 1;
		border: 0.06666em solid #fff;
		background-color: #2B333F;
		background-color: rgba(43, 51, 63, 0.3);
		border-radius: 0.3em;
		transition: all 0.4s;
		border-radius: 50%;
	}

	.playback-issues {
		background: #7b7b7b;
		border-radius: 2px;
		padding: 5px 0;
		color: #fff;
		font-weight: normal;
	}

	.playback-issues a {
		color: #fff;
		text-decoration: underline;
	}

	#additional-help ul {
		list-style-type: none;
		margin: 0 0 0 0;
		padding: 0 0 0 0;
	}

	#additional-help li {
		font-size: 13px;
		font-weight: normal;
		list-style-type: none;
		padding-top: 10px;
		color: #666;
	}

	/* overwriting styles added to light.css ...  */
	.disabled {
		background: transparent!important;
		border-color: transparent!important;
		color: inherit!important;
	}
}