.page-photobooth {
	position: relative;

	#webcam-wrapper, #approval-wrapper, #final-image-container {
		width: 400px;
		height: 400px;
		margin: 0 auto;
		position: relative;
	}

	#final-image-container {
		display: none;
		margin-top: 60px;
	}

	#approval-wrapper {
		display: none;
	}

	#webcam {
		object-fit: cover;
		object-position: center;
	}

	#stickers-gallery {
		height: 400px;
		overflow-y: scroll;
	}

	#backgrounds-gallery img,
	#stickers-gallery img,
	#frames-gallery img {
		display: block;
		width: 100px;
		height: 100px;
		margin-right: 5px;
		margin-bottom: 5px;
		border-radius: 2px;
		display: inline-block;
		background: #d9d9d9;
	}

	#backgrounds-gallery img:hover,
	#stickers-gallery img:hover,
	#frames-gallery img:hover {
		cursor: pointer;
	}

	#photo-container{
		width: 400px;
		height: 400px;
		position: relative;
		background: white;
		margin: 0 auto;
	}

	#photo-preview {
		display: block;
		width: 400px;
		height: 400px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}
	.frame {
		display: block;
		width: 400px;
		height: 400px;
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
	}

	.sticker {
		position: absolute;
		top: 0;
		left: 0;
		max-width: 400px;
		z-index: 10;
	}

	#stickers-gallery img {
		border: 1px solid #ccc;
		opacity:.5;
	}

	#stickers-gallery img.active {
		opacity: 1;
	}

	.not-visible {
		display: none;
	}

	#js-retake-photo, #js-submit-photo, #js-take-photo {
		position: absolute;
		display: block;
		z-index: 999;
		width: 60px;
		height: 60px;
		background: rgba(0,0,0,.8);
		color: #fff;
		text-align: center;
		line-height: 55px;
		border-radius: 50%;
		border: 1px solid rgba(255,255,255,.3);
		font-size: 22px;
	}

	#js-take-photo {
		bottom: 20px;
		left: 50%;
		margin-left: -30px;
	}

	#js-retake-photo {
		left: 20px;
		bottom: 20px;
	}

	#js-submit-photo {
		right: 20px;
		bottom: 20px;
	}

	#js-take-photo:hover,
	#js-retake-photo:hover,
	#js-submit-photo:hover {
		background: rgba(0,0,0,1);
	}

	#counter {
		position: absolute;
		width: 100px;
		height: 100px;
		font-size: 100px;
		line-height: 100px;
		z-index: 999;
		color: #fff;
		font-weight: bold;
		text-align: center;
		top: 50%;
		left: 50%;
		margin-top: -50px;
		margin-left: -50px;
		text-shadow: 0 0 20px #000000;
		display: none;
	}

	.moveable-control-box {
		display:none;
	}

	#page-photobooth h2 {
		font-weight: bold;
	}

	#page-photobooth .btn {
		font-weight: bold;
		font-size: 16px;
	}

	#page-photobooth .nav-tabs .nav-link {
		font-size: 18px;
	}

	#page-photobooth .nav-tabs .nav-item.show .nav-link,
	#page-photobooth .nav-tabs .nav-link.active {
		background: transparent;
	}

	.select {
		max-width: 400px;
		margin: 0 auto;
	}
}