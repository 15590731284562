/**
 * Description: Chat Room Styles
 * Version: 1.0.0
 * Last update: 2020/07/13
 * Author: Ryan Taggart <ryan.t@meetingplay.com>
 */


.page-chat-room {

	/* ==========================================================================
   Main Chat Comment Container
   ========================================================================== */
	#page-chat-room {
		position: relative;
	}

	#page-chat-room .scrollable {
		overflow-x: hidden;
	}

	.chatRoomForm-wrapper {
		background: #eee;
		border-bottom: 1px solid #ccc;
		overflow: hidden;
	}

	.chatRoomForm-wrapper textarea {
		border-radius: none;
		max-width: 100%;
		width: 100%;
		height: 100px;
		padding: 1em;
	}

	.chatRoomForm-wrapper .btn {
		margin: 1em;
	}

	.comment-list,
	.reply-container {
		clear: both;
	}

	#chat-room-container form {
		overflow: hidden;
		padding: 1em;
		border-radius:8px;
		background: #eee;
		border-bottom: 1px solid #ccc;
	}

	#chat-room-container form.disabled,
	#chat-room-container form.disabled:hover {
		opacity: .8;
		pointer-events: none;
		cursor: not-allowed;
	}

	.reply-container {
		padding: 1em;
	}

	#chat-room-container .comment-detail-overlay form {
		padding: 1em 2em;
	}
	#chat-room-container .chat-comment-body{
		padding-right:60px;
	}


	/* ==========================================================================
	Main Chat Comments
	=========================================================================== */

	.chat-comment {
		border-bottom: 1px solid #ccc;
		position: relative;
		cursor: pointer;
	}
	.chat-comment.isActive{
		background: #f2f2f2;
	}
	.chat-comment.isActive::after {
		content: '';
		position: absolute;
		right: -19px;
		top: 50px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 50px 0 50px 50px;
		border-color: transparent transparent transparent #f2f2f2;
		clear: both;
	}
	.chat-comment:after {
		content: "\f105";
		font-family: "Font Awesome 5 Pro";
		font-weight: 300;
		position: absolute;
		top: 50%;
		right: 0.75em;
		margin-top: -0.5em;
		font-size: 2em;
	}

	.comment-detail-overlay .chat-comment {
			cursor: initial;
		overflow-y: auto;
		height: 76vh;
	}

	.comment-detail-overlay .chat-comment:after {
		display: none;
	}


	/* Header
	========================================================================== */

	.chat-comment-header {
		overflow: hidden;
		padding: 1.5em 1.5em;
	}

	.chat-comment-header a {
		display: block;
		text-decoration: none;
		color: #000;
	}

	.chat-comment-header a:active,
	.chat-comment-header a:focus {
		box-shadow: none;
		text-decoration: none;
	}

	.chat-comment-header .profile-photo {
		width: 3em;
		height: 3em;
		border-radius: 1.5em;
		background-size: cover;
		float: left;
		margin-right: 1.5em;
	}

	.chat-comment-header h3 {
		margin: 0 0 0.2em;
	}

	.chat-comment-header .timestamp {
		font-style: italic;
		margin: 0;
		font-size: 0.8em;
		color: #333
	}


	/* Comment
	========================================================================== */

	.chat-comment-body {
		padding: 0 1em 0;
	}
	.chat-comment-header h6{
		margin-bottom:0;
		padding-top: 0.5rem;
	}
	.details{
		display:inline-block;
	}

	/* Comment Footer
	========================================================================== */

	.chat-comment-footer {
		padding: 1em 1.5em;
		margin-bottom: 0.5em;
			font-size: .8rem;
	}

	.chat-comment-footer .fal {
		margin-right: 1em;
	}


	/* Moderation button
	========================================================================== */

	.toggle-chatRoomCommentStatus {
		margin-left: 1em
	}


	/* ==========================================================================
	Chat Details Overlay
	========================================================================== */

	.chat-details {
		position: absolute;
		top: 50px;
		height: 100%;
		width: 100%;
		z-index: 99;
		background: #fff;
		background: rgba(0, 0, 0, 0.95);
		border: 1px solid #ccc;
		overflow-y: auto;
		padding-bottom: 60px;
	}

	.chat-details .chat-comment-header {
		background: rgba(0, 0, 0, 0.1);
		padding: 1.5em;
	}

	.chat-details .chat-comment-body {
		padding: 0.75em;
		font-size: 2em;
		max-height: 45%;
		overflow-y: auto;
	}


	/* Details Footer
	========================================================================== */

	.chat-details .chat-comment-footer {
		background: rgba(0, 0, 0, 0.1);
	}

	.chat-details .chat-comment-footer textarea {
		height: 2.525em;
		min-height: 2.525em;
		line-height: 1.525em;
		float: left;
		width: 75%;
		max-width: 75%;
		margin-right: 5%;
	}

	.chat-details .chat-comment-footer button {
		float: right;
		width: 20%;
	}

	.chat-details-reply {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		padding: 1em 1em 1em 5em;
		position: relative;
	}

	.chat-details-reply h6 {
		font-size: .8rem;
		margin-bottom: 0;
		padding-top: .5rem;
	}
	.chat-details-reply+.chat-details-reply {
		margin-top: 1em;
	}

	.chat-details-reply .profile-photo {
		width: 3em;
		height: 3em;
		background-size: cover;
		border-radius: 1.5em;
		position: absolute;
		left: 1em;
		top: 1em;
	}

	.chat-details-reply h3 {
		margin: 0;
	}

	.chat-details-reply .timestamp {
		position: absolute;
		top: 1em;
		right: 1.5em;
		font-style: italic;
		color: #333;
		font-size: 0.8em;
	}

	.chat-details-reply .reply {
		margin: 0.25em 0 0;
	}


	/* Close button
	========================================================================== */

	.chat-details-close {
		position: absolute;
		right: 0.5em;
		top: 0.5em;
		width: 2.5em;
		height: 2.5em;
		border-radius: 1.125em;
		background: #4a4f55;
		border-color: #aaa;
		color: #fff;
		cursor: pointer;
	}

	.chat-details-close .fa {
		line-height: 2.5em;
		text-align: center;
		display: block;
	}

	.comment-detail-overlay {
		transform: translateX(100%);
		transition: transform 0.25s ease-in-out;
		border-radius: 8px;
		position: fixed;
		width: 29%;
		max-width: 550px;
		background: #fff;
	}

	.comment-detail-overlay.isVisible {
		transform: translateX(0);
		transition: transform 0.25s ease-in-out;
		border: 1px solid #ccc;
	}

	.comment-detail-overlay .chat-details-reply .timestamp {
		position: relative;
		top:0;
		left:0;
	}
	#form_replyToComment{
		padding: 15px;
	}
	#chat-room-replies{
		min-height: 100%;
	}
	.chat-details-reply .btn-danger{
		margin-left: 0;
		margin-top: 15px;
	}
	.scrolledDown  .comment-detail-overlay {
		top: 80px;
	}
	.scrolledDown .comment-detail-overlay .chat-comment {
		cursor: initial;
		overflow-y: auto;
		height: 90vh;
	}

	@media(max-width:1400px){
		.comment-detail-overlay{
			width:44%;
			max-width: 100%;
		}
	}
	@media(max-width:1350px){
		.comment-detail-overlay{
			width:37%;
		}
	}
	@media(max-width:768px){
		.comment-detail-overlay {
			transform: translateX(100%);
			transition: transform 0.25s ease-in-out;
			border-radius: 8px;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100vh;
			margin:0;
			background: #fff;
			z-index: 9999;
		}
		.comment-detail-overlay .chat-comment{
			height:100%;
		}
	}
}