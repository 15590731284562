.page-conversation-wall,
.page-followed-items {
	#skip-to {
		overflow: visible;
	}

	.feed-item {
	   margin-bottom: 10px !important;
	}
	.card
	 {
	   border-color: #dee2e6;
	   border-radius: 0;
	}

	.card-body {
	   padding: 0;
	}

	.list-group-item.fetch-comment {
	   border: none;
	}

	.feed-item-loader {
		position: absolute;
		content: "";
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 2px;
		background-color: #2d71d6;
		animation: loading 1s linear infinite;
		display: none;
	}

	@keyframes loading {
		from {left: -200px; width: 30%;}
		50% {width: 30%;}
		70% {width: 70%;}
		80% { left: 50%;}
		95% {left: 120%;}
		to {left: 100%;}
	}

	.follower-tag {
		display: inline-block;
		background: rgb(43, 43, 43);
		color: #fff;
		padding: 7px;
		border-radius: 8px;
		margin-bottom: 3px;
	}

	.follower-tag:hover {
		cursor: pointer;
		color: #fff;
	}

	.follower-img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		margin-right: 5px;
	}

	.follower-handle {
		display: inline-block;
		font-size: .8rem;
		margin: 0 0 0 0;
	}

	/* The ribbons */
	.corner-ribbon{
		width: 200px;
		background: #39d;
		position: absolute;
		top: 25px;
		left: -50px;
		text-align: center;
		line-height: 20px;
		letter-spacing: 1px;
		color: #f0f0f0;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		font-size: 12px;
	}

	.corner-ribbon.shadow{
		box-shadow: 0 0 3px rgba(0,0,0,.3);
	}

	/* Different positions */

	.corner-ribbon.top-right{
		top: 25px;
		right: -65px;
		left: auto;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
	}
}