/**
* Custom Event Styles
**/
#global-container {
    max-width: 1440px; 
}

.sidebar-logo-insert {
    border-radius: 0.375rem;
    height: 175px;
    overflow: hidden;
}

.rounded-corners {
    border-radius: 0.375rem;
}

.btn-link {
    text-decoration: none;
}

.accordion-item {
    border-left: none;
    border-right: none;
    border-top: none;
}

.fs-sm { 
    font-size: 12px;
}

.accordion-button:not(.collapsed) {
    box-shadow: none;
}

.logo-block {
    height: 150px;
    border-radius: 0.375rem;
} 

.event-blocks {
    .badge {
        border-radius: 10px;
    }

    .card {
        border: 1px solid #fff;
    }

    .badge {
        &.bg-primary {
            background: #edf4ff!important;
            color: #3687fe;
        }
    }
}

.page-header {
    margin-top: 20px;
}

.venue {
    transition: .2s all;

    &:hover {
        cursor: pointer;
        border: 1px solid #dedede; 
    }

    &.active {
        border: 1px solid #3687fe;
    }
}

#events-scroll-container {
    border-radius: 8px;
}

#events-scroll {
    position: relative;
    .list-group-item {
        border: none;

        .date {
            line-height: 100%;
        }
    }
}  

.loader {
    display: block;
    width: 40px;
    margin: 30px auto;
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 900;
    background: rgba(255,255,255,.5);
}


.text-primary {
    color: #005eb8!important;
} 


.btn-primary {
    background: #005eb8!important;

    &:hover {
        background: #004692!important;
    }

    &:focus {
        background: #002f6c!important;
    }
} 

.alert.border-left {
    border-left: 6px solid #005eb8;
    border-radius: 0 0 0 0;
}