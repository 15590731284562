.cropit-preview {
	display: block;
	background-color: #f8f8f8;
	border: 5px solid #000;
	border-radius: 3px;
	margin: 0 auto 30px;
	min-width: 200px;
	width: 200px;
	height: 200px;
}

.cropit-preview-image-container {
	cursor: move;
}

.cropit-preview-background {
	opacity: .2;
	cursor: auto;
}

.mobile-user-note {
	padding-right: 40px;
}

.cropit-preview-image-container {
	cursor: move;
}

.cropit-preview-background {
	opacity: .2;
	cursor: auto;
}

.image-size-label {
	margin-top: 10px;
}

#edit-profile img {
  max-width: none;
}

#photo {
	margin-bottom: 30px;
}

.file-choose-btn {
	display: block;
	text-align: center;
	margin: 0 auto 30px;
	&:hover {
		cursor: pointer;
	}
}

.photo-hide {
	display: none;
}

.toggle {
	border-radius: 0px;
	.toggle-handle {
		border-radius: 0px;
	}
}