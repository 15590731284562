/**
 * Tabs
 */

.nav-tabs {
	display: block;
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
}
.nav-tabs .nav-link {
	display: inline-block;
	font-size: rem(14px);
	border-left: none;
	border-right: none;
	border-top: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus-visible {
	background: transparent;
	font-weight: bold;
    border: none;
    border-bottom: 4px solid $info;
	outline: none;
}

.mh-500 {
	min-height: 500px;
}

/**
 * Scrollbars
 */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-track {
	background: #e5e5e5;
}

::-webkit-scrollbar-thumb {
	background: #b7b7b7;
}

/**
 * Images
 */

.sq-img-50 img {
	display: block;
	width: 50px;
	height: 50px;
}

.image-placeholder {
	font-weight: 300;
	font-size: 20px;
	line-height: 48px;
	height: 50px;
	width: 50px;
	text-align: center;
	color: #555;
	border: 1px solid #ccc;
	background: #ddd;
	text-shadow: 1px 1px #eee;
}

.circle-img-thumbnail {
	min-width: 50px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: #000;

	&.--lg {
		min-width: 100px;
		width: 100px;
		height: 100px;
	}
}

.circle-img-placeholder {
	min-width: 50px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	color: #555;
	background: #ddd;
	text-shadow: 1px 1px #eee;
	font-weight: 300;
	font-size: 20px;
	line-height: 48px;
}

.img-block {
	display: block;
	max-width: 100%;

	&.--med {
		width: 400px;
	}

	&.--rounded {
		border-radius: 4px;
	}
}

/**
 * Typography
 */
.fs-small {
	font-size: .8rem;
}

/**
 * Navigations that overflow-x
 */
.nav-overflow-x {
	white-space: nowrap;
	overflow-x: auto;
}

/**
 * Standard Links / Anchors
 */
a {
	text-decoration: none;
}

.with-hover:hover {
	cursor: pointer;
}

/**
 * List Groups
 */
.li-btn-container-2 {
	// to prevent flex from squeezing icon buttons
	min-width: 93px;
}

.list-group-item .list-group-item-primary {
	// making the dividers font size smaller
	font-size: rem(14px);
}

.list-group-item {

	&.--arrow {
		position: relative;
		padding-right: 40px;

		&::after {
			content: '\f054';
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			font-family: "Font Awesome 6 Pro";
			font-weight: 300;
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
		}
	}

	&.--plus {
		position: relative;
		padding-right: 40px;

		&::after {
			content: '\f055';
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			font-family: "Font Awesome 6 Pro";
			font-weight: 300;
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
		}
	}

}

/**
 * Forms
 */
.form-label {
	font-size: .9rem;
	font-weight: bold;
}
.form-label-hint {
	font-size: .9rem;
}

.required::after {
	content: ' *';
	color: red;
}

.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 35px!important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 35px!important;
}


/**
 * Icons
 */
i.fx {
	min-width: 20px;
	text-align: center;
}

/**
 * Followed States
 */
.isActive .fal {
	font-weight: 700;
}

/**
 * Cards
 */
.mh-100 {
	min-height: 100px;
}
.card {
	&.--shadow {
		border: none;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	}
}

.mp-card {
	min-height: rem(250px);
	color: $dark;
	transition: $transition1;

	.mp-card__img {
		height: rem(100px);
		position: relative;
		overflow: hidden;

		img {
			position: absolute;
			top: 0px;
			left: 0px;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	&__desc {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&:hover {
		border-color: $primary;
	}
}

/**
 * Notes
 */
.note-item {
	border-left: 4px solid $customcolor1;
}

/*** Card Tile used by admin tools ***/
.card.card-tile {
	margin-bottom: 1em;
	box-shadow: 0 2px 8px rgba(100, 100, 100, 0.1);
	position: relative;
	margin-left: 1.5rem;
}

.card.card-tile .card-body,
.card.card-tile .card-header {
	padding: 1rem 1rem 1rem 2rem;
}

.card-icon {
	position: absolute;
	width: 3rem;
	height: 3rem;
	border-radius: 1.5rem;
	left: -1.5rem;
	top: 1rem;
	margin: -1px 0 0 -1px;
	line-height: 3rem;
	color: #fff;
	text-align: center;
	box-shadow: 0 2px 8px rgba(100, 100, 100, 0.1);
	border: 1px solid #ccc;
	font-size: 1.25rem;
	z-index: 1;
}

.card-tile-count {
	font-size: 3rem;
	font-weight: bold;
	line-height: 1em;
}

.card-tile-description {
	font-size: 0.9rem;
	margin: 0.25rem 0 0 0;
	font-weight: 700;
	opacity: 0.7;
}

.card.card-tile .card-cta {
	position: absolute;
	right: 0.5rem;
	top: 0.5rem;
	width: auto;
	font-size: 0.7em;
	padding: 0.5em 1em;
}

/*** Vue Table ***/
.vtl-checkbox-th {
	width: 1%;
}

.vtl-both {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
}

.vtl-sortable {
	cursor: pointer;
	background-position: right;
	background-repeat: no-repeat;
	padding-right: 30px !important;
}

.vtl-asc {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==);
}

.vtl-desc {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=);
}

.vtl-loading-mask {
	position: absolute;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	flex-flow: column;
	transition: opacity 0.3s ease;
}

.vtl-loading-content {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.vtl-card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
}

select {
	width: auto;
	border: 1px solid #cccccc;
	background-color: #ffffff;
	height: auto;
	padding: 0;
	margin-bottom: 0;
}

.vtl-table {
	width: 100%;
	margin-bottom: 1rem;
	color: #212529;
	border-collapse: collapse;
}

th {
	text-align: inherit;
}

tr {
	display: table-row;
	vertical-align: inherit;
	border-color: inherit;
}

.vtl-table-bordered thead td,
.vtl-table-bordered thead th {
	border-bottom-width: 2px;
}

.vtl-table thead th {
	vertical-align: bottom;
	color: #fff;
	background-color: #343a40;
	border-color: #454d55;
	border-bottom: 2px solid #dee2e6;
}

.vtl-table-bordered td,
.vtl-table-bordered th {
	border: 1px solid #dee2e6;
}

.vtl-table td,
.vtl-table th {
	padding: 0.75rem;
	vertical-align: top;
	border-top: 1px solid #dee2e6;
	vertical-align: middle;
}

.vtl-table-hover tbody tr:hover {
	color: #212529;
	background-color: rgba(0, 0, 0, 0.075);
}

.vtl-table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

.vtl-table-responsive > .vtl-table-bordered {
	border: 0;
}

.vtl-row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 0 -15px;
}

.vtl-pagination {
	margin: 2px 0;
	white-space: nowrap;
	justify-content: flex-end;
	display: -ms-flexbox;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 0.25rem;
}

// character limiter
.limiter-100 {color:#aaa}
.limiter-90 {color:#afa09f}
.limiter-70 {color:#b49695}
.limiter-60 {color:#b98d8b}
.limiter-50 {color:#be8381}
.limiter-40 {color:#c47977}
.limiter-30 {color:#c9706d}
.limiter-30 {color:#ce6663}
.limiter-10 {color:#d35c59}
.limiter-0 {color:red}

/* Global notification widget $notification $global
  =================================================================== */

  .notification-banner {
	padding: 1em 4em 1em 5em;
	border-radius: 4px;
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
	background: #fff;
	opacity: .95;
	border: 1px solid #ddd;
}

.notification-banner .banner-light {
	position: absolute;
	inset: 0.8em auto 0.8em .8em;
	width: 8px;
	border-radius: 8px;
	background: #aaa;
}

.notification-banner .banner-icon {
	position: absolute;
	inset: 50% auto auto 25px;
	margin: -.5em 0 0 0;
	font-size: 2em;
	font-weight: 300;
	color: #aaa;
	width: 50px;
	line-height: 1em;
	text-align: center;
}

.notification-banner .banner-icon:before {
	font-family: 'Font Awesome 5 Pro';
	content: '?';
}

.notification-banner .banner-message {
	font-size: 1.15em;
	font-weight: 700;
	color: #000;
}

.notification-banner .banner-link {
	display: block;
	max-width: 100%;
	background: #aaa;
	margin-top: 5px;
	color: #fff;
	font-weight: 700;
}

.notification-banner .banner-hide {
	position: absolute;
	inset: 50% 10px auto auto;
	border: none;
	color: #aaa;
	line-height: 1em;
	background: none;
	font-size: 1.75em;
	margin: -.5em 0 0 0;
}

/*** Notification Types ***/
.notification-banner.notification-banner-info .banner-light,
.notification-banner.notification-banner-info .banner-link {
	background: #50A0B5;
	border-color: #50A0B5
}

.notification-banner.notification-banner-info .banner-icon:before {
	content: '\f05a';
	color: #50A0B5
}

.notification-banner.notification-banner-danger .banner-light,
.notification-banner.notification-banner-danger .banner-link {
	background: #dc3545;
	border-color: #dc3545
}

.notification-banner.notification-banner-danger .banner-icon:before {
	content: '\f071';
	color: #dc3545
}

/*** Notification Admin ***/
.notification-list .list-item {
	position: relative;
	padding: 1em;
	border: 1px solid #ccc;
	border-left: 5px solid #ccc;
	background: #fff;
	font-size: .9em;
}

.notification-list .list-item.isPast {
	padding-top: .5em;
	padding-bottom: -5em;
	font-size: .75em;
}

.notification-list .list-item.type-info {
	border-left-color: #17a2b8;
}

.notification-list .list-item.type-danger {
	border-left-color: #dc3545;
}

.notification-list .list-item.inactive:empty {
	display: none;
}

.notification-list .list-item h4 {
	font-size: 1.25em;
	line-height: 1.5em;
	margin: 0 200px 0 0;
	padding-right: 1em;
}

.notification-list .list-tem.isPast h4 {
	opacity: .65;
}

.notification-list .list-item p {
	margin: 0;
}

.notification-list .list-item .action-buttons {
	position: absolute;
	top: 1em;
	right: 1em;
	width: 200px;
}