/*
* Custom Fonts
* Change as you need per build
* Imported into globals.scss
*/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,400&display=swap');
$font1: "Open Sans", sans-serif;

/*
* Site Typography
*/

body {
	font-size: rem(16px);
	font-family: $font1;
}

h1,
.h1 {
	@include fluid-type(32px, 40px);
}

h2,
.h2 {
	@include fluid-type(28px, 32px);
}

h3,
.h3 {
	@include fluid-type(24px, 28px);
}

h4,
.h4 {
	@include fluid-type(20px, 24px);
}

h5,
.h5 {
	@include fluid-type(16px, 20px);
}

h6,
.h6 {
	@include fluid-type(14px, 16px);
}