@use '../partials/mixins' as *;

/* ==========================================================================
   In App Maping Functionlity
   ========================================================================== */
.page-floor-plans,
.page-floor-plan,
.page-location {

	.page-contents {
		position: relative;

		@include mq('sm') {
			min-height: 79vh !important;
		}
	}

	#global-container {
		overflow: hidden;
	}
	.map-wrapper {
		height: 350px;
		width: 100%;
		position: relative;
	}
	.map-wrapper>#map-holder {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
	}

	.location-types {
		background: #eee;
		box-shadow: inset 0 0 5px #ddd;
		padding: 10px 15px;
		margin: 0;
		overflow: hidden;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
		white-space:nowrap;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
	}
	.location-types>a {
		font: 700 10px/24px sans-serif;
		display: inline-block;
		padding: 0 15px;
		border-radius: 6px;
		margin: 0 10px 0 0;
		background: #fff;
		text-decoration: none;
	}
	.location-types>a.isActive {
		background: #000!important;
		color: #fff;
		opacity: 1;
	}
	.pickup-select-wrapper {
		padding: 15px;
	}
	#map-notification .alert {
		margin-bottom: 0;
	}


	/* ==========================================================================
	   Location Map Marker
	   ========================================================================== */

	/* Map
	   ========================================================================== */
	.floorplan-wrapper {
		max-height: 100%;
		min-height: 100%;
	}
	.floorplan-wrapper img {
		display: block;
		margin: 0 auto;
		width: 100%;
		height: auto;
	}

	/* Markers
	   ========================================================================== */
	.marker {
		position: absolute;
		width: 30px;
		height: 30px;
		margin: -26px 0 0 -15px;
	}
	.marker__icon {
		width: 30px;
		height: 30px;
		font-size: 30px;
		text-align: center;
		color: #004C7D;
	}
	.marker__content {
		display: none;
		position: absolute;
		top: -27px;
		left: 50%;
		width: 88px;
		margin: 0 0 0 -50px;
		text-align: center;
		background: rgba(47, 130, 171, 0.85);
		border: 1px solid rgb(47, 130, 171);
		padding: 5px;
		border-radius: 4px;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-size: .8em;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: #fff;
	}

	.floorplan-zoom {
		position: absolute;
		top: 175px;
		right: 25px;
		width: 40px;
		font-size: 20px;
		line-height: 40px;
		text-align: center;
		color: #000;
		border-radius: 4px;
		background: rgba(255,255,255,.9);
		border: 1px solid rgba(0, 0, 0,.6);
	}

	.floorplan-zoom-out {
		position: absolute;
		top: 175px;
		right: 70px;
		width: 40px;
		font-size: 20px;
		line-height: 40px;
		text-align: center;
		color: #000;
		border-radius: 4px;
		background: rgba(255,255,255,.9);
		border: 1px solid rgba(0, 0, 0,.6);
		display: none;
	}

	/* Bottom Overlay
	   ========================================================================== */
	.bottom-overlay {
		position: absolute;
		bottom: 0;
		left: 0px;
		width: 100%;
		z-index: 100;
		min-height: 55px;
		height: 55px;
		overflow: hidden;
		background: #fff;
		background: rgba(255,255,255,.9);
		box-shadow: 0 0 10px rgba(0,0,0,0.4);
		transition-duration: .2s;
	}
	.bottom-overlay__header {
		cursor: pointer;
	}
	.bottom-overlay__header.text-smaller {
		font-size: .8em
	}
	.bottom-overlay__header .drag-bar {
		width: 40px;
		height: 4px;
		margin: 8px auto;
		border-radius: 4px;
		background: #666;
		opacity: .5;
		display: block;
	}

	/*** Opened ***/
	.bottom-overlay.open {
		height: 45%;
	}
	.bottom-overlay .list-group {
		position: absolute;
		left:20px;
		right: 20px;
		top: 50px;
		bottom: 20px;
		margin: 0;
		overflow: hidden;
		overflow-y:auto;
	}
	.bottom-overlay .list-group-item {
		border-left: 0;
		border-right: 0;
	}

	/* Location Toggles
	   ========================================================================== */
	.list-divider-day {
		position: relative;
		top: auto;
		right: auto;
		left: auto;
	}
	.location-toggle {
		position: relative;
	}
	.location-toggle:before,
	.location-toggle:after {
		content: '';
		display: table;
	}
	.location-toggle:after {
		clear: both;
	}
	.location-toggle .list-group-item-heading {
		margin: 0;
		padding: 20px 15px;
		color: #55565a;
		padding-right: 100px;
		font: 700 1.25em/1em 'Lato',sans-serif;
	}

	/* Open */
	.location-toggle.open {
		background: #004C7D;
		color: #fff;
	}

	.location-toggle.open .list-group-item-heading{
		color:#fff;
	}

	/* ==========================================================================
	   Form Control
	   ========================================================================== */
	/*specific to login page*/
	.input-container {
		position: relative;
	}
	.form-control {
		border-bottom: 1px solid #ccc;
		background: #fff;
	}
	.float-bar {
		position: relative;
		display: block;
	}

	.float-bar:before {
		content: ' ';
		height: 3px;
		width: 0;
		bottom: 1px;
		position: absolute;
		background: #00bbe6;
		transition: 0.2s ease all;
		left: 0;
	}

	.form-control:focus {
		border-color: transparent;
	}

	.form-control:focus ~ .float-bar:before {
		width: 100%;
	}

	.floating-label {
		position: absolute;
		top: 18px;
		left: 15px;
		font-size: 14px;
		line-height: 14px;
		font-weight: normal;
		transition: 0.2s ease all;
	}

	.form-control:focus ~ .floating-label {
		top: -10px;
		font-size: 12px;
	}

	.form-group {
		margin-top: 10px;
	}

	/*has text class is set in js */
	.form-control.has-text ~.floating-label {
		top: -10px!important;
	}
	.page-header .page-back-btn {
		font-size: 1.4em;
		line-height: 60px;
		position: absolute;
		top: -3px;
		left: 0;
		width: 200px;
		height: 60px;
		padding-left: 13px;
		color: #fff;
		background: 0 0;
	}
	@media(max-width:1024px){
		.bottom-overlay {
			left: 0
		}
	}
	@media(max-width:768px){
		.page-header .col-1 a {
			margin-top: 0;
		}
	}
}
