@use '../../css-modernize/variables.scss' as *;
@use '../../css-modernize/partials/mixins' as *;

.page-agenda-grid {

	@keyframes liveNowPulse {
		0% {
			background: rgba(255, 54, 33, 0.15);
			box-shadow: 0 0 0 0px rgba(255, 54, 33, 0.30);
			transform: scale(1.05);
		}
		80% {
			background: rgba(241, 33, 9, 0);
			box-shadow: 0 0 0 1em rgba(241, 33, 9, 0);
			transform: scale(1);
		}
	}

	@-webkit-keyframes liveNowPulse {
		0% {
			background: rgba(255, 54, 33, 0.15);
			box-shadow: 0 0 0 0px rgba(255, 54, 33, 0.30);
			transform: scale(1.05);
		}
		80% {
			background: rgba(241, 33, 9, 0);
			box-shadow: 0 0 0 1em rgba(241, 33, 9, 0);
			transform: scale(1);
		}
	}

	#global-container {
		max-width: 100%!important;
	}

	.agenda-grid {
		padding: 0px;

		.page-contents {
			max-width: 1400px;
			border-radius: 12px;
			background: rgba(0,0,0,.2);
			margin: 0 auto rem(20px);
			padding: rem(30px);
		}

		&__main-content {
			position: relative;
			min-height: initial !important;
			padding: 0 rem(70px);
		}

		/**
		* Agenda Day View
		**/
		&__view-day {
			width: 100%;
			overflow: hidden;
		}

		/**
		* Agenda Glance View
		**/
		&__view-glance {

		}

		/**
		* Agenda My Agenda View
		**/
		&__view-my-agenda {
			margin-bottom: rem(30px);
			padding-bottom: rem(30px);
		}

		/**
		* Agenda Search Results View
		**/
		&__view-search-results {
			margin-top: rem(90px);

			& + .agenda-grid__view-search-results {
				margin-top: rem(20px);
			}

			.searchResults-day-title {
				color: $light;
				font: 400 24px/30px "Arial", sans-serif;
				letter-spacing: -0.5px;
				margin: 0 0 3px;
				text-transform: uppercase;
			}

			.searchResults-timeslot-container {
				margin-bottom: rem(20px);
			}

			.searchResults-time-title {
				color: #fff;
				font: 600 19px/25px "Arial", sans-serif;
				letter-spacing: 1px;
				margin: 0;

				small {
					font: 600 14px/25px "Arial", sans-serif;
					letter-spacing: 1px;
				}
			}
		}
	}

	/**
	* Day Nav List
	**/
	.agenda-grid-day-nav {
		display: flex;
		justify-content: space-between;
		padding: 0;
		margin: 1em 0 3em;
		padding: 0 0 1em;
		overflow-x: auto;

		&__single {
			background: none;
			border: none;
			font-size: rem(18px);
			font-style: normal;
			font-weight: 400;
			line-height: 38px;
			text-align: left;
			white-space: nowrap;
			color: #ccc;
			width: auto;
			padding: 0.75em 0.75em;
			margin: 0 0 0 0;
			border-bottom: 5px solid transparent;
			transition: $transition1;

			&.--active {
				color: $light;
				border-bottom-color: $primary;
			}

			&:focus {
				outline: none;
			}
		}
	}

	/**
	* Timeslot Nav
	**/
	.agenda-grid-timeslot-header {
		display: block;
		overflow: hidden;
		background: $primary;

		&__inner-wrapper {
			display: flex;
			transition: transform 0.2s;
			overflow: hidden;
			overflow-y: auto;
		}

		&__single-nav {
			position: relative;

			&:last-child p {
				border: none;
			}

			p {
				color: $light;
				padding: 2px 10px 3px;
				font: 600 16px/25px "Arial", sans-serif;
				border-right: 1px solid rgba(255, 255, 255, 0.25);
				margin: 0;
				background: $primary;

				small {
					font-weight: 600;
					font-size: 14px;
					margin-left: 5px;
				}
			}
		}

		&__toggle-offset {
			position: absolute;
			top: 0;
			border: none;
			width: 70px;
			height: rem(31px);
			overflow: hidden;
			background: $primary;
			z-index: 10;
			color: $light;
			font: 600 14px/30px "Arial", sans-serif;
			text-transform: uppercase;
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: center;

			&:focus,
			&:active {
				box-shadow: none;
				outline: none;
			}

			&:disabled {
				opacity: .4;
			}

			span {
				line-height: 100%;
				padding-right: rem(3px);
			}

			i {
				display: inline-block;
				font-size: rem(22px);
			}

			&.--prev {
				left: 0;
			}

			&.--next {
				right: 0px;
			}
		}
	}

	/**
	* Time slot
	**/
	.agenda-grid-timeslot {
		overflow: hidden;
		overflow-y: auto;

		&__content {
			display: flex;
			transition: transform 0.2s;
			overflow: hidden;
			overflow-y: auto;
		}

		&__single {
			position: relative;
		}

		&__single-inner {
			overflow: hidden;
			overflow-y: auto;
			margin: 10px 0 0;
			padding: 0 2px;

			> .agenda-grid-session-block {
				margin-top: 0;
			}
		}
	}

	/**
	* Session Block
	**/
	.agenda-grid-session-block {
		border-radius: 4px;
		border: 1px solid #d1d1d1;
		background: #eff3f4;
		display: block;
		margin: 4px 0;
		cursor: pointer;
		transition: all 0.2s;
		position: relative;

		&:hover {
			color: $dark;

			a {
				color: $dark;
			}
		}

		&__inner {
			position: relative;
			padding: 13px 30px 45px 13px;
			display: block;
			color: $dark;
			min-height: 211px;

			&:hover {
				text-decoration: none;
			}

			@include mq('md') {
				padding-bottom: 60px;
			}

			@include mq('sm') {
				padding-bottom: 80px;
			}
		}

		&__title {

			h4 {
				font: 600 12px/17px "Arial", sans-serif;
				letter-spacing: 2px;
				color: #818181;
				margin: 0 0 4px;
			}

			h3 {
				font: 600 16px/20px "Arial", sans-serif;
				color: $dark;
				margin: 0;
			}
		}

		&__description {
			max-height: 250px;
			overflow-y: auto;
			margin-top: 1em;
			padding-right: 30px;
			margin-right: -30px;
		}

		&__speakers-title {
			font: 400 14px/17px "Arial", sans-serif;
			color: #7a7a7a;
			margin: 4px -20px 0 0;
			max-height: 70px;
			overflow: auto;
		}

		&__speaker-list {
			max-height: 100px;
			width: 100%;
			overflow-y: auto;
		}

		&__tags {
			font: 700 13px/16px "Arial", sans-serif;
			letter-spacing: 1px;
			text-align: left;
			margin: 1em 0 0;
			text-transform: uppercase;
			position: absolute;
			left: 13px;
			right: 0;
			bottom: 3px;

			h5 {
				font-weight: normal;
				text-transform: none;
				letter-spacing: initial;
				font-size: rem(13px);
			}

			.badge {
				padding: 0.3em 0.7em;
				margin: 0 5px 2px 0;
				background-color: #1b3139;
			}
		}

		&__actions {
			position: absolute;
			right: 0;
			top: 0;

			> button {
				display: block;
				border: none;
				background: none;
				padding: 2px 5px;
				color: inherit;
				font-size: 18px;

				&:focus,
				&:active {
					outline: none;
				}

				&.--active {

					i {
						color: var(--secondary-bc);
					}
				}
			}

			.fa {
				color: #dadada;
			}

			.addeventatc {
				width: 26px;
				box-shadow: none !important;
				border: none;
				background: none;
				padding: 5px 2px;
				z-index: 1;
			}

			.addeventatc_icon {
				display: none;
			}

			.fa-calendar-alt {
				display: block;
				text-align: center;
				font-size: 18px;
			}

			.addeventatc_dropdown.addeventatc-selected {
				top: -20px !important;
				right: 15px !important;
				left: auto !important;
			}
		}

		.live-now-indicator {
			color: #000;
			position: absolute;
			bottom: 13px;
			right: 13px;
		}

		/**
		* Toggled class
		**/
		&.isToggled {
			background: #ccdade;
			transition: all 0.2s;

			.agenda-grid-session-block__actions {

				.fa {
					color: #bbb;

					&.--active {
						color: var(--secondary-bc);
					}
				}
			}
		}
	}

	/**
	* Speaker Block
	**/
	.agenda-grid-speaker-block {
		position: relative;
		display: flex;

		& + .agenda-grid-speaker-block {
			margin-top: rem(15px);
		}

		&__image {
			width: rem(40px);
			height: rem(40px);
			background-position: 50%;
			background-size: cover;
			background-repeat: no-repeat;
		}

		&__info {
			margin-left: rem(10px);

			p {
				font-size: rem(12px);
				margin: 0px;

				&.name {
					font-size: rem(14px);
				}
			}
		}

		/**
		* Overlay styles
		**/
		&.--overlay {
			.agenda-grid-speaker-block__image {
				width: rem(60px);
				height: rem(60px);
			}

			.agenda-grid-speaker-block__info {

				p {
					font-size: rem(14px);

					&.name {
						font-size: rem(16px);
					}
				}
			}
		}
	}

	/**
	* Live now
	**/
	.live-now-indicator {
		color: $light;
		font: 600 12px/18px "Arial", sans-serif;
		text-transform: uppercase;
		text-align: right;
		letter-spacing: 0.5px;
		line-height: 30px;
		padding: 0 10px;
		pointer-events: none;
		animation: liveNowPulse 3s ease-in-out 0s infinite;
		-webkit-animation: liveNowPulse 3s ease-in-out 0s infinite;
		border-radius: 4px;

		.fas {
			font-size: 18px;
			margin-left: 2px;
			position: relative;
			top: 2px;
		}
	}

	/**
	* Sponsor block
	**/
	.sponsor-block {

		& + .sponsor-block {
			margin-top: rem(15px);
		}

		/**
		* Slide out
		**/
		&.--slide-out {
			max-width: 60%;
		}
	}

	/**
	* Time slot row? Might not be used anymore.
	**/
	.timeslot-row {
		margin-top: 1.5em;

		.timeslot-row-header {
			text-transform: uppercase;
			letter-spacing: 0.1em;
			font-weight: 300;
			color: $light;
			margin: 0 0 0.5em;
		}
	}

	/**
	* Agenda Grid Slide out
	**/
	.session-detail-close-overlay {
		visibility: hidden;
		opacity: 0;
		transition: $transition1;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
		background-color: rgba($dark, .6);

		&.--visible {
			opacity: 1;
			visibility: visible;
		}
	}

	.session-detail-overlay {
		position: fixed;
		right: 0;
		bottom: 0;
		top: 0;
		left: auto;
		width: 450px;
		max-width: 75%;
		background-color: rgba($primary , .8);
		box-shadow: -4px 5px 20px rgba(0, 0, 0, 0.25);
		color: $light;
		z-index: 3000;
		transform: translateX(450px);
		transition: 0.2s ease-in-out;
		backdrop-filter: blur(12px);

		&.--visible {
			transform: translateX(0);
			transition: 0.2s ease-in-out;
		}

		&__header {
			padding: rem(15px) rem(30px);
			display: flex;
			justify-content: space-between;
			align-items: center;

			button {
				background: none;
				border: none;
				margin: -15px;
				padding: 15px;
				font-size: 20px;
				color: rgba(255,255,255,.5);

				&:active,
				&:focus {
					outline: none;
					box-sizing: none;
				}

				&.active {
					color: var(--secondary-bc);
				}
			}

			.close-overlay {

				button {
					color: $light;
					font-size: 20px;
					transition: $transition1;
				}

				&:hover {
					button {
						transform: rotate(180deg);
					}
				}
			}

			.header-actions {
				display: flex;
				align-items: center;
			}

			.addeventatc_icon {
				display: none;
			}

			.addeventatc {
				padding: 15px;
				background: none;
				border: none;
				box-shadow: none !important;

				&:focus,
				&:active {
					box-shadow: none;
					outline: none;
				}

				i {
					font-size: rem(20px);
					color: $light;
				}
			}

			.addeventatc_dropdown {
				margin-top: 50px;
				margin-left: -10px;
			}
		}

		&__content {
			position: relative;
			padding: rem(0px) rem(30px) rem(15px);
			overflow-y: auto;
			max-height: 90vh;
		}

		&__title {
			margin-bottom: rem(15px);

			p {
				margin: 0px;
				@include fluid-type(18px, 24px);
				font-weight: 700;
			}
		}

		&__speakers {
			max-height: 200px;
			overflow-y: auto;
		}

		&__description {
			color: $light;
			overflow-y: auto;
			max-height: rem(200px);
			margin-top: rem(20px);

			> * {
				color: $light;
			}

			a {
				color: $light;
				text-decoration: underline;
			}
		}
	}
}








