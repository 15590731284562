/**
 * Description: CSS Specific to twilio collaborative sessions
 * Author: Ryan Taggart <ryan.t@meetingplay.com>
**/


.page-admin-panel,
.page-sessison-virtual,
.page-session-zoom,
.page-video-testing,
.page-virtual-exhibitor {
	/* ==========================================================================
		General Styles
	========================================================================== */


	/* ==========================================================================
		Live Feed Containers
	========================================================================== */


	/* Wrappers
	========================================================================== */

	.live-feed-wrapper {
		position: relative;
	}

	.live-feed-wrapper+.live-feed-wrapper {
		margin-top: 10px;
	}


	/* Titles
	========================================================================== */

	.live-feed-title {
		padding: 10px;
		position: relative;
	}

	.live-feed-title .title-intro {
		font-size: 1.1em;
		margin: 0;
		line-height: 35px;
	}

	.live-feed-title .title-intro .fal {
		margin-right: 5px;
		font-size: 20px;
		position: relative;
		top: 3px;
	}

	.live-feed-title .title {
		margin: 0;
		font-weight: 700;
		font-size: 1.25em;
		margin: 0 0 8px;
	}

	.liveFeed-noData {
		width: 100%;
		height: auto;
		background: #ccc;
		background: linear-gradient(#f3f3f3, #d4d4d4);
		z-index: 101;
		background-size: cover;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		background-position: 50% 50%;
		padding-top: 35%;
	}


	/* Toggles
	========================================================================== */

	.toggle-fullscreen {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 5px;
		z-index: 15;
		color: #000;
		z-index: 100;
	}


	/* Minimized
	========================================================================== */


	/*** Minimized version ***/

	.toggle-feed-size {
		position: absolute;
		top: 10px;
		right: 10px;
		bottom: auto;
		z-index: 100;
	}

	.minimized .live-feed-title {
		padding-left: 130px;
		font-size: .8em;
		height: 115px;
	}

	.minimized .live-feed-title h3,
	.minimized .live-feed-title h4 {
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: none
	}

	#sidebar-panel .minimized[data-feed="presenter"] a[rel="#action-tab-collaboration"],
	#sidebar-panel .minimized[data-feed="presenter"] .toggle-feed-size {
		top: unset;
		bottom: 5px;
		right: 10px;
		z-index: 1;
	}

	#sidebar-panel .minimized[data-feed="presenter"] a[rel="#action-tab-collaboration"] {
		right: 60px;
	}

	#sidebar-panel .minimized[data-feed="presenter"] .video-block video {
		height: 115px;
		object-fit: cover;
	}

	.minimized .liveFeed-noData {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 113px;
		height: 115px;
		padding: 0;
	}

	.minimized .live-feed {
		position: absolute;
		top: 0;
		left: 0;
		width: 113px;
		height: auto;
		z-index: 1;
	}


	/*** Minimized users feed ***/

	[data-feed="users"] .live-feed-title {
		display: none;
	}

	.minimized[data-feed="users"] .live-feed-title {
		display: block;
		height: 113px;
		padding-left: 170px;
	}

	.minimized[data-feed="users"] .video-block-overlay {
		display: none;
	}

	.minimized[data-feed="users"] .live-feed,
	.minimized[data-feed="users"] .liveFeed-noData {
		width: 150px;
		height: 113px;
	}


	/*** Minimized Presenter Feed ***/

	[data-feed="presenter"] [rel="#action-tab-collaboration"] {
		position: absolute;
		bottom: 5px;
		right: 5px;
	}

	.minimized[data-feed="presenter"] [rel="#action-tab-collaboration"] {
		bottom: auto;
		top: 10px;
		right: 50px;
		font-size: .95em;
		font-weight: 700;
	}

	.minimized[data-feed="presenter"] .live-feed,
	.minimized[data-feed="presenter"] .liveFeed-noData {
		top: 100%;
		max-height: 115px;
	}

	.minimized[data-feed="presenter"] .toggle-screenshare,
	.minimized[data-feed="presenter"] .toggle-fullscreen {
		display: none;
	}

	.minimized[data-feed="presenter"] h4 {
		opacity: 0;
	}


	/*** Screenshare minimized ***/

	[data-feed="screenshare"].minimized .live-feed-container {
		width: 113px;
		height: 115px;
		position: absolute;
		overflow: hidden;
	}


	/* Toggles
	========================================================================== */

	.live-feed-container {
		position: relative;
	}

	.live-feed-container .toggle-fullscreen,
	.live-feed-container .toggle-screenshare {
		position: absolute;
		bottom: 10px;
		right: 10px;
		left: auto;
		z-index: 100;
		/* will be behind any no data overlay */
		color: #fff;
		background: rgba(12, 38, 69, .75);
		line-height: 40px;
		width: 40px;
		display: block;
		text-align: center;
		border-radius: 4px;
	}

	.live-feed-container .toggle-screenshare {
		z-index: 1001;
		/* Now needs to be in front of overlay */
	}

	.live-feed-container .toggle-fullscreen .fa,
	.live-feed-container .toggle-screenshare .fa {
		position: relative;
		top: 2px;
		left: 1px;
		font-size: 1.25em;
	}

	.live-feed-container .toggle-screenshare {
		position: absolute;
		left: 5px;
		right: auto;
		width: auto;
		padding: 0 10px;
		font-weight: 700;
		font-size: 0.8em;
		letter-spacing: 2px;
		height: auto;
	}

	.live-feed-container .toggle-screenshare.disabled:before {
		content: '\f110';
		font-family: 'Font Awesome 5 Pro';
		-webkit-animation: fa-spin 2s linear infinite;
		animation: fa-spin 2s linear infinite;
		margin-right: 10px;
	}

	[data-feed="presenter"] [rel="#action-tab-collaboration"] {
		position: absolute;
		bottom: 5px;
		right: 5px;
	}


	/* Main Feed video blocks
	========================================================================== */

	#liveFeed-users {
		background: #fff;
	}

	#liveFeed-users:before,
	#liveFeed-users:after {
		content: '';
		display: table;
	}

	#liveFeed-users:after {
		clear: both;
	}

	#liveFeed-users .video-block {
		float: left;
		width: 100%
	}

	#liveFeed-users .video-block .media-wrapper {
		position: relative;
		width: 100%;
		padding: 75% 0 0 0;
	}

	#liveFeed-users .video-block .media-wrapper video {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
	}


	/* ==========================================================================
		Presenter Block
	========================================================================== */

	#liveFeed-presenter .video-block-overlay {
		display: none;
	}


	/* ==========================================================================
		Screenshare Block
	========================================================================== */

	#liveFeed-screenshare .video-block .media-wrapper {
		padding-top: 56.25%;
	}

	#liveFeed-screenshare .video-block video {
		object-fit: contain !important;
	}

	#liveFeed-screenshare .video-status.audio-disabled {
		display: none;
	}

	/* ==========================================================================
		Agora type video blocks
	========================================================================== */

	.video-block.video-block-agora .media-wrapper {
		width: 100%;
		padding-top: 75%;
	}

	.video-block.video-block-agora .media-wrapper .video-placeholder {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.video-block.video-block-agora .play-stream {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 9999999;
	}

	.video-block.video-block-agora .play-stream .fa {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		margin: -.5em 0 0 0;
		font-size: 3em;
		text-align: center;
	}

	#liveFeed-users .video-block[data-hasvideo="false"] {
		width: 0px !important;
	}


	/* Audience Management
	========================================================================== */

	.video-admin-block {
		background: #fafafa;
		padding: 16px 16px 10px;
		color: #000;
		border-radius: 6px;
		overflow: hidden;
		border: 1px solid #ccc;
		border-top: 6px solid rgba(44, 71, 175, 1);
		margin: 1em 0;
		position: relative;
	}

	.video-admin-block h4 {
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 2px;
		font-size: 1.1em;
	}

	.video-admin-block h4 small {
		text-transform: initial;
		letter-spacing: 0;
		display: block;
		font-weight: 700;
		font-size: .8em;
		margin: .5em 0 0;
	}

	.video-admin-block .inner-content {
		transition-duration: .2s;
		padding-top: 15px;
	}

	.video-admin-block.minimized .inner-content {
		height: 0 !important;
		padding: 0;
		overflow: hidden;
		transition-duration: .2s;
	}

	.video-admin-block.minimized .video-admin-toggle {
		transform: rotate(180deg);
		transition-duration: .2s;
	}

	.video-admin-block .btn {
		line-height: 1.7em;
		height: 2.5em;
	}

	.video-admin-toggle {
		position: absolute;
		top: 10px;
		right: 10px;
		background: none;
		font-size: 1.25em;
		transform: rotate(0);
		transition-duration: .2s;
	}

	.search-container.loading {
		position: relative;
	}

	.search-container.loading:after {
		content: '\f3f4';
		font-family: 'Font Awesome 5 Pro';
		position: absolute;
		top: 5px;
		right: 5px;
		-webkit-animation: fa-spin 2s linear infinite;
		animation: fa-spin 2s linear infinite;
		opacity: .5;
	}

	.workshop-list {
		max-height: 300px;
		overflow: auto;
		font-size: .85em;
		padding-bottom: 2px;
	}

	.workshop-list#list-audience {
		font-size: .75em;
	}

	.workshop-list-item {
		padding: 0.5em .9em 0.5em 3em;
		border: 1px solid #ccc;
		background: #fafafa;
		color: #000;
		position: relative;
		border-radius: 4px;
		margin-bottom: 1px;
	}

	.workshop-list-item>.fa,
	.workshop-list-item>.fal,
	.workshop-list-item>.fas {
		position: absolute;
		left: 0;
		top: 50%;
		margin-top: -.5em;
		width: 2em;
		text-align: center;
	}

	.workshop-list-item .me {
		position: absolute;
		top: 50%;
		right: 120px;
		width: 2.5em;
		background: #3f5e88;
		border-radius: 4px;
		text-align: center;
		line-height: 1.5em;
		color: #fff;
		font-size: .85em;
		margin-top: -0.65em;
	}

	#list-live .workshop-list-item .me {
		top: 5px;
		margin-top: 0;
		right: 10px;
	}

	.workshop-list-item .workshop-list-item-heading {
		font-size: 1.2em;
		margin: 0;
		padding: 0;
	}

	.workshop-list-item button {
		float: right;
		font-size: 0.8em;
		margin-top: -1.95em;
		position: relative;
		padding: .35em .5em;
	}

	.workshop-list-item button .fa {
		margin-right: .5em;
	}


	/* Live List
	========================================================================== */

	.workshop-list#list-live button {
		float: none;
		margin: 5px 0 -2px 0;
		display: block;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		height: auto;
		line-height: 2em;
	}

	.workshop-list#list-live .fa-hand-paper {
		display: none;
	}
}