.page-session-library {
	.scrollable {
		padding-bottom: 100px;
	}
	.video-item-column p {
		font-size: 16px;
		line-height: 18px;
	}
	.video-item {
		position: relative;
	}

	.video-item i.fa-fire {
		position: absolute;
		bottom: 10px;
		left: 10px;
		font-size: 22px;
		color: #fff;
	}

	.video-item img {
		display: block;
		width: 100%;
		height: auto;
	}

	.play-btn {
		display: block;
		width: 60px;
		height: 40px;
		border-radius: 3px;
		text-align: center;
		position: absolute;
		bottom: 10px;
		right: 5px;
		padding: 5px 10px;
		background: rgba(0,0,0,.7);
		font-size: 20px;
		color: #fff;
	}

	.promoted {
		position: absolute;
		top: 0;
		left: 0;
		background: #000;
		padding: 3px 7px;
		text-align: center;
		color: white;
		text-transform: uppercase;
		font-size: 11px;
	}

	.video-link:hover {
		cursor: pointer;
	}


	#promoted-video .col-sm-9 {
		padding-top: 20px;
		padding-bottom: 20px;
		padding-right: 60px;
	}

	#filter-tags-btn, #filter-tracks-btn {
		background: #5d5d5d;
		border-color: #5d5d5d;
	}

	#session-library-content {
		max-width: 1200px;
		margin: 0 auto;
	}

	#session-library h2 {
		font-weight: bold;
		text-transform: uppercase;
	}

	.video-info {
		height: 100px;
		overflow: hidden;
	}

	.video-image {
		width: 100%;
		height: 140px;
	}


	/*overlay filter*/
	.filter-widget {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.6);
		display: none;
		z-index: 4000;
	}

	.filter-widget-content {
		width: 300px;
		margin: 70px auto 0;
		background: #fff;
		-webkit-box-shadow: 0 0 4px 1px rgba(0,0,0,.3);
		box-shadow: 0 0 4px 1px rgba(0,0,0,.3);
	}

	.filter-list {
		height: 350px;
		padding: 10px;
		overflow-y: auto;
	}
	.filter-footer {
		padding: 10px;
		background: #ddd;
	}

	.filter-list ul {
		margin-left: 0;
		padding-left: 0;
	}

	.filter-list .filter-header, .filter-list .filter-link {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.filter-list .filter-header {
		border-bottom: 2px solid #eaeaea;
		font-size: 15px;
		font-weight: bold;
		padding: 8px 5px 8px 5px;
		background-color: #d5d5d5
	}

	.filter-list .filter-link {
		border-bottom: 1px solid #eaeaea;
		font-size: 14px;
		padding: 5px 5px 8px 5px;
		cursor: pointer;
		transition: $transition1;

		&.selected {
			background-color: $primary;
			color: color-contrast($primary);
		}
	}

	/*#all-videos.filter-container {
		display: block!important;
		flex-wrap: nowrap;
	}*/


	#all-videos {
		min-height: 800px;
	}

	/* ==========================================================================
	   bx-slider for promoted videos
	   ========================================================================== */
	.bx-wrapper {
		-moz-box-shadow: none!important;
		-webkit-box-shadow: none!important;
		box-shadow: none!important;
		border: none!important;
		background: none!important;
	}
	.video-item-column{
		width: 24.5%!important;
		padding:10px;
	}
	#all-videos {
		min-height: 800px;
		width: 100%;
	}

	.item .video-image{
		width: 100%;
	}

	#all-videos {
		overflow-x: hidden;
		overflow-y: visible;
	}

	.filter-container {
		overflow-y: hidden;
	}

	.vid-description {
		display: none;
		font-size: 13px;
		margin: 5px 0;
		font-style: italic;
	}

	.list-vid-name {
		display: none;
	}

	.video-item-column.list-view .grid-vid-name {
		display: none;
	}

	.video-item-column.list-view .list-vid-name {
		display: block;
	}

	.video-item-column.list-view {
		width: 49%!important;
		margin-top: 0!important;
	}

	.video-item-column.list-view .play-btn,
	.video-item-column.list-view .hot-tag {
		display: none;
	}

	.video-item-column.list-view .video-image {
		position: absolute;
		top: 10px;
		left: 10px;
		height: 80px;
		width: 130px;
	}

	.video-item-column.list-view .video-info {
		padding-right: 25px;
		border: 1px solid #ccc;
		border-radius: 4px;
		height: 120px;
		padding-left: 160px;
		background: #fff;
	}

	.video-item-column.list-view .vid-description {
		display: block;
	}

	.video-item-column.list-view .vid-name {
		margin-top: 10px!important;
	}
	@media(max-width:768px){
		.video-item-column{
			width: 49%!important;
			padding:10px;
		}
		.video-item-column.list-view{
			width: 100%!important;
		}
		#all-videos {
			width: 100%!important;
		}
	}
	@media(max-width:500px){
		.video-item-column{
			width: 100%!important;
		}
	}
	@media(max-width:700px){
		.play-btn {
			left: 6px;
		}
	}

	@media only screen and (max-width: 991px) {
		.video-image {
			width: 100%;
			height: 210px;
		}
	}
}