.page-admin-panel {

	.panel {
		margin-top: 2em;
		text-align: center;
	}

	.panel-body {
		overflow: hidden;
	}

	.panel-header {
		background: rgba(7, 83, 160, 1);
	}

	.count {
		font-size: 3em;
		line-height: 1.25em;
	}

	[data-require] {
		display: none;
	}


	/*Tabs */

	.simple-tab {
		display: none;
		padding: 20px 0;
	}

	.simple-tab.current-item {
		display: block;
	}

	.simple-tab-nav a {
		display: inline-block;
		text-decoration: none;
		text-align: center;
		padding: 10px 15px;
		color: #000;
	}

	.simple-tab-nav a.current-item {
		border-bottom: 4px solid #000;
		font-weight: bold;
	}
}