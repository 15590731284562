.page-sessison-virtual {
	.customer-sentiment-wrapper {
		position: relative;
	}

	.customer-sentiment-container {
		height: 100%;
		left: 0;
		opacity: 0;
		overflow: hidden;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 9999;
	}

	.customer-sentiment-wrapper[data-enabled="true"]
	.customer-sentiment-container {
		opacity: 1;
	}

	.customer-sentiment-wrapper[data-on="false"]
	.customer-sentiment-container {
		opacity: 0 !important;
	}

	.customer-sentiment-triggers {
		bottom: 2rem;
		display: flex;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		right: 1rem;
	}

	.customer-sentiment-wrapper[data-enabled="true"]
	.customer-sentiment-triggers {
		opacity: 1;
		pointer-events: auto;
	}

	.customer-sentiment-wrapper[data-on="false"]
	.customer-sentiment-triggers {
		opacity: 0 !important;
		pointer-events: none !important;
	}

	/* trigger */

	.customer-sentiment-trigger {
		margin: 0 0 0 12px;
		user-select: none;
	}

	.disabled .customer-sentiment-trigger,
	.customer-sentiment-trigger.disabled {
		opacity: 0.500;
		pointer-events: none;
	}

	.customer-sentiment-trigger-icon {
		align-items: center;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 32px 32px;
		border-radius: 50%;
		border: solid 1px rgba(255, 255, 255, 0.5);
		color: white;
		cursor: pointer;
		display: flex;
		filter: grayscale(1) brightness(0.75);
		height: 40px;
		justify-content: center;
		text-align: center;
		transition: 0.2s;
		width: 40px;
	}

	.customer-sentiment-trigger-icon:hover {
		border-color: #fbaa19;
		filter: grayscale(0) brightness(1);
	}

	.customer-sentiment-trigger.disabled
	.customer-sentiment-trigger-icon {
		color: #fbaa19;
		opacity: 0.500;
		pointer-events: none;
		transform: scale(0.9);
	}

	/* fix for overlapping captions menu  */
	.vjs-menu-content {
		z-index: 200;
	}

	/* mute */

	.customer-sentiment-mute {

	}

	.customer-sentiment-mute i {
		color: white;
		cursor: pointer;
		height: 40px;
		opacity: 0.500;
		line-height: 40px;
		text-align: center;
		transition: 0.2s;
		width: 40px;
	}

	.customer-sentiment-mute i:hover {
		opacity: 1;
	}

	.customer-sentiment-mute .fa-eye {
		display: block;
	}

	.disabled .customer-sentiment-mute .fa-eye {
		display: none;
	}

	.customer-sentiment-mute .fa-eye-slash {
		display: none;
	}

	.disabled .customer-sentiment-mute .fa-eye-slash {
		display: block;
	}

	/* plus */

	.customer-sentiment-avatar {
		align-items: center;
		display: flex;
		justify-content: center;
		position: absolute;
	}

	.customer-sentiment-avatar img {
		border-radius: 100%;
		height: 100%;
		left: -100%;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.customer-sentiment-avatar-icon {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 90%;
		height: 100%;
		width: 100%;
	}

	/* icons */

	/* [data-type="tada"] .customer-sentiment-trigger-icon,
	[data-type="tada"] .customer-sentiment-avatar-icon {
		background-image: url(../../images/sentiment/reaction-tada.png);
	} */

	[data-type="like"] .customer-sentiment-trigger-icon,
	[data-type="like"] .customer-sentiment-avatar-icon {
		background-image: url(../../images/sentiment/reaction-like.png);
		background-size: 50% 50%;
	}

	[data-type="applause"] .customer-sentiment-trigger-icon,
	[data-type="applause"] .customer-sentiment-avatar-icon {
		background-image: url(../../images/sentiment/reaction-applause.png);
		background-size: 50% 50%;
	}

	[data-type="heart"] .customer-sentiment-trigger-icon,
	[data-type="heart"] .customer-sentiment-avatar-icon {
		background-image: url(../../images/sentiment/reaction-heart.png);
		background-size: 50% 50%;
	}
}