/**
 * Bootstap theme vars
 * ! Do not edit source bootstrap scss, add variable overrides here, recompile base after adding / modifying
 */
// three main colors
$primary : #005eb8;
$info : #005eb8;
$success : #4ea000;

// best left unmodified
$secondary : #465a5d;
$warning : #E35205;
$danger : #B80009;
$light : #f8f9fa;
$dark : #101820;

$accordion-color:                         #fff;
$accordion-bg:                            transparent;

$accordion-button-bg:                     transparent;
$accordion-button-active-bg:              #005db9;
$accordion-button-active-color:           #fff;

$accordion-border-radius:                 0 0 0 0;
$accordion-inner-border-radius:           0 0 0 0;

/**
 * Meetingplay theme vars
 */
$pageheader : #005eb8;
$sidebar : rgba(0,0,0, .7);
$tabcolor: #005eb8;
$customcolor1: #002f6c;

/**
 * Site transitions
 */
$transition1: .2s ease-in-out all;