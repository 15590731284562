/*
=================================================================================================================
Section: Chat Pages
Description: Used for the private messaging chat overall & detail pages
=================================================================================================================
*/

.page-group-chat {
	/*** Message History (Individual Chat Page) ***/
	.message-history {
		margin: 20px 0;
	}
	/*** Message Item ***/
	.message-item {
		width: 60%;
		position: relative;
		margin: 10px 15px 15px;
		float: left;
	}
	.message-item .image-wrap {
		width: 40px;
		max-height: 40px;
		border-radius: 4px;
		border: 1px solid #ddd;
		overflow: hidden;
		position: absolute;
		top: 5px;
		left: -10px;
	}
	.message-meta {
		font-size:.7rem;
		color: #555;
		margin: 0 0 5px 42px;
		text-align: left;
	}
	.message-content {
		font-size:1.1rem;
		padding: 8px 15px 8px 42px;
		margin: 0;
	}
	.message-content:after {
		content: '';
		border-left: 0 solid transparent;
		border-right: 10px solid transparent;
		position: absolute;
		left: 5px;
		top: 100%;
	}
	/*** My Messages ***/
	.message-item.me .image-wrap {
		display: none;
	}
	.message-item.me {
		float: right;
	}
	.message-item.me .message-meta {
		margin-left: 15px;
	}
	.message-item.me .message-content {
		background: #fff;
		padding: 10px 15px;
		color: #000;
		border: 1px solid #ccc;
	}
	.message-item.me .message-content:after {
		border-left-width: 10px;
		border-right-width: 0;
		border-top-color: #ccc;
		right: 5px;
		left: auto;
	}
}