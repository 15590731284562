/* First breakpoint for left side nav, hide it */
#mobile-flyout{display:none!important}
@media(max-width: 1024px){
    .hidden-mobile {
        display: none;
    }

    .hidden-desktop {
        display: block;
    }

    #global-container {
        padding-left: 0;
    }

    #sidebar-left {
        transform: translateX(-250px);
    }

    .sidebar-in #sidebar-left {
        transform: translateX(0px) !important;
    }

    #app-header #left-sidebar-toggle {
        display: inline-flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-left: 10px;
    }

	#app-header #left-sidebar-toggle .bar {
		width: 25px;
		padding: 2px;
		border-radius: 4px;
		background: white;
		transition: .2s ease-in-out transform;
	}

	#app-header #left-sidebar-toggle .bar + .bar {
		margin-top: 3px;
	}

	#app-header #left-sidebar-toggle:hover .bar.-center {
		transform: scaleX(.8);
	}

    #close-sidebar-background-mask {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: rgba(0,0,0,.7);
    }

    .sidebar-in #close-sidebar-background-mask {
        opacity: 1;
		visibility: visible;
    }

    body.sidebar-in .sidebar{
        overflow-y: auto!important;
    }

    #virtual-exhibit-hall .ex-header-img {
        height: auto;
    }
    .profile-header img {
        height: 100px;
    }

    body .tile.height-1 {
		padding-top: calc(50% - 100px);
	}
	body .tile.height-2 {
		padding-top: calc(100% - 200px);
    }

    .promoted-description {
		margin-right: 26%;
    }

    #networking-form {
		padding-bottom: 400px;
	}
	.forget {
		padding-bottom: 200px;
	}

    #skip-to, #page-agenda, #page-general-information, #page-question-hub-list, #page-followed-items {
        margin: 0 0 0 0;
        border-radius: 0 0 0 0;
        overflow: auto;
    }

}
@media(max-width:990px){
    #mobile-flyout{display:inline-block!important}
}

@media (min-width:700px) {
	#custom-nav {
		display: none;
	}
	p.lead {
		font-size: 22px;
	}
	.session-list {
		position: relative;
	}
	#hf-session-container {
		height: 500px;
	}
	#app-only-pulse-container {
		padding-bottom: 0!important;
	}
	#hf-nav-container .tile.height-1 {
		padding-top: calc(50% - 60px);
	}


}


@media(max-width:768px) {
    body .page-contents {
        height: auto!important;
        min-height: auto!important;
    }

    #hf-session-container {
		height: 200px;
    }

	#hero-background {
		max-width: 100%;
    }

    .promoted-description {
		margin-right: -15px;
    }

	.bx-wrapper img {
		margin-bottom: 15px;
    }

    .hidden-sm-down {
		display: none!important;
    }

    #app-header {
        padding: 10px!important;
    }

    #pulse-app-container {
        padding: 5px;
    }

    .p-5, .p-4, .p-3, .p-2, .p-1 {
        padding: 1rem!important;
    }

    body .page-contents {
        padding: 10px;
    }

    /* .page-header .col-1 a {
        margin-top: -15px;
    } */

    .profile-header img {
        height: auto;
        border-radius: 4px;
        max-width: 100px;
	}
	#enable-theatre{
        display: none!important
	}
	.tile {
        min-height: 150px;
	}
    .notification-banner {
		padding-left: 40px;
		padding-right: 40px;
		font-size: .8em;
    }
    .notification-banner .banner-icon {
		top: 50%;
		left: -10px;
		margin: -18px 0 0;
		width: auto;
		background:#fff;
		padding: 5px;
		border-radius: 6px;
		box-shadow: 1px 1px 1px rgba(0,0,0,.2);
    }

    .notification-banner .banner-light {
        inset: inherit;
    }
    .notification-banner .banner-hide {
		right: 0;
		left: auto;
		margin: -18px 0 0 0;
		color: #333;
		width: 36px;
		height: 36px;
    }

	body #global-container {
        padding-top: 0!important;
    }
	#page-poll .answer-list {
        position: relative;
    }

	#page-attendee-search .col-sm-6,
	#page-attendee-search .col-sm-6.mt-5 {
		margin-top: 10px !important;
	}

	.left-side-login {
		min-height: 0px!important;
		height: auto!important;
	}

	#login-background {
		padding: 150px 0px;
	}
    /* fix sweet alert on mobile */
    .swal2-container {
        display: block!important;
    }
}

/*Nav styles*/
@media(max-width:900px){
    .profile-header img {
        height: auto;
    }
}


/* new mobile fixes brought over from scla / mite / etc modify as needed */
@media only screen and (max-width: 991px) {
    #virtual-content-column .page-contents {
        min-height: auto !important;
        height: auto !important;
    }

    #sidebar-panel.mini-nav {
        margin: 0 0 0 0!important;
    }

    #sidebar-column {
        margin-left: 0!important;
        margin-top: 0;
        border-top: 1px solid #ccc;
    }

    #sidebar-panel.mini-nav {
        border-radius: 0 0 0 0;
    }

    body .page-contents {
        min-height: 50vh!important;
    }

    body #favorites-sidebar {
        min-height: 50vh;
    }

    .form-chat-reply button[type="submit"], #session_chat_form button[type="submit"] {
        margin-top: 5px;
    }

    #gamification h6 {
        font-size: 16px!important;
    }

    #gamification .mini-subhead small {
        color: #fafafa;
    }

    #page-profile .profile-header a {
        position: relative;
        top: -10px;
    }

    .page-header h4 {
        font-size: 1rem;
    }
    .page-header h1 {
        font-size: 1.2rem;
    }

    #virtual-main-content {
        padding: 15px!important;
    }

    .page-header p {
        font-size: 15px;
    }

    body .session-chat-item-replies {
        padding-top: 0!important;
    }

    body .form-chat-reply {
        position: static!important;
    }

    body .session-chat-item-footer button {
        margin-bottom: 3px;
    }

    body .form-chat-reply {
        background: #e8e8e8!important;
    }

    .modal-open .modal {
        padding-bottom: 100px;
    }
}

