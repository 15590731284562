.page-virtual-exhibitor {
	#audience-container {
		height: auto;
		min-height: 250px;
		overflow: hidden;
		padding: 0 0 0 0;
	}

	.live-feed-overlay {
		display: none!important;
	}

	.schedule-item.active {
		border-color: rgba(0,161,141,1);
	}

	.schedule-item .live-indicator {
		background: rgba(0,161,141,1);
		padding: 2px 8px;
		color: #fff;
		font-size: 12px;
		display: inline-block;
	}

	#audience-container .toggle-feed-size {
		display: none;
	}

	#audience-container {
		position: relative;
	}

	#session-dicussion {
		display: none!important;
	}

	#audience-container .toggle-feed-size {
		display: none;
	}

	.minimized[data-feed="users"] .live-feed-container {
		width: 100%!important;
	}

	.virtual-product img {
		border-bottom: 1px solid #ccc;
		margin-top: 10px;
	}

	.virtual-product {
		margin-bottom: 10px;
		border: 1px solid #ccc;
		background: #fff;
	}

	.virtual-product .media-body {
		padding: 10px;
	}

	.virtual-product .media a {
		display: block;
		width: 100%;
	}

	.live-feed-wrapper.minimized .live-feed-title{
		padding-left: 125px!important;
	}
}