/**
 * Bootstap theme vars
 * ! Do not edit source bootstrap scss, add variable overrides here, recompile base after adding / modifying
 */
/**
 * Meetingplay theme vars
 */
/**
 * Site transitions
 */
/**
* Feel free to add mixins. This is just a starting point.
* Imported into globals.scss
**/
/**
* Used for breaking change of division
* New change math.div(num1, num2)
**/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,400&display=swap");
@use "sass:math";
/**
* Rem calculator
**/
/**
* Fluid type
**/
/**
* Media Queries
* bootstrap break points: https://getbootstrap.com/docs/5.0/layout/breakpoints/
* Default: max-width - subtract .02px via bootstrap calculations
**/
/*!
   * Bootstrap  v5.2.0 (https://getbootstrap.com/)
   * Copyright 2011-2022 The Bootstrap Authors
   * Copyright 2011-2022 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #005eb8;
  --bs-secondary: #465a5d;
  --bs-success: #4ea000;
  --bs-info: #005eb8;
  --bs-warning: #E35205;
  --bs-danger: #B80009;
  --bs-light: #f8f9fa;
  --bs-dark: #101820;
  --bs-primary-rgb: 0, 94, 184;
  --bs-secondary-rgb: 70, 90, 93;
  --bs-success-rgb: 78, 160, 0;
  --bs-info-rgb: 0, 94, 184;
  --bs-warning-rgb: 227, 82, 5;
  --bs-danger-rgb: 184, 0, 9;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 16, 24, 32;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #005eb8;
  --bs-link-hover-color: #004b93;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: var(--bs-link-color);
  text-decoration: underline; }
  a:hover {
    color: var(--bs-link-hover-color); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: 2px solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #ccdff1;
  --bs-table-border-color: #b8c9d9;
  --bs-table-striped-bg: #c2d4e5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8c9d9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdcedf;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #dadedf;
  --bs-table-border-color: #c4c8c9;
  --bs-table-striped-bg: #cfd3d4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c4c8c9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cacdce;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #dceccc;
  --bs-table-border-color: #c6d4b8;
  --bs-table-striped-bg: #d1e0c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c6d4b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ccdabd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #ccdff1;
  --bs-table-border-color: #b8c9d9;
  --bs-table-striped-bg: #c2d4e5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8c9d9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdcedf;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #f9dccd;
  --bs-table-border-color: #e0c6b9;
  --bs-table-striped-bg: #edd1c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e0c6b9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e6ccbe;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f1ccce;
  --bs-table-border-color: #d9b8b9;
  --bs-table-striped-bg: #e5c2c4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d9b8b9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dfbdbf;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #101820;
  --bs-table-border-color: #282f36;
  --bs-table-striped-bg: #1c242b;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #282f36;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #222931;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #80afdc;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(0, 94, 184, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: 0.375rem; }
  .form-control-color::-webkit-color-swatch {
    border-radius: 0.375rem; }
  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px); }
  .form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + 2px); }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #80afdc;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(0, 94, 184, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #80afdc;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(0, 94, 184, 0.25); }
  .form-check-input:checked {
    background-color: #005eb8;
    border-color: #005eb8; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #005eb8;
    border-color: #005eb8;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380afdc'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 94, 184, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 94, 184, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #005eb8;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #b3cfea; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #005eb8;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #b3cfea; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: 1px 0; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #4ea000; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(78, 160, 0, 0.9);
  border-radius: 0.375rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #4ea000;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234ea000' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #4ea000;
    box-shadow: 0 0 0 0.25rem rgba(78, 160, 0, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #4ea000; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234ea000' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #4ea000;
    box-shadow: 0 0 0 0.25rem rgba(78, 160, 0, 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #4ea000; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #4ea000; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(78, 160, 0, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #4ea000; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #B80009; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(184, 0, 9, 0.9);
  border-radius: 0.375rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #B80009;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23B80009'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23B80009' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #B80009;
    box-shadow: 0 0 0 0.25rem rgba(184, 0, 9, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #B80009; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23B80009'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23B80009' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #B80009;
    box-shadow: 0 0 0 0.25rem rgba(184, 0, 9, 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #B80009; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #B80009; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(184, 0, 9, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #B80009; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check:focus + .btn, .btn:focus {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus,
    .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #005eb8;
  --bs-btn-border-color: #005eb8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00509c;
  --bs-btn-hover-border-color: #004b93;
  --bs-btn-focus-shadow-rgb: 38, 118, 195;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #004b93;
  --bs-btn-active-border-color: #00478a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #005eb8;
  --bs-btn-disabled-border-color: #005eb8; }

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #465a5d;
  --bs-btn-border-color: #465a5d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3c4d4f;
  --bs-btn-hover-border-color: #38484a;
  --bs-btn-focus-shadow-rgb: 98, 115, 117;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #38484a;
  --bs-btn-active-border-color: #354446;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #465a5d;
  --bs-btn-disabled-border-color: #465a5d; }

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #4ea000;
  --bs-btn-border-color: #4ea000;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #69ae26;
  --bs-btn-hover-border-color: #60aa1a;
  --bs-btn-focus-shadow-rgb: 66, 136, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #71b333;
  --bs-btn-active-border-color: #60aa1a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #4ea000;
  --bs-btn-disabled-border-color: #4ea000; }

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #005eb8;
  --bs-btn-border-color: #005eb8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00509c;
  --bs-btn-hover-border-color: #004b93;
  --bs-btn-focus-shadow-rgb: 38, 118, 195;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #004b93;
  --bs-btn-active-border-color: #00478a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #005eb8;
  --bs-btn-disabled-border-color: #005eb8; }

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #E35205;
  --bs-btn-border-color: #E35205;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e76c2b;
  --bs-btn-hover-border-color: #e6631e;
  --bs-btn-focus-shadow-rgb: 193, 70, 4;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e97537;
  --bs-btn-active-border-color: #e6631e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #E35205;
  --bs-btn-disabled-border-color: #E35205; }

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #B80009;
  --bs-btn-border-color: #B80009;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #9c0008;
  --bs-btn-hover-border-color: #930007;
  --bs-btn-focus-shadow-rgb: 195, 38, 46;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #930007;
  --bs-btn-active-border-color: #8a0007;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #B80009;
  --bs-btn-disabled-border-color: #B80009; }

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa; }

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #101820;
  --bs-btn-border-color: #101820;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #343b41;
  --bs-btn-hover-border-color: #282f36;
  --bs-btn-focus-shadow-rgb: 52, 59, 65;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #40464d;
  --bs-btn-active-border-color: #282f36;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #101820;
  --bs-btn-disabled-border-color: #101820; }

.btn-outline-primary {
  --bs-btn-color: #005eb8;
  --bs-btn-border-color: #005eb8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #005eb8;
  --bs-btn-hover-border-color: #005eb8;
  --bs-btn-focus-shadow-rgb: 0, 94, 184;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #005eb8;
  --bs-btn-active-border-color: #005eb8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #005eb8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #005eb8;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #465a5d;
  --bs-btn-border-color: #465a5d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #465a5d;
  --bs-btn-hover-border-color: #465a5d;
  --bs-btn-focus-shadow-rgb: 70, 90, 93;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #465a5d;
  --bs-btn-active-border-color: #465a5d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #465a5d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #465a5d;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #4ea000;
  --bs-btn-border-color: #4ea000;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #4ea000;
  --bs-btn-hover-border-color: #4ea000;
  --bs-btn-focus-shadow-rgb: 78, 160, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #4ea000;
  --bs-btn-active-border-color: #4ea000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #4ea000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4ea000;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #005eb8;
  --bs-btn-border-color: #005eb8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #005eb8;
  --bs-btn-hover-border-color: #005eb8;
  --bs-btn-focus-shadow-rgb: 0, 94, 184;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #005eb8;
  --bs-btn-active-border-color: #005eb8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #005eb8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #005eb8;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #E35205;
  --bs-btn-border-color: #E35205;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #E35205;
  --bs-btn-hover-border-color: #E35205;
  --bs-btn-focus-shadow-rgb: 227, 82, 5;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #E35205;
  --bs-btn-active-border-color: #E35205;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #E35205;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #E35205;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #B80009;
  --bs-btn-border-color: #B80009;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #B80009;
  --bs-btn-hover-border-color: #B80009;
  --bs-btn-focus-shadow-rgb: 184, 0, 9;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #B80009;
  --bs-btn-active-border-color: #B80009;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #B80009;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #B80009;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #101820;
  --bs-btn-border-color: #101820;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #101820;
  --bs-btn-hover-border-color: #101820;
  --bs-btn-focus-shadow-rgb: 16, 24, 32;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #101820;
  --bs-btn-active-border-color: #101820;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #101820;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #101820;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 38, 118, 195;
  text-decoration: underline; }
  .btn-link:focus {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem; }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #005eb8;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #005eb8;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group {
  border-radius: 0.375rem; }
  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
    background: none;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
    .nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #005eb8; }
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius); }
    .nav-pills .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion {
  --bs-accordion-color: #fff;
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0 0 0 0;
  --bs-accordion-inner-border-radius: 0 0 0 0;
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #fff;
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #80afdc;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 94, 184, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #fff;
  --bs-accordion-active-bg: #005db9; }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(0, 94, 184, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #005eb8;
  --bs-pagination-active-border-color: #005eb8;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item .page-link {
  border-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem; }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem; }

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  --bs-alert-color: #00386e;
  --bs-alert-bg: #ccdff1;
  --bs-alert-border-color: #b3cfea; }
  .alert-primary .alert-link {
    color: #002d58; }

.alert-secondary {
  --bs-alert-color: #2a3638;
  --bs-alert-bg: #dadedf;
  --bs-alert-border-color: #c8cece; }
  .alert-secondary .alert-link {
    color: #222b2d; }

.alert-success {
  --bs-alert-color: #2f6000;
  --bs-alert-bg: #dceccc;
  --bs-alert-border-color: #cae3b3; }
  .alert-success .alert-link {
    color: #264d00; }

.alert-info {
  --bs-alert-color: #00386e;
  --bs-alert-bg: #ccdff1;
  --bs-alert-border-color: #b3cfea; }
  .alert-info .alert-link {
    color: #002d58; }

.alert-warning {
  --bs-alert-color: #883103;
  --bs-alert-bg: #f9dccd;
  --bs-alert-border-color: #f7cbb4; }
  .alert-warning .alert-link {
    color: #6d2702; }

.alert-danger {
  --bs-alert-color: #6e0005;
  --bs-alert-bg: #f1ccce;
  --bs-alert-border-color: #eab3b5; }
  .alert-danger .alert-link {
    color: #580004; }

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe; }
  .alert-light .alert-link {
    color: #4f5050; }

.alert-dark {
  --bs-alert-color: #0a0e13;
  --bs-alert-bg: #cfd1d2;
  --bs-alert-border-color: #b7babc; }
  .alert-dark .alert-link {
    color: #080b0f; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #005eb8;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #005eb8;
  --bs-list-group-active-border-color: #005eb8;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(var(--bs-list-group-border-width) * -1);
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(var(--bs-list-group-border-width) * -1);
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(var(--bs-list-group-border-width) * -1);
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #00386e;
  background-color: #ccdff1; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #00386e;
    background-color: #b8c9d9; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #00386e;
    border-color: #00386e; }

.list-group-item-secondary {
  color: #2a3638;
  background-color: #dadedf; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #2a3638;
    background-color: #c4c8c9; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #2a3638;
    border-color: #2a3638; }

.list-group-item-success {
  color: #2f6000;
  background-color: #dceccc; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #2f6000;
    background-color: #c6d4b8; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #2f6000;
    border-color: #2f6000; }

.list-group-item-info {
  color: #00386e;
  background-color: #ccdff1; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #00386e;
    background-color: #b8c9d9; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #00386e;
    border-color: #00386e; }

.list-group-item-warning {
  color: #883103;
  background-color: #f9dccd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #883103;
    background-color: #e0c6b9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #883103;
    border-color: #883103; }

.list-group-item-danger {
  color: #6e0005;
  background-color: #f1ccce; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #6e0005;
    background-color: #d9b8b9; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #6e0005;
    border-color: #6e0005; }

.list-group-item-light {
  color: #636464;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #636464;
    background-color: #e5e5e5; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #636464;
    border-color: #636464; }

.list-group-item-dark {
  color: #0a0e13;
  background-color: #cfd1d2; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #0a0e13;
    background-color: #babcbd; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #0a0e13;
    border-color: #0a0e13; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(0, 94, 184, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  position: absolute;
  z-index: 1090;
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width)); }
  .toast-header .btn-close {
    margin-right: calc(var(--bs-toast-padding-x) * -.5);
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(var(--bs-modal-header-padding-y) * -.5) calc(var(--bs-modal-header-padding-x) * -.5) calc(var(--bs-modal-header-padding-y) * -.5) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header,
  .modal-fullscreen .modal-footer {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0; }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0; }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius); }

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: var(--bs-heading-color);
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius); }
  .popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height); }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc((var(--bs-popover-arrow-height) * -1) - var(--bs-popover-border-width)); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg); }

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc((var(--bs-popover-arrow-height) * -1) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc((var(--bs-popover-arrow-height) * -1) - var(--bs-popover-border-width)); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg); }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(var(--bs-popover-arrow-width) * -.5);
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg); }

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc((var(--bs-popover-arrow-height) * -1) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color); }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; } }

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(var(--bs-offcanvas-padding-y) * -.5);
    margin-right: calc(var(--bs-offcanvas-padding-x) * -.5);
    margin-bottom: calc(var(--bs-offcanvas-padding-y) * -.5); }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(0, 94, 184, var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(70, 90, 93, var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(78, 160, 0, var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(0, 94, 184, var(--bs-bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(227, 82, 5, var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(184, 0, 9, var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(16, 24, 32, var(--bs-bg-opacity, 1)) !important; }

.link-primary {
  color: #005eb8 !important; }
  .link-primary:hover, .link-primary:focus {
    color: #004b93 !important; }

.link-secondary {
  color: #465a5d !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: #38484a !important; }

.link-success {
  color: #4ea000 !important; }
  .link-success:hover, .link-success:focus {
    color: #71b333 !important; }

.link-info {
  color: #005eb8 !important; }
  .link-info:hover, .link-info:focus {
    color: #004b93 !important; }

.link-warning {
  color: #E35205 !important; }
  .link-warning:hover, .link-warning:focus {
    color: #e97537 !important; }

.link-danger {
  color: #B80009 !important; }
  .link-danger:hover, .link-danger:focus {
    color: #930007 !important; }

.link-light {
  color: #f8f9fa !important; }
  .link-light:hover, .link-light:focus {
    color: #f9fafb !important; }

.link-dark {
  color: #101820 !important; }
  .link-dark:hover, .link-dark:focus {
    color: #0d131a !important; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-1 {
  --bs-border-width: 1px; }

.border-2 {
  --bs-border-width: 2px; }

.border-3 {
  --bs-border-width: 3px; }

.border-4 {
  --bs-border-width: 4px; }

.border-5 {
  --bs-border-width: 5px; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/*
* Custom Fonts
* Change as you need per build
* Imported into globals.scss
*/
/*
* Site Typography
*/
body {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif; }

h1, .h1,
.h1 {
  font-size: calc(2rem + 8 * ( (100vw - 420px) / 780)); }
  @media screen and (max-width: 420px) {
    h1, .h1,
    .h1 {
      font-size: 2rem; } }
  @media screen and (min-width: 1200px) {
    h1, .h1,
    .h1 {
      font-size: 2.5rem; } }

h2, .h2,
.h2 {
  font-size: calc(1.75rem + 4 * ( (100vw - 420px) / 780)); }
  @media screen and (max-width: 420px) {
    h2, .h2,
    .h2 {
      font-size: 1.75rem; } }
  @media screen and (min-width: 1200px) {
    h2, .h2,
    .h2 {
      font-size: 2rem; } }

h3, .h3,
.h3 {
  font-size: calc(1.5rem + 4 * ( (100vw - 420px) / 780)); }
  @media screen and (max-width: 420px) {
    h3, .h3,
    .h3 {
      font-size: 1.5rem; } }
  @media screen and (min-width: 1200px) {
    h3, .h3,
    .h3 {
      font-size: 1.75rem; } }

h4, .h4,
.h4 {
  font-size: calc(1.25rem + 4 * ( (100vw - 420px) / 780)); }
  @media screen and (max-width: 420px) {
    h4, .h4,
    .h4 {
      font-size: 1.25rem; } }
  @media screen and (min-width: 1200px) {
    h4, .h4,
    .h4 {
      font-size: 1.5rem; } }

h5, .h5,
.h5 {
  font-size: calc(1rem + 4 * ( (100vw - 420px) / 780)); }
  @media screen and (max-width: 420px) {
    h5, .h5,
    .h5 {
      font-size: 1rem; } }
  @media screen and (min-width: 1200px) {
    h5, .h5,
    .h5 {
      font-size: 1.25rem; } }

h6, .h6,
.h6 {
  font-size: calc(0.875rem + 2 * ( (100vw - 420px) / 780)); }
  @media screen and (max-width: 420px) {
    h6, .h6,
    .h6 {
      font-size: 0.875rem; } }
  @media screen and (min-width: 1200px) {
    h6, .h6,
    .h6 {
      font-size: 1rem; } }

/**
 * Event Theme - Code below should focus on setting colors only, use page specific scss or components.scss for event specific styles
 */
.page-contents {
  background: #fff; }

.page-header,
.alpha-nav-wrap {
  background: #005eb8;
  color: #fff; }

.page-header-alt {
  background: #00386e;
  color: #fff; }

.page-header-alt a,
.page-header-alt .nav-tabs .nav-link,
.page-header-alt .nav-tabs .nav-link.active,
.page-header-alt .nav-tabs .nav-link:hover,
.page-header-alt .nav-tabs .nav-link:focus,
#favorites-sidebar a,
#favorites-sidebar .nav-tabs .nav-link.active,
#favorites-sidebar .nav-tabs .nav-link:hover,
#favorites-sidebar .nav-tabs .nav-link:focus {
  color: #fff; }

.page-header a.btn-link,
.page-header a.btn-link:hover,
.page-header a.btn-link:focus {
  color: #fff; }

.alpha-nav-item.active {
  background: #337ec6;
  color: #fff; }

#favorites-sidebar {
  background: #004b93;
  color: #fff; }

#favorites-sidebar a.list-group-item {
  background: transparent;
  color: #fff; }

#favorites-sidebar a.list-group-item:hover {
  background: #337ec6; }

.bg-primary {
  color: #fff; }

.bg-info {
  color: #fff; }

.list-group-item a:not(.btn) {
  color: #212529; }

#favorites-sidebar .accordion-item, #favorites-sidebar .list-group-item {
  border-color: #1a6ebf; }

#favorites-sidebar .accordion-button, #favorites-sidebar .accordion-item {
  color: #fff; }

#favorites-sidebar .accordion-button::after {
  filter: invert(1); }

#favorites-sidebar .accordion-button:not(.collapsed) {
  background: #1a6ebf; }

#sidebar-column {
  background: white; }

.btn-success {
  color: #fff !important; }

.nav-tabs .nav-link, .action-item {
  color: black; }

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: #005eb8;
  transition: unset;
  color: #005eb8; }

a.action-item.display, a.action-item:focus {
  border-bottom: 4px solid #005eb8;
  color: #005eb8; }

#app-header a.header-btn {
  background: #002f6c;
  color: #fff; }

#sidebar-left {
  background: rgba(0, 0, 0, 0.7);
  color: #fff; }

#sidebar-left a, #sidebar-left table {
  color: #fff; }

#app-header a#left-sidebar-toggle > .bar {
  background: #fff; }

/* session admin panel */
.notification-banner.notification-banner-info .banner-light, .notification-banner.notification-banner-info .banner-link {
  border-color: #005eb8;
  background: #005eb8; }

.notification-banner.notification-banner-info .banner-icon:before {
  color: #005eb8; }

.swal2-popup .swal2-styled.swal2-confirm {
  border-color: #005eb8;
  background: #005eb8;
  color: #fff; }

.page-admin-panel {
  /*Tabs */ }
  .page-admin-panel .panel {
    margin-top: 2em;
    text-align: center; }
  .page-admin-panel .panel-body {
    overflow: hidden; }
  .page-admin-panel .panel-header {
    background: #0753a0; }
  .page-admin-panel .count {
    font-size: 3em;
    line-height: 1.25em; }
  .page-admin-panel [data-require] {
    display: none; }
  .page-admin-panel .simple-tab {
    display: none;
    padding: 20px 0; }
  .page-admin-panel .simple-tab.current-item {
    display: block; }
  .page-admin-panel .simple-tab-nav a {
    display: inline-block;
    text-decoration: none;
    text-align: center;
    padding: 10px 15px;
    color: #000; }
  .page-admin-panel .simple-tab-nav a.current-item {
    border-bottom: 4px solid #000;
    font-weight: bold; }

@use '../../css-modernize/variables.scss' as *;
@use '../../css-modernize/partials/mixins' as *;
.page-agenda-grid {
  /**
	* Day Nav List
	**/
  /**
	* Timeslot Nav
	**/
  /**
	* Time slot
	**/
  /**
	* Session Block
	**/
  /**
	* Speaker Block
	**/
  /**
	* Live now
	**/
  /**
	* Sponsor block
	**/
  /**
	* Time slot row? Might not be used anymore.
	**/
  /**
	* Agenda Grid Slide out
	**/ }

@keyframes liveNowPulse {
  0% {
    background: rgba(255, 54, 33, 0.15);
    box-shadow: 0 0 0 0px rgba(255, 54, 33, 0.3);
    transform: scale(1.05); }
  80% {
    background: rgba(241, 33, 9, 0);
    box-shadow: 0 0 0 1em rgba(241, 33, 9, 0);
    transform: scale(1); } }

@-webkit-keyframes liveNowPulse {
  0% {
    background: rgba(255, 54, 33, 0.15);
    box-shadow: 0 0 0 0px rgba(255, 54, 33, 0.3);
    transform: scale(1.05); }
  80% {
    background: rgba(241, 33, 9, 0);
    box-shadow: 0 0 0 1em rgba(241, 33, 9, 0);
    transform: scale(1); } }
  .page-agenda-grid #global-container {
    max-width: 100% !important; }
  .page-agenda-grid .agenda-grid {
    padding: 0px;
    /**
		* Agenda Day View
		**/
    /**
		* Agenda Glance View
		**/
    /**
		* Agenda My Agenda View
		**/
    /**
		* Agenda Search Results View
		**/ }
    .page-agenda-grid .agenda-grid .page-contents {
      max-width: 1400px;
      border-radius: 12px;
      background: rgba(0, 0, 0, 0.2);
      margin: 0 auto 1.25rem;
      padding: 1.875rem; }
    .page-agenda-grid .agenda-grid__main-content {
      position: relative;
      min-height: initial !important;
      padding: 0 4.375rem; }
    .page-agenda-grid .agenda-grid__view-day {
      width: 100%;
      overflow: hidden; }
    .page-agenda-grid .agenda-grid__view-my-agenda {
      margin-bottom: 1.875rem;
      padding-bottom: 1.875rem; }
    .page-agenda-grid .agenda-grid__view-search-results {
      margin-top: 5.625rem; }
      .page-agenda-grid .agenda-grid__view-search-results + .agenda-grid__view-search-results {
        margin-top: 1.25rem; }
      .page-agenda-grid .agenda-grid__view-search-results .searchResults-day-title {
        color: #f8f9fa;
        font: 400 24px/30px "Arial", sans-serif;
        letter-spacing: -0.5px;
        margin: 0 0 3px;
        text-transform: uppercase; }
      .page-agenda-grid .agenda-grid__view-search-results .searchResults-timeslot-container {
        margin-bottom: 1.25rem; }
      .page-agenda-grid .agenda-grid__view-search-results .searchResults-time-title {
        color: #fff;
        font: 600 19px/25px "Arial", sans-serif;
        letter-spacing: 1px;
        margin: 0; }
        .page-agenda-grid .agenda-grid__view-search-results .searchResults-time-title small, .page-agenda-grid .agenda-grid__view-search-results .searchResults-time-title .small {
          font: 600 14px/25px "Arial", sans-serif;
          letter-spacing: 1px; }
  .page-agenda-grid .agenda-grid-day-nav {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 1em 0 3em;
    padding: 0 0 1em;
    overflow-x: auto; }
    .page-agenda-grid .agenda-grid-day-nav__single {
      background: none;
      border: none;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 38px;
      text-align: left;
      white-space: nowrap;
      color: #ccc;
      width: auto;
      padding: 0.75em 0.75em;
      margin: 0 0 0 0;
      border-bottom: 5px solid transparent;
      transition: 0.2s ease-in-out all; }
      .page-agenda-grid .agenda-grid-day-nav__single.--active {
        color: #f8f9fa;
        border-bottom-color: #005eb8; }
      .page-agenda-grid .agenda-grid-day-nav__single:focus {
        outline: none; }
  .page-agenda-grid .agenda-grid-timeslot-header {
    display: block;
    overflow: hidden;
    background: #005eb8; }
    .page-agenda-grid .agenda-grid-timeslot-header__inner-wrapper {
      display: flex;
      transition: transform 0.2s;
      overflow: hidden;
      overflow-y: auto; }
    .page-agenda-grid .agenda-grid-timeslot-header__single-nav {
      position: relative; }
      .page-agenda-grid .agenda-grid-timeslot-header__single-nav:last-child p {
        border: none; }
      .page-agenda-grid .agenda-grid-timeslot-header__single-nav p {
        color: #f8f9fa;
        padding: 2px 10px 3px;
        font: 600 16px/25px "Arial", sans-serif;
        border-right: 1px solid rgba(255, 255, 255, 0.25);
        margin: 0;
        background: #005eb8; }
        .page-agenda-grid .agenda-grid-timeslot-header__single-nav p small, .page-agenda-grid .agenda-grid-timeslot-header__single-nav p .small {
          font-weight: 600;
          font-size: 14px;
          margin-left: 5px; }
    .page-agenda-grid .agenda-grid-timeslot-header__toggle-offset {
      position: absolute;
      top: 0;
      border: none;
      width: 70px;
      height: 1.9375rem;
      overflow: hidden;
      background: #005eb8;
      z-index: 10;
      color: #f8f9fa;
      font: 600 14px/30px "Arial", sans-serif;
      text-transform: uppercase;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .page-agenda-grid .agenda-grid-timeslot-header__toggle-offset:focus, .page-agenda-grid .agenda-grid-timeslot-header__toggle-offset:active {
        box-shadow: none;
        outline: none; }
      .page-agenda-grid .agenda-grid-timeslot-header__toggle-offset:disabled {
        opacity: .4; }
      .page-agenda-grid .agenda-grid-timeslot-header__toggle-offset span {
        line-height: 100%;
        padding-right: 0.1875rem; }
      .page-agenda-grid .agenda-grid-timeslot-header__toggle-offset i {
        display: inline-block;
        font-size: 1.375rem; }
      .page-agenda-grid .agenda-grid-timeslot-header__toggle-offset.--prev {
        left: 0; }
      .page-agenda-grid .agenda-grid-timeslot-header__toggle-offset.--next {
        right: 0px; }
  .page-agenda-grid .agenda-grid-timeslot {
    overflow: hidden;
    overflow-y: auto; }
    .page-agenda-grid .agenda-grid-timeslot__content {
      display: flex;
      transition: transform 0.2s;
      overflow: hidden;
      overflow-y: auto; }
    .page-agenda-grid .agenda-grid-timeslot__single {
      position: relative; }
    .page-agenda-grid .agenda-grid-timeslot__single-inner {
      overflow: hidden;
      overflow-y: auto;
      margin: 10px 0 0;
      padding: 0 2px; }
      .page-agenda-grid .agenda-grid-timeslot__single-inner > .agenda-grid-session-block {
        margin-top: 0; }
  .page-agenda-grid .agenda-grid-session-block {
    border-radius: 4px;
    border: 1px solid #d1d1d1;
    background: #eff3f4;
    display: block;
    margin: 4px 0;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
    /**
		* Toggled class
		**/ }
    .page-agenda-grid .agenda-grid-session-block:hover {
      color: #101820; }
      .page-agenda-grid .agenda-grid-session-block:hover a {
        color: #101820; }
    .page-agenda-grid .agenda-grid-session-block__inner {
      position: relative;
      padding: 13px 30px 45px 13px;
      display: block;
      color: #101820;
      min-height: 211px; }
      .page-agenda-grid .agenda-grid-session-block__inner:hover {
        text-decoration: none; }
      @media only screen and (max-width: 990.98px) {
        .page-agenda-grid .agenda-grid-session-block__inner {
          padding-bottom: 60px; } }
      @media only screen and (max-width: 767.98px) {
        .page-agenda-grid .agenda-grid-session-block__inner {
          padding-bottom: 80px; } }
    .page-agenda-grid .agenda-grid-session-block__title h4, .page-agenda-grid .agenda-grid-session-block__title .h4 {
      font: 600 12px/17px "Arial", sans-serif;
      letter-spacing: 2px;
      color: #818181;
      margin: 0 0 4px; }
    .page-agenda-grid .agenda-grid-session-block__title h3, .page-agenda-grid .agenda-grid-session-block__title .h3 {
      font: 600 16px/20px "Arial", sans-serif;
      color: #101820;
      margin: 0; }
    .page-agenda-grid .agenda-grid-session-block__description {
      max-height: 250px;
      overflow-y: auto;
      margin-top: 1em;
      padding-right: 30px;
      margin-right: -30px; }
    .page-agenda-grid .agenda-grid-session-block__speakers-title {
      font: 400 14px/17px "Arial", sans-serif;
      color: #7a7a7a;
      margin: 4px -20px 0 0;
      max-height: 70px;
      overflow: auto; }
    .page-agenda-grid .agenda-grid-session-block__speaker-list {
      max-height: 100px;
      width: 100%;
      overflow-y: auto; }
    .page-agenda-grid .agenda-grid-session-block__tags {
      font: 700 13px/16px "Arial", sans-serif;
      letter-spacing: 1px;
      text-align: left;
      margin: 1em 0 0;
      text-transform: uppercase;
      position: absolute;
      left: 13px;
      right: 0;
      bottom: 3px; }
      .page-agenda-grid .agenda-grid-session-block__tags h5, .page-agenda-grid .agenda-grid-session-block__tags .h5 {
        font-weight: normal;
        text-transform: none;
        letter-spacing: initial;
        font-size: 0.8125rem; }
      .page-agenda-grid .agenda-grid-session-block__tags .badge {
        padding: 0.3em 0.7em;
        margin: 0 5px 2px 0;
        background-color: #1b3139; }
    .page-agenda-grid .agenda-grid-session-block__actions {
      position: absolute;
      right: 0;
      top: 0; }
      .page-agenda-grid .agenda-grid-session-block__actions > button {
        display: block;
        border: none;
        background: none;
        padding: 2px 5px;
        color: inherit;
        font-size: 18px; }
        .page-agenda-grid .agenda-grid-session-block__actions > button:focus, .page-agenda-grid .agenda-grid-session-block__actions > button:active {
          outline: none; }
        .page-agenda-grid .agenda-grid-session-block__actions > button.--active i {
          color: var(--secondary-bc); }
      .page-agenda-grid .agenda-grid-session-block__actions .fa {
        color: #dadada; }
      .page-agenda-grid .agenda-grid-session-block__actions .addeventatc {
        width: 26px;
        box-shadow: none !important;
        border: none;
        background: none;
        padding: 5px 2px;
        z-index: 1; }
      .page-agenda-grid .agenda-grid-session-block__actions .addeventatc_icon {
        display: none; }
      .page-agenda-grid .agenda-grid-session-block__actions .fa-calendar-alt {
        display: block;
        text-align: center;
        font-size: 18px; }
      .page-agenda-grid .agenda-grid-session-block__actions .addeventatc_dropdown.addeventatc-selected {
        top: -20px !important;
        right: 15px !important;
        left: auto !important; }
    .page-agenda-grid .agenda-grid-session-block .live-now-indicator {
      color: #000;
      position: absolute;
      bottom: 13px;
      right: 13px; }
    .page-agenda-grid .agenda-grid-session-block.isToggled {
      background: #ccdade;
      transition: all 0.2s; }
      .page-agenda-grid .agenda-grid-session-block.isToggled .agenda-grid-session-block__actions .fa {
        color: #bbb; }
        .page-agenda-grid .agenda-grid-session-block.isToggled .agenda-grid-session-block__actions .fa.--active {
          color: var(--secondary-bc); }
  .page-agenda-grid .agenda-grid-speaker-block {
    position: relative;
    display: flex;
    /**
		* Overlay styles
		**/ }
    .page-agenda-grid .agenda-grid-speaker-block + .agenda-grid-speaker-block {
      margin-top: 0.9375rem; }
    .page-agenda-grid .agenda-grid-speaker-block__image {
      width: 2.5rem;
      height: 2.5rem;
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat; }
    .page-agenda-grid .agenda-grid-speaker-block__info {
      margin-left: 0.625rem; }
      .page-agenda-grid .agenda-grid-speaker-block__info p {
        font-size: 0.75rem;
        margin: 0px; }
        .page-agenda-grid .agenda-grid-speaker-block__info p.name {
          font-size: 0.875rem; }
    .page-agenda-grid .agenda-grid-speaker-block.--overlay .agenda-grid-speaker-block__image {
      width: 3.75rem;
      height: 3.75rem; }
    .page-agenda-grid .agenda-grid-speaker-block.--overlay .agenda-grid-speaker-block__info p {
      font-size: 0.875rem; }
      .page-agenda-grid .agenda-grid-speaker-block.--overlay .agenda-grid-speaker-block__info p.name {
        font-size: 1rem; }
  .page-agenda-grid .live-now-indicator {
    color: #f8f9fa;
    font: 600 12px/18px "Arial", sans-serif;
    text-transform: uppercase;
    text-align: right;
    letter-spacing: 0.5px;
    line-height: 30px;
    padding: 0 10px;
    pointer-events: none;
    animation: liveNowPulse 3s ease-in-out 0s infinite;
    -webkit-animation: liveNowPulse 3s ease-in-out 0s infinite;
    border-radius: 4px; }
    .page-agenda-grid .live-now-indicator .fas {
      font-size: 18px;
      margin-left: 2px;
      position: relative;
      top: 2px; }
  .page-agenda-grid .sponsor-block {
    /**
		* Slide out
		**/ }
    .page-agenda-grid .sponsor-block + .sponsor-block {
      margin-top: 0.9375rem; }
    .page-agenda-grid .sponsor-block.--slide-out {
      max-width: 60%; }
  .page-agenda-grid .timeslot-row {
    margin-top: 1.5em; }
    .page-agenda-grid .timeslot-row .timeslot-row-header {
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-weight: 300;
      color: #f8f9fa;
      margin: 0 0 0.5em; }
  .page-agenda-grid .session-detail-close-overlay {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s ease-in-out all;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(16, 24, 32, 0.6); }
    .page-agenda-grid .session-detail-close-overlay.--visible {
      opacity: 1;
      visibility: visible; }
  .page-agenda-grid .session-detail-overlay {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: auto;
    width: 450px;
    max-width: 75%;
    background-color: rgba(0, 94, 184, 0.8);
    box-shadow: -4px 5px 20px rgba(0, 0, 0, 0.25);
    color: #f8f9fa;
    z-index: 3000;
    transform: translateX(450px);
    transition: 0.2s ease-in-out;
    backdrop-filter: blur(12px); }
    .page-agenda-grid .session-detail-overlay.--visible {
      transform: translateX(0);
      transition: 0.2s ease-in-out; }
    .page-agenda-grid .session-detail-overlay__header {
      padding: 0.9375rem 1.875rem;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .page-agenda-grid .session-detail-overlay__header button {
        background: none;
        border: none;
        margin: -15px;
        padding: 15px;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.5); }
        .page-agenda-grid .session-detail-overlay__header button:active, .page-agenda-grid .session-detail-overlay__header button:focus {
          outline: none;
          box-sizing: none; }
        .page-agenda-grid .session-detail-overlay__header button.active {
          color: var(--secondary-bc); }
      .page-agenda-grid .session-detail-overlay__header .close-overlay button {
        color: #f8f9fa;
        font-size: 20px;
        transition: 0.2s ease-in-out all; }
      .page-agenda-grid .session-detail-overlay__header .close-overlay:hover button {
        transform: rotate(180deg); }
      .page-agenda-grid .session-detail-overlay__header .header-actions {
        display: flex;
        align-items: center; }
      .page-agenda-grid .session-detail-overlay__header .addeventatc_icon {
        display: none; }
      .page-agenda-grid .session-detail-overlay__header .addeventatc {
        padding: 15px;
        background: none;
        border: none;
        box-shadow: none !important; }
        .page-agenda-grid .session-detail-overlay__header .addeventatc:focus, .page-agenda-grid .session-detail-overlay__header .addeventatc:active {
          box-shadow: none;
          outline: none; }
        .page-agenda-grid .session-detail-overlay__header .addeventatc i {
          font-size: 1.25rem;
          color: #f8f9fa; }
      .page-agenda-grid .session-detail-overlay__header .addeventatc_dropdown {
        margin-top: 50px;
        margin-left: -10px; }
    .page-agenda-grid .session-detail-overlay__content {
      position: relative;
      padding: 0rem 1.875rem 0.9375rem;
      overflow-y: auto;
      max-height: 90vh; }
    .page-agenda-grid .session-detail-overlay__title {
      margin-bottom: 0.9375rem; }
      .page-agenda-grid .session-detail-overlay__title p {
        margin: 0px;
        font-size: calc(1.125rem + 6 * ( (100vw - 420px) / 780));
        font-weight: 700; }
        @media screen and (max-width: 420px) {
          .page-agenda-grid .session-detail-overlay__title p {
            font-size: 1.125rem; } }
        @media screen and (min-width: 1200px) {
          .page-agenda-grid .session-detail-overlay__title p {
            font-size: 1.5rem; } }
    .page-agenda-grid .session-detail-overlay__speakers {
      max-height: 200px;
      overflow-y: auto; }
    .page-agenda-grid .session-detail-overlay__description {
      color: #f8f9fa;
      overflow-y: auto;
      max-height: 12.5rem;
      margin-top: 1.25rem; }
      .page-agenda-grid .session-detail-overlay__description > * {
        color: #f8f9fa; }
      .page-agenda-grid .session-detail-overlay__description a {
        color: #f8f9fa;
        text-decoration: underline; }

.page-agenda .accordion-button, .page-agenda .accordion-item {
  background: transparent; }

/**
 * Description: Chat Room Styles
 * Version: 1.0.0
 * Last update: 2020/07/13
 * Author: Ryan Taggart <ryan.t@meetingplay.com>
 */
.page-chat-room {
  /* ==========================================================================
   Main Chat Comment Container
   ========================================================================== */
  /* ==========================================================================
	Main Chat Comments
	=========================================================================== */
  /* Header
	========================================================================== */
  /* Comment
	========================================================================== */
  /* Comment Footer
	========================================================================== */
  /* Moderation button
	========================================================================== */
  /* ==========================================================================
	Chat Details Overlay
	========================================================================== */
  /* Details Footer
	========================================================================== */
  /* Close button
	========================================================================== */ }
  .page-chat-room #page-chat-room {
    position: relative; }
  .page-chat-room #page-chat-room .scrollable {
    overflow-x: hidden; }
  .page-chat-room .chatRoomForm-wrapper {
    background: #eee;
    border-bottom: 1px solid #ccc;
    overflow: hidden; }
  .page-chat-room .chatRoomForm-wrapper textarea {
    border-radius: none;
    max-width: 100%;
    width: 100%;
    height: 100px;
    padding: 1em; }
  .page-chat-room .chatRoomForm-wrapper .btn {
    margin: 1em; }
  .page-chat-room .comment-list,
  .page-chat-room .reply-container {
    clear: both; }
  .page-chat-room #chat-room-container form {
    overflow: hidden;
    padding: 1em;
    border-radius: 8px;
    background: #eee;
    border-bottom: 1px solid #ccc; }
  .page-chat-room #chat-room-container form.disabled,
  .page-chat-room #chat-room-container form.disabled:hover {
    opacity: .8;
    pointer-events: none;
    cursor: not-allowed; }
  .page-chat-room .reply-container {
    padding: 1em; }
  .page-chat-room #chat-room-container .comment-detail-overlay form {
    padding: 1em 2em; }
  .page-chat-room #chat-room-container .chat-comment-body {
    padding-right: 60px; }
  .page-chat-room .chat-comment {
    border-bottom: 1px solid #ccc;
    position: relative;
    cursor: pointer; }
  .page-chat-room .chat-comment.isActive {
    background: #f2f2f2; }
  .page-chat-room .chat-comment.isActive::after {
    content: '';
    position: absolute;
    right: -19px;
    top: 50px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 0 50px 50px;
    border-color: transparent transparent transparent #f2f2f2;
    clear: both; }
  .page-chat-room .chat-comment:after {
    content: "\f105";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    position: absolute;
    top: 50%;
    right: 0.75em;
    margin-top: -0.5em;
    font-size: 2em; }
  .page-chat-room .comment-detail-overlay .chat-comment {
    cursor: initial;
    overflow-y: auto;
    height: 76vh; }
  .page-chat-room .comment-detail-overlay .chat-comment:after {
    display: none; }
  .page-chat-room .chat-comment-header {
    overflow: hidden;
    padding: 1.5em 1.5em; }
  .page-chat-room .chat-comment-header a {
    display: block;
    text-decoration: none;
    color: #000; }
  .page-chat-room .chat-comment-header a:active,
  .page-chat-room .chat-comment-header a:focus {
    box-shadow: none;
    text-decoration: none; }
  .page-chat-room .chat-comment-header .profile-photo {
    width: 3em;
    height: 3em;
    border-radius: 1.5em;
    background-size: cover;
    float: left;
    margin-right: 1.5em; }
  .page-chat-room .chat-comment-header h3, .page-chat-room .chat-comment-header .h3 {
    margin: 0 0 0.2em; }
  .page-chat-room .chat-comment-header .timestamp {
    font-style: italic;
    margin: 0;
    font-size: 0.8em;
    color: #333; }
  .page-chat-room .chat-comment-body {
    padding: 0 1em 0; }
  .page-chat-room .chat-comment-header h6, .page-chat-room .chat-comment-header .h6 {
    margin-bottom: 0;
    padding-top: 0.5rem; }
  .page-chat-room .details {
    display: inline-block; }
  .page-chat-room .chat-comment-footer {
    padding: 1em 1.5em;
    margin-bottom: 0.5em;
    font-size: .8rem; }
  .page-chat-room .chat-comment-footer .fal {
    margin-right: 1em; }
  .page-chat-room .toggle-chatRoomCommentStatus {
    margin-left: 1em; }
  .page-chat-room .chat-details {
    position: absolute;
    top: 50px;
    height: 100%;
    width: 100%;
    z-index: 99;
    background: #fff;
    background: rgba(0, 0, 0, 0.95);
    border: 1px solid #ccc;
    overflow-y: auto;
    padding-bottom: 60px; }
  .page-chat-room .chat-details .chat-comment-header {
    background: rgba(0, 0, 0, 0.1);
    padding: 1.5em; }
  .page-chat-room .chat-details .chat-comment-body {
    padding: 0.75em;
    font-size: 2em;
    max-height: 45%;
    overflow-y: auto; }
  .page-chat-room .chat-details .chat-comment-footer {
    background: rgba(0, 0, 0, 0.1); }
  .page-chat-room .chat-details .chat-comment-footer textarea {
    height: 2.525em;
    min-height: 2.525em;
    line-height: 1.525em;
    float: left;
    width: 75%;
    max-width: 75%;
    margin-right: 5%; }
  .page-chat-room .chat-details .chat-comment-footer button {
    float: right;
    width: 20%; }
  .page-chat-room .chat-details-reply {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 1em 1em 1em 5em;
    position: relative; }
  .page-chat-room .chat-details-reply h6, .page-chat-room .chat-details-reply .h6 {
    font-size: .8rem;
    margin-bottom: 0;
    padding-top: .5rem; }
  .page-chat-room .chat-details-reply + .chat-details-reply {
    margin-top: 1em; }
  .page-chat-room .chat-details-reply .profile-photo {
    width: 3em;
    height: 3em;
    background-size: cover;
    border-radius: 1.5em;
    position: absolute;
    left: 1em;
    top: 1em; }
  .page-chat-room .chat-details-reply h3, .page-chat-room .chat-details-reply .h3 {
    margin: 0; }
  .page-chat-room .chat-details-reply .timestamp {
    position: absolute;
    top: 1em;
    right: 1.5em;
    font-style: italic;
    color: #333;
    font-size: 0.8em; }
  .page-chat-room .chat-details-reply .reply {
    margin: 0.25em 0 0; }
  .page-chat-room .chat-details-close {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.125em;
    background: #4a4f55;
    border-color: #aaa;
    color: #fff;
    cursor: pointer; }
  .page-chat-room .chat-details-close .fa {
    line-height: 2.5em;
    text-align: center;
    display: block; }
  .page-chat-room .comment-detail-overlay {
    transform: translateX(100%);
    transition: transform 0.25s ease-in-out;
    border-radius: 8px;
    position: fixed;
    width: 29%;
    max-width: 550px;
    background: #fff; }
  .page-chat-room .comment-detail-overlay.isVisible {
    transform: translateX(0);
    transition: transform 0.25s ease-in-out;
    border: 1px solid #ccc; }
  .page-chat-room .comment-detail-overlay .chat-details-reply .timestamp {
    position: relative;
    top: 0;
    left: 0; }
  .page-chat-room #form_replyToComment {
    padding: 15px; }
  .page-chat-room #chat-room-replies {
    min-height: 100%; }
  .page-chat-room .chat-details-reply .btn-danger {
    margin-left: 0;
    margin-top: 15px; }
  .page-chat-room .scrolledDown .comment-detail-overlay {
    top: 80px; }
  .page-chat-room .scrolledDown .comment-detail-overlay .chat-comment {
    cursor: initial;
    overflow-y: auto;
    height: 90vh; }
  @media (max-width: 1400px) {
    .page-chat-room .comment-detail-overlay {
      width: 44%;
      max-width: 100%; } }
  @media (max-width: 1350px) {
    .page-chat-room .comment-detail-overlay {
      width: 37%; } }
  @media (max-width: 768px) {
    .page-chat-room .comment-detail-overlay {
      transform: translateX(100%);
      transition: transform 0.25s ease-in-out;
      border-radius: 8px;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      margin: 0;
      background: #fff;
      z-index: 9999; }
    .page-chat-room .comment-detail-overlay .chat-comment {
      height: 100%; } }

/*
=================================================================================================================
Section: Chat Pages
Description: Used for the private messaging chat overall & detail pages
=================================================================================================================
*/
.page-group-chat {
  /*** Message History (Individual Chat Page) ***/
  /*** Message Item ***/
  /*** My Messages ***/ }
  .page-group-chat .message-history {
    margin: 20px 0; }
  .page-group-chat .message-item {
    width: 60%;
    position: relative;
    margin: 10px 15px 15px;
    float: left; }
  .page-group-chat .message-item .image-wrap {
    width: 40px;
    max-height: 40px;
    border-radius: 4px;
    border: 1px solid #ddd;
    overflow: hidden;
    position: absolute;
    top: 5px;
    left: -10px; }
  .page-group-chat .message-meta {
    font-size: .7rem;
    color: #555;
    margin: 0 0 5px 42px;
    text-align: left; }
  .page-group-chat .message-content {
    font-size: 1.1rem;
    padding: 8px 15px 8px 42px;
    margin: 0; }
  .page-group-chat .message-content:after {
    content: '';
    border-left: 0 solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    left: 5px;
    top: 100%; }
  .page-group-chat .message-item.me .image-wrap {
    display: none; }
  .page-group-chat .message-item.me {
    float: right; }
  .page-group-chat .message-item.me .message-meta {
    margin-left: 15px; }
  .page-group-chat .message-item.me .message-content {
    background: #fff;
    padding: 10px 15px;
    color: #000;
    border: 1px solid #ccc; }
  .page-group-chat .message-item.me .message-content:after {
    border-left-width: 10px;
    border-right-width: 0;
    border-top-color: #ccc;
    right: 5px;
    left: auto; }

.page-conversation-wall,
.page-followed-items {
  /* The ribbons */
  /* Different positions */ }
  .page-conversation-wall #skip-to,
  .page-followed-items #skip-to {
    overflow: visible; }
  .page-conversation-wall .feed-item,
  .page-followed-items .feed-item {
    margin-bottom: 10px !important; }
  .page-conversation-wall .card,
  .page-followed-items .card {
    border-color: #dee2e6;
    border-radius: 0; }
  .page-conversation-wall .card-body,
  .page-followed-items .card-body {
    padding: 0; }
  .page-conversation-wall .list-group-item.fetch-comment,
  .page-followed-items .list-group-item.fetch-comment {
    border: none; }
  .page-conversation-wall .feed-item-loader,
  .page-followed-items .feed-item-loader {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background-color: #2d71d6;
    animation: loading 1s linear infinite;
    display: none; }

@keyframes loading {
  from {
    left: -200px;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }
  .page-conversation-wall .follower-tag,
  .page-followed-items .follower-tag {
    display: inline-block;
    background: #2b2b2b;
    color: #fff;
    padding: 7px;
    border-radius: 8px;
    margin-bottom: 3px; }
  .page-conversation-wall .follower-tag:hover,
  .page-followed-items .follower-tag:hover {
    cursor: pointer;
    color: #fff; }
  .page-conversation-wall .follower-img,
  .page-followed-items .follower-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px; }
  .page-conversation-wall .follower-handle,
  .page-followed-items .follower-handle {
    display: inline-block;
    font-size: .8rem;
    margin: 0 0 0 0; }
  .page-conversation-wall .corner-ribbon,
  .page-followed-items .corner-ribbon {
    width: 200px;
    background: #39d;
    position: absolute;
    top: 25px;
    left: -50px;
    text-align: center;
    line-height: 20px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    font-size: 12px; }
  .page-conversation-wall .corner-ribbon.shadow,
  .page-followed-items .corner-ribbon.shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); }
  .page-conversation-wall .corner-ribbon.top-right,
  .page-followed-items .corner-ribbon.top-right {
    top: 25px;
    right: -65px;
    left: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }

.page-sessison-virtual {
  /* trigger */
  /* fix for overlapping captions menu  */
  /* mute */
  /* plus */
  /* icons */
  /* [data-type="tada"] .customer-sentiment-trigger-icon,
	[data-type="tada"] .customer-sentiment-avatar-icon {
		background-image: url(../../images/sentiment/reaction-tada.png);
	} */ }
  .page-sessison-virtual .customer-sentiment-wrapper {
    position: relative; }
  .page-sessison-virtual .customer-sentiment-container {
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999; }
  .page-sessison-virtual .customer-sentiment-wrapper[data-enabled="true"] .customer-sentiment-container {
    opacity: 1; }
  .page-sessison-virtual .customer-sentiment-wrapper[data-on="false"] .customer-sentiment-container {
    opacity: 0 !important; }
  .page-sessison-virtual .customer-sentiment-triggers {
    bottom: 2rem;
    display: flex;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 1rem; }
  .page-sessison-virtual .customer-sentiment-wrapper[data-enabled="true"] .customer-sentiment-triggers {
    opacity: 1;
    pointer-events: auto; }
  .page-sessison-virtual .customer-sentiment-wrapper[data-on="false"] .customer-sentiment-triggers {
    opacity: 0 !important;
    pointer-events: none !important; }
  .page-sessison-virtual .customer-sentiment-trigger {
    margin: 0 0 0 12px;
    user-select: none; }
  .page-sessison-virtual .disabled .customer-sentiment-trigger,
  .page-sessison-virtual .customer-sentiment-trigger.disabled {
    opacity: 0.500;
    pointer-events: none; }
  .page-sessison-virtual .customer-sentiment-trigger-icon {
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    border-radius: 50%;
    border: solid 1px rgba(255, 255, 255, 0.5);
    color: white;
    cursor: pointer;
    display: flex;
    filter: grayscale(1) brightness(0.75);
    height: 40px;
    justify-content: center;
    text-align: center;
    transition: 0.2s;
    width: 40px; }
  .page-sessison-virtual .customer-sentiment-trigger-icon:hover {
    border-color: #fbaa19;
    filter: grayscale(0) brightness(1); }
  .page-sessison-virtual .customer-sentiment-trigger.disabled .customer-sentiment-trigger-icon {
    color: #fbaa19;
    opacity: 0.500;
    pointer-events: none;
    transform: scale(0.9); }
  .page-sessison-virtual .vjs-menu-content {
    z-index: 200; }
  .page-sessison-virtual .customer-sentiment-mute i {
    color: white;
    cursor: pointer;
    height: 40px;
    opacity: 0.500;
    line-height: 40px;
    text-align: center;
    transition: 0.2s;
    width: 40px; }
  .page-sessison-virtual .customer-sentiment-mute i:hover {
    opacity: 1; }
  .page-sessison-virtual .customer-sentiment-mute .fa-eye {
    display: block; }
  .page-sessison-virtual .disabled .customer-sentiment-mute .fa-eye {
    display: none; }
  .page-sessison-virtual .customer-sentiment-mute .fa-eye-slash {
    display: none; }
  .page-sessison-virtual .disabled .customer-sentiment-mute .fa-eye-slash {
    display: block; }
  .page-sessison-virtual .customer-sentiment-avatar {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute; }
  .page-sessison-virtual .customer-sentiment-avatar img {
    border-radius: 100%;
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    width: 100%; }
  .page-sessison-virtual .customer-sentiment-avatar-icon {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 90%;
    height: 100%;
    width: 100%; }
  .page-sessison-virtual [data-type="like"] .customer-sentiment-trigger-icon,
  .page-sessison-virtual [data-type="like"] .customer-sentiment-avatar-icon {
    background-image: url(../../images/sentiment/reaction-like.png);
    background-size: 50% 50%; }
  .page-sessison-virtual [data-type="applause"] .customer-sentiment-trigger-icon,
  .page-sessison-virtual [data-type="applause"] .customer-sentiment-avatar-icon {
    background-image: url(../../images/sentiment/reaction-applause.png);
    background-size: 50% 50%; }
  .page-sessison-virtual [data-type="heart"] .customer-sentiment-trigger-icon,
  .page-sessison-virtual [data-type="heart"] .customer-sentiment-avatar-icon {
    background-image: url(../../images/sentiment/reaction-heart.png);
    background-size: 50% 50%; }

.cropit-preview {
  display: block;
  background-color: #f8f8f8;
  border: 5px solid #000;
  border-radius: 3px;
  margin: 0 auto 30px;
  min-width: 200px;
  width: 200px;
  height: 200px; }

.cropit-preview-image-container {
  cursor: move; }

.cropit-preview-background {
  opacity: .2;
  cursor: auto; }

.mobile-user-note {
  padding-right: 40px; }

.cropit-preview-image-container {
  cursor: move; }

.cropit-preview-background {
  opacity: .2;
  cursor: auto; }

.image-size-label {
  margin-top: 10px; }

#edit-profile img {
  max-width: none; }

#photo {
  margin-bottom: 30px; }

.file-choose-btn {
  display: block;
  text-align: center;
  margin: 0 auto 30px; }
  .file-choose-btn:hover {
    cursor: pointer; }

.photo-hide {
  display: none; }

.toggle {
  border-radius: 0px; }
  .toggle .toggle-handle {
    border-radius: 0px; }

@use '../partials/mixins' as *;
/* ==========================================================================
   In App Maping Functionlity
   ========================================================================== */
.page-floor-plans,
.page-floor-plan,
.page-location {
  /* ==========================================================================
	   Location Map Marker
	   ========================================================================== */
  /* Map
	   ========================================================================== */
  /* Markers
	   ========================================================================== */
  /* Bottom Overlay
	   ========================================================================== */
  /*** Opened ***/
  /* Location Toggles
	   ========================================================================== */
  /* Open */
  /* ==========================================================================
	   Form Control
	   ========================================================================== */
  /*specific to login page*/
  /*has text class is set in js */ }
  .page-floor-plans .page-contents,
  .page-floor-plan .page-contents,
  .page-location .page-contents {
    position: relative; }
    @media only screen and (max-width: 767.98px) {
      .page-floor-plans .page-contents,
      .page-floor-plan .page-contents,
      .page-location .page-contents {
        min-height: 79vh !important; } }
  .page-floor-plans #global-container,
  .page-floor-plan #global-container,
  .page-location #global-container {
    overflow: hidden; }
  .page-floor-plans .map-wrapper,
  .page-floor-plan .map-wrapper,
  .page-location .map-wrapper {
    height: 350px;
    width: 100%;
    position: relative; }
  .page-floor-plans .map-wrapper > #map-holder,
  .page-floor-plan .map-wrapper > #map-holder,
  .page-location .map-wrapper > #map-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%; }
  .page-floor-plans .location-types,
  .page-floor-plan .location-types,
  .page-location .location-types {
    background: #eee;
    box-shadow: inset 0 0 5px #ddd;
    padding: 10px 15px;
    margin: 0;
    overflow: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc; }
  .page-floor-plans .location-types > a,
  .page-floor-plan .location-types > a,
  .page-location .location-types > a {
    font: 700 10px/24px sans-serif;
    display: inline-block;
    padding: 0 15px;
    border-radius: 6px;
    margin: 0 10px 0 0;
    background: #fff;
    text-decoration: none; }
  .page-floor-plans .location-types > a.isActive,
  .page-floor-plan .location-types > a.isActive,
  .page-location .location-types > a.isActive {
    background: #000 !important;
    color: #fff;
    opacity: 1; }
  .page-floor-plans .pickup-select-wrapper,
  .page-floor-plan .pickup-select-wrapper,
  .page-location .pickup-select-wrapper {
    padding: 15px; }
  .page-floor-plans #map-notification .alert,
  .page-floor-plan #map-notification .alert,
  .page-location #map-notification .alert {
    margin-bottom: 0; }
  .page-floor-plans .floorplan-wrapper,
  .page-floor-plan .floorplan-wrapper,
  .page-location .floorplan-wrapper {
    max-height: 100%;
    min-height: 100%; }
  .page-floor-plans .floorplan-wrapper img,
  .page-floor-plan .floorplan-wrapper img,
  .page-location .floorplan-wrapper img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto; }
  .page-floor-plans .marker,
  .page-floor-plan .marker,
  .page-location .marker {
    position: absolute;
    width: 30px;
    height: 30px;
    margin: -26px 0 0 -15px; }
  .page-floor-plans .marker__icon,
  .page-floor-plan .marker__icon,
  .page-location .marker__icon {
    width: 30px;
    height: 30px;
    font-size: 30px;
    text-align: center;
    color: #004C7D; }
  .page-floor-plans .marker__content,
  .page-floor-plan .marker__content,
  .page-location .marker__content {
    display: none;
    position: absolute;
    top: -27px;
    left: 50%;
    width: 88px;
    margin: 0 0 0 -50px;
    text-align: center;
    background: rgba(47, 130, 171, 0.85);
    border: 1px solid #2f82ab;
    padding: 5px;
    border-radius: 4px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: .8em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #fff; }
  .page-floor-plans .floorplan-zoom,
  .page-floor-plan .floorplan-zoom,
  .page-location .floorplan-zoom {
    position: absolute;
    top: 175px;
    right: 25px;
    width: 40px;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #000;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(0, 0, 0, 0.6); }
  .page-floor-plans .floorplan-zoom-out,
  .page-floor-plan .floorplan-zoom-out,
  .page-location .floorplan-zoom-out {
    position: absolute;
    top: 175px;
    right: 70px;
    width: 40px;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #000;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(0, 0, 0, 0.6);
    display: none; }
  .page-floor-plans .bottom-overlay,
  .page-floor-plan .bottom-overlay,
  .page-location .bottom-overlay {
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    z-index: 100;
    min-height: 55px;
    height: 55px;
    overflow: hidden;
    background: #fff;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    transition-duration: .2s; }
  .page-floor-plans .bottom-overlay__header,
  .page-floor-plan .bottom-overlay__header,
  .page-location .bottom-overlay__header {
    cursor: pointer; }
  .page-floor-plans .bottom-overlay__header.text-smaller,
  .page-floor-plan .bottom-overlay__header.text-smaller,
  .page-location .bottom-overlay__header.text-smaller {
    font-size: .8em; }
  .page-floor-plans .bottom-overlay__header .drag-bar,
  .page-floor-plan .bottom-overlay__header .drag-bar,
  .page-location .bottom-overlay__header .drag-bar {
    width: 40px;
    height: 4px;
    margin: 8px auto;
    border-radius: 4px;
    background: #666;
    opacity: .5;
    display: block; }
  .page-floor-plans .bottom-overlay.open,
  .page-floor-plan .bottom-overlay.open,
  .page-location .bottom-overlay.open {
    height: 45%; }
  .page-floor-plans .bottom-overlay .list-group,
  .page-floor-plan .bottom-overlay .list-group,
  .page-location .bottom-overlay .list-group {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 50px;
    bottom: 20px;
    margin: 0;
    overflow: hidden;
    overflow-y: auto; }
  .page-floor-plans .bottom-overlay .list-group-item,
  .page-floor-plan .bottom-overlay .list-group-item,
  .page-location .bottom-overlay .list-group-item {
    border-left: 0;
    border-right: 0; }
  .page-floor-plans .list-divider-day,
  .page-floor-plan .list-divider-day,
  .page-location .list-divider-day {
    position: relative;
    top: auto;
    right: auto;
    left: auto; }
  .page-floor-plans .location-toggle,
  .page-floor-plan .location-toggle,
  .page-location .location-toggle {
    position: relative; }
  .page-floor-plans .location-toggle:before,
  .page-floor-plans .location-toggle:after,
  .page-floor-plan .location-toggle:before,
  .page-floor-plan .location-toggle:after,
  .page-location .location-toggle:before,
  .page-location .location-toggle:after {
    content: '';
    display: table; }
  .page-floor-plans .location-toggle:after,
  .page-floor-plan .location-toggle:after,
  .page-location .location-toggle:after {
    clear: both; }
  .page-floor-plans .location-toggle .list-group-item-heading,
  .page-floor-plan .location-toggle .list-group-item-heading,
  .page-location .location-toggle .list-group-item-heading {
    margin: 0;
    padding: 20px 15px;
    color: #55565a;
    padding-right: 100px;
    font: 700 1.25em/1em 'Lato',sans-serif; }
  .page-floor-plans .location-toggle.open,
  .page-floor-plan .location-toggle.open,
  .page-location .location-toggle.open {
    background: #004C7D;
    color: #fff; }
  .page-floor-plans .location-toggle.open .list-group-item-heading,
  .page-floor-plan .location-toggle.open .list-group-item-heading,
  .page-location .location-toggle.open .list-group-item-heading {
    color: #fff; }
  .page-floor-plans .input-container,
  .page-floor-plan .input-container,
  .page-location .input-container {
    position: relative; }
  .page-floor-plans .form-control,
  .page-floor-plan .form-control,
  .page-location .form-control {
    border-bottom: 1px solid #ccc;
    background: #fff; }
  .page-floor-plans .float-bar,
  .page-floor-plan .float-bar,
  .page-location .float-bar {
    position: relative;
    display: block; }
  .page-floor-plans .float-bar:before,
  .page-floor-plan .float-bar:before,
  .page-location .float-bar:before {
    content: ' ';
    height: 3px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #00bbe6;
    transition: 0.2s ease all;
    left: 0; }
  .page-floor-plans .form-control:focus,
  .page-floor-plan .form-control:focus,
  .page-location .form-control:focus {
    border-color: transparent; }
  .page-floor-plans .form-control:focus ~ .float-bar:before,
  .page-floor-plan .form-control:focus ~ .float-bar:before,
  .page-location .form-control:focus ~ .float-bar:before {
    width: 100%; }
  .page-floor-plans .floating-label,
  .page-floor-plan .floating-label,
  .page-location .floating-label {
    position: absolute;
    top: 18px;
    left: 15px;
    font-size: 14px;
    line-height: 14px;
    font-weight: normal;
    transition: 0.2s ease all; }
  .page-floor-plans .form-control:focus ~ .floating-label,
  .page-floor-plan .form-control:focus ~ .floating-label,
  .page-location .form-control:focus ~ .floating-label {
    top: -10px;
    font-size: 12px; }
  .page-floor-plans .form-group,
  .page-floor-plan .form-group,
  .page-location .form-group {
    margin-top: 10px; }
  .page-floor-plans .form-control.has-text ~ .floating-label,
  .page-floor-plan .form-control.has-text ~ .floating-label,
  .page-location .form-control.has-text ~ .floating-label {
    top: -10px !important; }
  .page-floor-plans .page-header .page-back-btn,
  .page-floor-plan .page-header .page-back-btn,
  .page-location .page-header .page-back-btn {
    font-size: 1.4em;
    line-height: 60px;
    position: absolute;
    top: -3px;
    left: 0;
    width: 200px;
    height: 60px;
    padding-left: 13px;
    color: #fff;
    background: 0 0; }
  @media (max-width: 1024px) {
    .page-floor-plans .bottom-overlay,
    .page-floor-plan .bottom-overlay,
    .page-location .bottom-overlay {
      left: 0; } }
  @media (max-width: 768px) {
    .page-floor-plans .page-header .col-1 a,
    .page-floor-plan .page-header .col-1 a,
    .page-location .page-header .col-1 a {
      margin-top: 0; } }

.page-home-full {
  /* begin smaller navigation tiles (pulse) for virtual */ }
  .page-home-full .page-contents {
    background: transparent; }
  .page-home-full #hf-nav-container {
    margin-top: 95px; }
  .page-home-full #hf-session-container {
    overflow: auto; }
  .page-home-full body #hf-nav-container .tile {
    padding: 20px 20px 20px 20px !important;
    width: 164px;
    height: 140px;
    border-radius: 6px; }
  .page-home-full #events-scroll {
    height: calc(100vh - 110px);
    overflow-y: auto; }
    .page-home-full #events-scroll .sidebar-logo {
      max-height: 80px;
      filter: grayscale(1) invert(1); }
  .page-home-full body #hf-nav-container .tile-icon {
    /* To center icon, subtract half width from top & left */
    margin: -25px 0 0 -25px;
    /* background is set in _tiles.cfm */
    /* background: rgba(0,0,0,.2); */
    border-radius: 50%;
    -webkit-border-radius: 50%;
    font-size: 22px;
    width: 50px;
    height: 50px;
    line-height: 50px; }
  .page-home-full body #hf-nav-container .tile-icon.tile-hPosition-left {
    left: 0%;
    margin-left: 15px; }
  .page-home-full body #hf-nav-container .tile-icon.tile-hPosition-right {
    left: auto;
    right: 0%;
    margin-left: 0;
    margin-right: 15px; }
  .page-home-full body #hf-nav-container .tile-icon.tile-vPosition-top {
    top: 0;
    margin-top: 15px; }
  .page-home-full body #hf-nav-container .tile-icon.tile-vPosition-bottom {
    top: auto;
    bottom: 0;
    margin-top: 0;
    margin-bottom: 15px; }
  .page-home-full body #hf-nav-container .tile-footer {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    bottom: 20px; }

.page-login {
  /* Login Page $login =================================================================== */ }
  .page-login #sidebar-left {
    display: none; }
  .page-login #global-container {
    padding-left: 0; }
  .page-login .gdpr-scroll {
    max-height: 200px;
    overflow-y: auto;
    padding-right: 20px; }
  .page-login .btn-light {
    border-color: #005eb8;
    background: #fff;
    color: #005eb8; }
    .page-login .btn-light:hover {
      border-color: #fff;
      background: #005eb8;
      color: #fff; }
  .page-login #gdpr-scroll {
    height: 140px;
    overflow-y: auto;
    font-size: 12px;
    line-height: 16px; }
  .page-login #page-login .page-contents {
    min-height: 100vh;
    padding: 0 0 0 0; }
  .page-login #page-login h1, .page-login #page-login .h1 {
    font-size: 2rem; }
  .page-login #page-login .left-side-login {
    max-height: 100vh;
    overflow: auto; }
  .page-login #page-login .page-contents {
    background: transparent !important; }
  .page-login #page-login .center-form {
    border-radius: 5px;
    min-height: 697px;
    box-shadow: #000 0px 5px 15px; }
  .page-login #page-login .login-form {
    width: 520px;
    max-width: 520px;
    background: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }
  .page-login #page-login .teaser-info {
    transition: max-width 0.3s linear 0s, min-width 0.3s linear 0.25s, padding 0.3s linear 0.25s, width 0.3s linear 0.25s;
    width: 340px;
    max-width: 340px;
    padding: 2rem;
    background-color: #1f262d;
    color: #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: url("/includes/images/default/bg-login-left.png"); }
  .page-login #page-login .teaser-header {
    display: inline;
    font-size: 46px !important;
    font-weight: 600;
    line-height: 1.25;
    margin: 2px 0; }
  .page-login #page-login .teaser-header-container {
    padding-bottom: 0;
    padding-top: 15px;
    width: 100%; }
  .page-login #page-login .teaser-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 2px 0;
    padding-top: 32px; }
  .page-login #page-login .delay-display {
    visibility: visible;
    transition-delay: 750ms; }
  @media only screen and (max-width: 991px) {
    .page-login #page-login .page-contents {
      min-height: 100vh !important; } }
  @media only screen and (max-width: 883px) {
    .page-login #page-login .teaser-info {
      width: 0px;
      max-width: 0px;
      padding: 0px;
      transition: max-width 0.3s linear 0s, min-width 0.3s linear 0.25s, padding 0.3s linear 0.25s, width 0.3s linear 0.25s; }
    .page-login #page-login .delay-display {
      visibility: hidden;
      transition-delay: 1ms; }
    .page-login #page-login .login-form {
      width: 100%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; } }
  @media only screen and (max-width: 576px) {
    .page-login #page-login .login-form {
      height: 100vh;
      width: 100%;
      margin: 0rem; } }
  .page-login #ie-message {
    display: none;
    padding: 40px; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .page-login #page-login {
      display: none; }
    .page-login #ie-message {
      display: block; } }
  .page-login #mobile-message {
    display: none; }
  @media (max-width: 769px) {
    .page-login #mobile-message {
      display: block; } }

.video-js.popped-out:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  z-index: 2; }

.video-js.popped-out:after {
  content: '\f35d';
  font-family: 'Font Awesome 5 Pro';
  color: #666;
  text-shadow: 1px 1px #ccc;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -.5em;
  text-align: center;
  font-size: 8em;
  z-index: 3;
  transform: rotate(90deg); }

.persistent-video {
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: 400px;
  height: 225px;
  transform: translateY(300px);
  transition: transform .2s ease-in-out;
  z-index: 10000;
  box-shadow: -2px -2px 20px rgba(0, 0, 0, 0.15);
  border: 1px solid #fff;
  overflow: hidden;
  border-radius: 6; }

.persistent-video.visible {
  transform: translateY(0);
  transition: transform .2s ease-in-out; }

.persistent-video.focus {
  animation: pulsing 0.5s infinite;
  -webkit-animation: pulsing 0.5s infinite; }

@keyframes pulsing {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 51, 87, 0.5);
    transform: scale(1.1); }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    transform: scale(1); } }

@-webkit-keyframes pulsing {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 51, 87, 0.5);
    transform: scale(1.1); }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    transform: scale(1); } }

.persistent-video-inner {
  position: 'absolute';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: '#000'; }

.persistent-video-trigger {
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: white;
  background: none;
  border: none;
  text-shadow: rgba(0, 0, 0, 0.1) 1px 1px;
  padding: 0.7em 1em 0.8em;
  font-size: 0.75em;
  line-height: 1em;
  opacity: 1;
  display: none;
  visibility: none; }

[data-allow-persistent="true"] .vjs-control-bar {
  padding-right: 30px; }

.vjs-has-started + .persistent-video-trigger {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: visibility 1s, opacity 1s; }

.vjs-has-started.vjs-user-inactive.vjs-playing + .persistent-video-trigger {
  transition: visibility 1s, opacity 1s;
  opacity: 0; }

.video-js .vjs-big-play-button {
  font-size: 3em;
  line-height: 60px;
  height: 60px;
  width: 60px;
  margin-left: -30px;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0;
  margin-top: -30px;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #2B333F;
  background-color: rgba(43, 51, 63, 0.3);
  border-radius: 0.3em;
  transition: all 0.4s;
  border-radius: 50%; }

@media only screen and (max-width: 1024px) {
  .persistent-video-trigger {
    display: none !important; } }

.page-photobooth {
  position: relative; }
  .page-photobooth #webcam-wrapper, .page-photobooth #approval-wrapper, .page-photobooth #final-image-container {
    width: 400px;
    height: 400px;
    margin: 0 auto;
    position: relative; }
  .page-photobooth #final-image-container {
    display: none;
    margin-top: 60px; }
  .page-photobooth #approval-wrapper {
    display: none; }
  .page-photobooth #webcam {
    object-fit: cover;
    object-position: center; }
  .page-photobooth #stickers-gallery {
    height: 400px;
    overflow-y: scroll; }
  .page-photobooth #backgrounds-gallery img,
  .page-photobooth #stickers-gallery img,
  .page-photobooth #frames-gallery img {
    display: block;
    width: 100px;
    height: 100px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 2px;
    display: inline-block;
    background: #d9d9d9; }
  .page-photobooth #backgrounds-gallery img:hover,
  .page-photobooth #stickers-gallery img:hover,
  .page-photobooth #frames-gallery img:hover {
    cursor: pointer; }
  .page-photobooth #photo-container {
    width: 400px;
    height: 400px;
    position: relative;
    background: white;
    margin: 0 auto; }
  .page-photobooth #photo-preview {
    display: block;
    width: 400px;
    height: 400px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
  .page-photobooth .frame {
    display: block;
    width: 400px;
    height: 400px;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0; }
  .page-photobooth .sticker {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 400px;
    z-index: 10; }
  .page-photobooth #stickers-gallery img {
    border: 1px solid #ccc;
    opacity: .5; }
  .page-photobooth #stickers-gallery img.active {
    opacity: 1; }
  .page-photobooth .not-visible {
    display: none; }
  .page-photobooth #js-retake-photo, .page-photobooth #js-submit-photo, .page-photobooth #js-take-photo {
    position: absolute;
    display: block;
    z-index: 999;
    width: 60px;
    height: 60px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    line-height: 55px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 22px; }
  .page-photobooth #js-take-photo {
    bottom: 20px;
    left: 50%;
    margin-left: -30px; }
  .page-photobooth #js-retake-photo {
    left: 20px;
    bottom: 20px; }
  .page-photobooth #js-submit-photo {
    right: 20px;
    bottom: 20px; }
  .page-photobooth #js-take-photo:hover,
  .page-photobooth #js-retake-photo:hover,
  .page-photobooth #js-submit-photo:hover {
    background: black; }
  .page-photobooth #counter {
    position: absolute;
    width: 100px;
    height: 100px;
    font-size: 100px;
    line-height: 100px;
    z-index: 999;
    color: #fff;
    font-weight: bold;
    text-align: center;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    text-shadow: 0 0 20px #000000;
    display: none; }
  .page-photobooth .moveable-control-box {
    display: none; }
  .page-photobooth #page-photobooth h2, .page-photobooth #page-photobooth .h2 {
    font-weight: bold; }
  .page-photobooth #page-photobooth .btn {
    font-weight: bold;
    font-size: 16px; }
  .page-photobooth #page-photobooth .nav-tabs .nav-link {
    font-size: 18px; }
  .page-photobooth #page-photobooth .nav-tabs .nav-item.show .nav-link,
  .page-photobooth #page-photobooth .nav-tabs .nav-link.active {
    background: transparent; }
  .page-photobooth .select {
    max-width: 400px;
    margin: 0 auto; }

/*======================================
=            Locked Overlay            =
======================================*/
.page-poll #locked-overlay {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff; }

.page-poll #locked-overlay-content h3, .page-poll #locked-overlay-content .h3 {
  font-weight: bold;
  font-size: 2.2rem;
  text-transform: uppercase; }

.page-poll #locked-overlay-content p {
  font-size: 1.6rem; }

.page-poll .form-check-label:hover {
  cursor: pointer; }

/**
 * Description: CSS Specific to twilio collaborative sessions
 * Author: Ryan Taggart <ryan.t@meetingplay.com>
**/
.page-admin-panel,
.page-sessison-virtual,
.page-session-zoom,
.page-video-testing,
.page-virtual-exhibitor {
  /* ==========================================================================
		General Styles
	========================================================================== */
  /* ==========================================================================
		Live Feed Containers
	========================================================================== */
  /* Wrappers
	========================================================================== */
  /* Titles
	========================================================================== */
  /* Toggles
	========================================================================== */
  /* Minimized
	========================================================================== */
  /*** Minimized version ***/
  /*** Minimized users feed ***/
  /*** Minimized Presenter Feed ***/
  /*** Screenshare minimized ***/
  /* Toggles
	========================================================================== */
  /* Main Feed video blocks
	========================================================================== */
  /* ==========================================================================
		Presenter Block
	========================================================================== */
  /* ==========================================================================
		Screenshare Block
	========================================================================== */
  /* ==========================================================================
		Agora type video blocks
	========================================================================== */
  /* Audience Management
	========================================================================== */
  /* Live List
	========================================================================== */ }
  .page-admin-panel .live-feed-wrapper,
  .page-sessison-virtual .live-feed-wrapper,
  .page-session-zoom .live-feed-wrapper,
  .page-video-testing .live-feed-wrapper,
  .page-virtual-exhibitor .live-feed-wrapper {
    position: relative; }
  .page-admin-panel .live-feed-wrapper + .live-feed-wrapper,
  .page-sessison-virtual .live-feed-wrapper + .live-feed-wrapper,
  .page-session-zoom .live-feed-wrapper + .live-feed-wrapper,
  .page-video-testing .live-feed-wrapper + .live-feed-wrapper,
  .page-virtual-exhibitor .live-feed-wrapper + .live-feed-wrapper {
    margin-top: 10px; }
  .page-admin-panel .live-feed-title,
  .page-sessison-virtual .live-feed-title,
  .page-session-zoom .live-feed-title,
  .page-video-testing .live-feed-title,
  .page-virtual-exhibitor .live-feed-title {
    padding: 10px;
    position: relative; }
  .page-admin-panel .live-feed-title .title-intro,
  .page-sessison-virtual .live-feed-title .title-intro,
  .page-session-zoom .live-feed-title .title-intro,
  .page-video-testing .live-feed-title .title-intro,
  .page-virtual-exhibitor .live-feed-title .title-intro {
    font-size: 1.1em;
    margin: 0;
    line-height: 35px; }
  .page-admin-panel .live-feed-title .title-intro .fal,
  .page-sessison-virtual .live-feed-title .title-intro .fal,
  .page-session-zoom .live-feed-title .title-intro .fal,
  .page-video-testing .live-feed-title .title-intro .fal,
  .page-virtual-exhibitor .live-feed-title .title-intro .fal {
    margin-right: 5px;
    font-size: 20px;
    position: relative;
    top: 3px; }
  .page-admin-panel .live-feed-title .title,
  .page-sessison-virtual .live-feed-title .title,
  .page-session-zoom .live-feed-title .title,
  .page-video-testing .live-feed-title .title,
  .page-virtual-exhibitor .live-feed-title .title {
    margin: 0;
    font-weight: 700;
    font-size: 1.25em;
    margin: 0 0 8px; }
  .page-admin-panel .liveFeed-noData,
  .page-sessison-virtual .liveFeed-noData,
  .page-session-zoom .liveFeed-noData,
  .page-video-testing .liveFeed-noData,
  .page-virtual-exhibitor .liveFeed-noData {
    width: 100%;
    height: auto;
    background: #ccc;
    background: linear-gradient(#f3f3f3, #d4d4d4);
    z-index: 101;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: 50% 50%;
    padding-top: 35%; }
  .page-admin-panel .toggle-fullscreen,
  .page-sessison-virtual .toggle-fullscreen,
  .page-session-zoom .toggle-fullscreen,
  .page-video-testing .toggle-fullscreen,
  .page-virtual-exhibitor .toggle-fullscreen {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px;
    z-index: 15;
    color: #000;
    z-index: 100; }
  .page-admin-panel .toggle-feed-size,
  .page-sessison-virtual .toggle-feed-size,
  .page-session-zoom .toggle-feed-size,
  .page-video-testing .toggle-feed-size,
  .page-virtual-exhibitor .toggle-feed-size {
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: auto;
    z-index: 100; }
  .page-admin-panel .minimized .live-feed-title,
  .page-sessison-virtual .minimized .live-feed-title,
  .page-session-zoom .minimized .live-feed-title,
  .page-video-testing .minimized .live-feed-title,
  .page-virtual-exhibitor .minimized .live-feed-title {
    padding-left: 130px;
    font-size: .8em;
    height: 115px; }
  .page-admin-panel .minimized .live-feed-title h3, .page-admin-panel .minimized .live-feed-title .h3,
  .page-admin-panel .minimized .live-feed-title h4,
  .page-admin-panel .minimized .live-feed-title .h4,
  .page-sessison-virtual .minimized .live-feed-title h3,
  .page-sessison-virtual .minimized .live-feed-title .h3,
  .page-sessison-virtual .minimized .live-feed-title h4,
  .page-sessison-virtual .minimized .live-feed-title .h4,
  .page-session-zoom .minimized .live-feed-title h3,
  .page-session-zoom .minimized .live-feed-title .h3,
  .page-session-zoom .minimized .live-feed-title h4,
  .page-session-zoom .minimized .live-feed-title .h4,
  .page-video-testing .minimized .live-feed-title h3,
  .page-video-testing .minimized .live-feed-title .h3,
  .page-video-testing .minimized .live-feed-title h4,
  .page-video-testing .minimized .live-feed-title .h4,
  .page-virtual-exhibitor .minimized .live-feed-title h3,
  .page-virtual-exhibitor .minimized .live-feed-title .h3,
  .page-virtual-exhibitor .minimized .live-feed-title h4,
  .page-virtual-exhibitor .minimized .live-feed-title .h4 {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none; }
  .page-admin-panel #sidebar-panel .minimized[data-feed="presenter"] a[rel="#action-tab-collaboration"],
  .page-admin-panel #sidebar-panel .minimized[data-feed="presenter"] .toggle-feed-size,
  .page-sessison-virtual #sidebar-panel .minimized[data-feed="presenter"] a[rel="#action-tab-collaboration"],
  .page-sessison-virtual #sidebar-panel .minimized[data-feed="presenter"] .toggle-feed-size,
  .page-session-zoom #sidebar-panel .minimized[data-feed="presenter"] a[rel="#action-tab-collaboration"],
  .page-session-zoom #sidebar-panel .minimized[data-feed="presenter"] .toggle-feed-size,
  .page-video-testing #sidebar-panel .minimized[data-feed="presenter"] a[rel="#action-tab-collaboration"],
  .page-video-testing #sidebar-panel .minimized[data-feed="presenter"] .toggle-feed-size,
  .page-virtual-exhibitor #sidebar-panel .minimized[data-feed="presenter"] a[rel="#action-tab-collaboration"],
  .page-virtual-exhibitor #sidebar-panel .minimized[data-feed="presenter"] .toggle-feed-size {
    top: unset;
    bottom: 5px;
    right: 10px;
    z-index: 1; }
  .page-admin-panel #sidebar-panel .minimized[data-feed="presenter"] a[rel="#action-tab-collaboration"],
  .page-sessison-virtual #sidebar-panel .minimized[data-feed="presenter"] a[rel="#action-tab-collaboration"],
  .page-session-zoom #sidebar-panel .minimized[data-feed="presenter"] a[rel="#action-tab-collaboration"],
  .page-video-testing #sidebar-panel .minimized[data-feed="presenter"] a[rel="#action-tab-collaboration"],
  .page-virtual-exhibitor #sidebar-panel .minimized[data-feed="presenter"] a[rel="#action-tab-collaboration"] {
    right: 60px; }
  .page-admin-panel #sidebar-panel .minimized[data-feed="presenter"] .video-block video,
  .page-sessison-virtual #sidebar-panel .minimized[data-feed="presenter"] .video-block video,
  .page-session-zoom #sidebar-panel .minimized[data-feed="presenter"] .video-block video,
  .page-video-testing #sidebar-panel .minimized[data-feed="presenter"] .video-block video,
  .page-virtual-exhibitor #sidebar-panel .minimized[data-feed="presenter"] .video-block video {
    height: 115px;
    object-fit: cover; }
  .page-admin-panel .minimized .liveFeed-noData,
  .page-sessison-virtual .minimized .liveFeed-noData,
  .page-session-zoom .minimized .liveFeed-noData,
  .page-video-testing .minimized .liveFeed-noData,
  .page-virtual-exhibitor .minimized .liveFeed-noData {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 113px;
    height: 115px;
    padding: 0; }
  .page-admin-panel .minimized .live-feed,
  .page-sessison-virtual .minimized .live-feed,
  .page-session-zoom .minimized .live-feed,
  .page-video-testing .minimized .live-feed,
  .page-virtual-exhibitor .minimized .live-feed {
    position: absolute;
    top: 0;
    left: 0;
    width: 113px;
    height: auto;
    z-index: 1; }
  .page-admin-panel [data-feed="users"] .live-feed-title,
  .page-sessison-virtual [data-feed="users"] .live-feed-title,
  .page-session-zoom [data-feed="users"] .live-feed-title,
  .page-video-testing [data-feed="users"] .live-feed-title,
  .page-virtual-exhibitor [data-feed="users"] .live-feed-title {
    display: none; }
  .page-admin-panel .minimized[data-feed="users"] .live-feed-title,
  .page-sessison-virtual .minimized[data-feed="users"] .live-feed-title,
  .page-session-zoom .minimized[data-feed="users"] .live-feed-title,
  .page-video-testing .minimized[data-feed="users"] .live-feed-title,
  .page-virtual-exhibitor .minimized[data-feed="users"] .live-feed-title {
    display: block;
    height: 113px;
    padding-left: 170px; }
  .page-admin-panel .minimized[data-feed="users"] .video-block-overlay,
  .page-sessison-virtual .minimized[data-feed="users"] .video-block-overlay,
  .page-session-zoom .minimized[data-feed="users"] .video-block-overlay,
  .page-video-testing .minimized[data-feed="users"] .video-block-overlay,
  .page-virtual-exhibitor .minimized[data-feed="users"] .video-block-overlay {
    display: none; }
  .page-admin-panel .minimized[data-feed="users"] .live-feed,
  .page-admin-panel .minimized[data-feed="users"] .liveFeed-noData,
  .page-sessison-virtual .minimized[data-feed="users"] .live-feed,
  .page-sessison-virtual .minimized[data-feed="users"] .liveFeed-noData,
  .page-session-zoom .minimized[data-feed="users"] .live-feed,
  .page-session-zoom .minimized[data-feed="users"] .liveFeed-noData,
  .page-video-testing .minimized[data-feed="users"] .live-feed,
  .page-video-testing .minimized[data-feed="users"] .liveFeed-noData,
  .page-virtual-exhibitor .minimized[data-feed="users"] .live-feed,
  .page-virtual-exhibitor .minimized[data-feed="users"] .liveFeed-noData {
    width: 150px;
    height: 113px; }
  .page-admin-panel [data-feed="presenter"] [rel="#action-tab-collaboration"],
  .page-sessison-virtual [data-feed="presenter"] [rel="#action-tab-collaboration"],
  .page-session-zoom [data-feed="presenter"] [rel="#action-tab-collaboration"],
  .page-video-testing [data-feed="presenter"] [rel="#action-tab-collaboration"],
  .page-virtual-exhibitor [data-feed="presenter"] [rel="#action-tab-collaboration"] {
    position: absolute;
    bottom: 5px;
    right: 5px; }
  .page-admin-panel .minimized[data-feed="presenter"] [rel="#action-tab-collaboration"],
  .page-sessison-virtual .minimized[data-feed="presenter"] [rel="#action-tab-collaboration"],
  .page-session-zoom .minimized[data-feed="presenter"] [rel="#action-tab-collaboration"],
  .page-video-testing .minimized[data-feed="presenter"] [rel="#action-tab-collaboration"],
  .page-virtual-exhibitor .minimized[data-feed="presenter"] [rel="#action-tab-collaboration"] {
    bottom: auto;
    top: 10px;
    right: 50px;
    font-size: .95em;
    font-weight: 700; }
  .page-admin-panel .minimized[data-feed="presenter"] .live-feed,
  .page-admin-panel .minimized[data-feed="presenter"] .liveFeed-noData,
  .page-sessison-virtual .minimized[data-feed="presenter"] .live-feed,
  .page-sessison-virtual .minimized[data-feed="presenter"] .liveFeed-noData,
  .page-session-zoom .minimized[data-feed="presenter"] .live-feed,
  .page-session-zoom .minimized[data-feed="presenter"] .liveFeed-noData,
  .page-video-testing .minimized[data-feed="presenter"] .live-feed,
  .page-video-testing .minimized[data-feed="presenter"] .liveFeed-noData,
  .page-virtual-exhibitor .minimized[data-feed="presenter"] .live-feed,
  .page-virtual-exhibitor .minimized[data-feed="presenter"] .liveFeed-noData {
    top: 100%;
    max-height: 115px; }
  .page-admin-panel .minimized[data-feed="presenter"] .toggle-screenshare,
  .page-admin-panel .minimized[data-feed="presenter"] .toggle-fullscreen,
  .page-sessison-virtual .minimized[data-feed="presenter"] .toggle-screenshare,
  .page-sessison-virtual .minimized[data-feed="presenter"] .toggle-fullscreen,
  .page-session-zoom .minimized[data-feed="presenter"] .toggle-screenshare,
  .page-session-zoom .minimized[data-feed="presenter"] .toggle-fullscreen,
  .page-video-testing .minimized[data-feed="presenter"] .toggle-screenshare,
  .page-video-testing .minimized[data-feed="presenter"] .toggle-fullscreen,
  .page-virtual-exhibitor .minimized[data-feed="presenter"] .toggle-screenshare,
  .page-virtual-exhibitor .minimized[data-feed="presenter"] .toggle-fullscreen {
    display: none; }
  .page-admin-panel .minimized[data-feed="presenter"] h4, .page-admin-panel .minimized[data-feed="presenter"] .h4,
  .page-sessison-virtual .minimized[data-feed="presenter"] h4,
  .page-sessison-virtual .minimized[data-feed="presenter"] .h4,
  .page-session-zoom .minimized[data-feed="presenter"] h4,
  .page-session-zoom .minimized[data-feed="presenter"] .h4,
  .page-video-testing .minimized[data-feed="presenter"] h4,
  .page-video-testing .minimized[data-feed="presenter"] .h4,
  .page-virtual-exhibitor .minimized[data-feed="presenter"] h4,
  .page-virtual-exhibitor .minimized[data-feed="presenter"] .h4 {
    opacity: 0; }
  .page-admin-panel [data-feed="screenshare"].minimized .live-feed-container,
  .page-sessison-virtual [data-feed="screenshare"].minimized .live-feed-container,
  .page-session-zoom [data-feed="screenshare"].minimized .live-feed-container,
  .page-video-testing [data-feed="screenshare"].minimized .live-feed-container,
  .page-virtual-exhibitor [data-feed="screenshare"].minimized .live-feed-container {
    width: 113px;
    height: 115px;
    position: absolute;
    overflow: hidden; }
  .page-admin-panel .live-feed-container,
  .page-sessison-virtual .live-feed-container,
  .page-session-zoom .live-feed-container,
  .page-video-testing .live-feed-container,
  .page-virtual-exhibitor .live-feed-container {
    position: relative; }
  .page-admin-panel .live-feed-container .toggle-fullscreen,
  .page-admin-panel .live-feed-container .toggle-screenshare,
  .page-sessison-virtual .live-feed-container .toggle-fullscreen,
  .page-sessison-virtual .live-feed-container .toggle-screenshare,
  .page-session-zoom .live-feed-container .toggle-fullscreen,
  .page-session-zoom .live-feed-container .toggle-screenshare,
  .page-video-testing .live-feed-container .toggle-fullscreen,
  .page-video-testing .live-feed-container .toggle-screenshare,
  .page-virtual-exhibitor .live-feed-container .toggle-fullscreen,
  .page-virtual-exhibitor .live-feed-container .toggle-screenshare {
    position: absolute;
    bottom: 10px;
    right: 10px;
    left: auto;
    z-index: 100;
    /* will be behind any no data overlay */
    color: #fff;
    background: rgba(12, 38, 69, 0.75);
    line-height: 40px;
    width: 40px;
    display: block;
    text-align: center;
    border-radius: 4px; }
  .page-admin-panel .live-feed-container .toggle-screenshare,
  .page-sessison-virtual .live-feed-container .toggle-screenshare,
  .page-session-zoom .live-feed-container .toggle-screenshare,
  .page-video-testing .live-feed-container .toggle-screenshare,
  .page-virtual-exhibitor .live-feed-container .toggle-screenshare {
    z-index: 1001;
    /* Now needs to be in front of overlay */ }
  .page-admin-panel .live-feed-container .toggle-fullscreen .fa,
  .page-admin-panel .live-feed-container .toggle-screenshare .fa,
  .page-sessison-virtual .live-feed-container .toggle-fullscreen .fa,
  .page-sessison-virtual .live-feed-container .toggle-screenshare .fa,
  .page-session-zoom .live-feed-container .toggle-fullscreen .fa,
  .page-session-zoom .live-feed-container .toggle-screenshare .fa,
  .page-video-testing .live-feed-container .toggle-fullscreen .fa,
  .page-video-testing .live-feed-container .toggle-screenshare .fa,
  .page-virtual-exhibitor .live-feed-container .toggle-fullscreen .fa,
  .page-virtual-exhibitor .live-feed-container .toggle-screenshare .fa {
    position: relative;
    top: 2px;
    left: 1px;
    font-size: 1.25em; }
  .page-admin-panel .live-feed-container .toggle-screenshare,
  .page-sessison-virtual .live-feed-container .toggle-screenshare,
  .page-session-zoom .live-feed-container .toggle-screenshare,
  .page-video-testing .live-feed-container .toggle-screenshare,
  .page-virtual-exhibitor .live-feed-container .toggle-screenshare {
    position: absolute;
    left: 5px;
    right: auto;
    width: auto;
    padding: 0 10px;
    font-weight: 700;
    font-size: 0.8em;
    letter-spacing: 2px;
    height: auto; }
  .page-admin-panel .live-feed-container .toggle-screenshare.disabled:before,
  .page-sessison-virtual .live-feed-container .toggle-screenshare.disabled:before,
  .page-session-zoom .live-feed-container .toggle-screenshare.disabled:before,
  .page-video-testing .live-feed-container .toggle-screenshare.disabled:before,
  .page-virtual-exhibitor .live-feed-container .toggle-screenshare.disabled:before {
    content: '\f110';
    font-family: 'Font Awesome 5 Pro';
    -webkit-animation: fa-spin 2s linear infinite;
    animation: fa-spin 2s linear infinite;
    margin-right: 10px; }
  .page-admin-panel [data-feed="presenter"] [rel="#action-tab-collaboration"],
  .page-sessison-virtual [data-feed="presenter"] [rel="#action-tab-collaboration"],
  .page-session-zoom [data-feed="presenter"] [rel="#action-tab-collaboration"],
  .page-video-testing [data-feed="presenter"] [rel="#action-tab-collaboration"],
  .page-virtual-exhibitor [data-feed="presenter"] [rel="#action-tab-collaboration"] {
    position: absolute;
    bottom: 5px;
    right: 5px; }
  .page-admin-panel #liveFeed-users,
  .page-sessison-virtual #liveFeed-users,
  .page-session-zoom #liveFeed-users,
  .page-video-testing #liveFeed-users,
  .page-virtual-exhibitor #liveFeed-users {
    background: #fff; }
  .page-admin-panel #liveFeed-users:before,
  .page-admin-panel #liveFeed-users:after,
  .page-sessison-virtual #liveFeed-users:before,
  .page-sessison-virtual #liveFeed-users:after,
  .page-session-zoom #liveFeed-users:before,
  .page-session-zoom #liveFeed-users:after,
  .page-video-testing #liveFeed-users:before,
  .page-video-testing #liveFeed-users:after,
  .page-virtual-exhibitor #liveFeed-users:before,
  .page-virtual-exhibitor #liveFeed-users:after {
    content: '';
    display: table; }
  .page-admin-panel #liveFeed-users:after,
  .page-sessison-virtual #liveFeed-users:after,
  .page-session-zoom #liveFeed-users:after,
  .page-video-testing #liveFeed-users:after,
  .page-virtual-exhibitor #liveFeed-users:after {
    clear: both; }
  .page-admin-panel #liveFeed-users .video-block,
  .page-sessison-virtual #liveFeed-users .video-block,
  .page-session-zoom #liveFeed-users .video-block,
  .page-video-testing #liveFeed-users .video-block,
  .page-virtual-exhibitor #liveFeed-users .video-block {
    float: left;
    width: 100%; }
  .page-admin-panel #liveFeed-users .video-block .media-wrapper,
  .page-sessison-virtual #liveFeed-users .video-block .media-wrapper,
  .page-session-zoom #liveFeed-users .video-block .media-wrapper,
  .page-video-testing #liveFeed-users .video-block .media-wrapper,
  .page-virtual-exhibitor #liveFeed-users .video-block .media-wrapper {
    position: relative;
    width: 100%;
    padding: 75% 0 0 0; }
  .page-admin-panel #liveFeed-users .video-block .media-wrapper video,
  .page-sessison-virtual #liveFeed-users .video-block .media-wrapper video,
  .page-session-zoom #liveFeed-users .video-block .media-wrapper video,
  .page-video-testing #liveFeed-users .video-block .media-wrapper video,
  .page-virtual-exhibitor #liveFeed-users .video-block .media-wrapper video {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0; }
  .page-admin-panel #liveFeed-presenter .video-block-overlay,
  .page-sessison-virtual #liveFeed-presenter .video-block-overlay,
  .page-session-zoom #liveFeed-presenter .video-block-overlay,
  .page-video-testing #liveFeed-presenter .video-block-overlay,
  .page-virtual-exhibitor #liveFeed-presenter .video-block-overlay {
    display: none; }
  .page-admin-panel #liveFeed-screenshare .video-block .media-wrapper,
  .page-sessison-virtual #liveFeed-screenshare .video-block .media-wrapper,
  .page-session-zoom #liveFeed-screenshare .video-block .media-wrapper,
  .page-video-testing #liveFeed-screenshare .video-block .media-wrapper,
  .page-virtual-exhibitor #liveFeed-screenshare .video-block .media-wrapper {
    padding-top: 56.25%; }
  .page-admin-panel #liveFeed-screenshare .video-block video,
  .page-sessison-virtual #liveFeed-screenshare .video-block video,
  .page-session-zoom #liveFeed-screenshare .video-block video,
  .page-video-testing #liveFeed-screenshare .video-block video,
  .page-virtual-exhibitor #liveFeed-screenshare .video-block video {
    object-fit: contain !important; }
  .page-admin-panel #liveFeed-screenshare .video-status.audio-disabled,
  .page-sessison-virtual #liveFeed-screenshare .video-status.audio-disabled,
  .page-session-zoom #liveFeed-screenshare .video-status.audio-disabled,
  .page-video-testing #liveFeed-screenshare .video-status.audio-disabled,
  .page-virtual-exhibitor #liveFeed-screenshare .video-status.audio-disabled {
    display: none; }
  .page-admin-panel .video-block.video-block-agora .media-wrapper,
  .page-sessison-virtual .video-block.video-block-agora .media-wrapper,
  .page-session-zoom .video-block.video-block-agora .media-wrapper,
  .page-video-testing .video-block.video-block-agora .media-wrapper,
  .page-virtual-exhibitor .video-block.video-block-agora .media-wrapper {
    width: 100%;
    padding-top: 75%; }
  .page-admin-panel .video-block.video-block-agora .media-wrapper .video-placeholder,
  .page-sessison-virtual .video-block.video-block-agora .media-wrapper .video-placeholder,
  .page-session-zoom .video-block.video-block-agora .media-wrapper .video-placeholder,
  .page-video-testing .video-block.video-block-agora .media-wrapper .video-placeholder,
  .page-virtual-exhibitor .video-block.video-block-agora .media-wrapper .video-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
  .page-admin-panel .video-block.video-block-agora .play-stream,
  .page-sessison-virtual .video-block.video-block-agora .play-stream,
  .page-session-zoom .video-block.video-block-agora .play-stream,
  .page-video-testing .video-block.video-block-agora .play-stream,
  .page-virtual-exhibitor .video-block.video-block-agora .play-stream {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999; }
  .page-admin-panel .video-block.video-block-agora .play-stream .fa,
  .page-sessison-virtual .video-block.video-block-agora .play-stream .fa,
  .page-session-zoom .video-block.video-block-agora .play-stream .fa,
  .page-video-testing .video-block.video-block-agora .play-stream .fa,
  .page-virtual-exhibitor .video-block.video-block-agora .play-stream .fa {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin: -.5em 0 0 0;
    font-size: 3em;
    text-align: center; }
  .page-admin-panel #liveFeed-users .video-block[data-hasvideo="false"],
  .page-sessison-virtual #liveFeed-users .video-block[data-hasvideo="false"],
  .page-session-zoom #liveFeed-users .video-block[data-hasvideo="false"],
  .page-video-testing #liveFeed-users .video-block[data-hasvideo="false"],
  .page-virtual-exhibitor #liveFeed-users .video-block[data-hasvideo="false"] {
    width: 0px !important; }
  .page-admin-panel .video-admin-block,
  .page-sessison-virtual .video-admin-block,
  .page-session-zoom .video-admin-block,
  .page-video-testing .video-admin-block,
  .page-virtual-exhibitor .video-admin-block {
    background: #fafafa;
    padding: 16px 16px 10px;
    color: #000;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #ccc;
    border-top: 6px solid #2c47af;
    margin: 1em 0;
    position: relative; }
  .page-admin-panel .video-admin-block h4, .page-admin-panel .video-admin-block .h4,
  .page-sessison-virtual .video-admin-block h4,
  .page-sessison-virtual .video-admin-block .h4,
  .page-session-zoom .video-admin-block h4,
  .page-session-zoom .video-admin-block .h4,
  .page-video-testing .video-admin-block h4,
  .page-video-testing .video-admin-block .h4,
  .page-virtual-exhibitor .video-admin-block h4,
  .page-virtual-exhibitor .video-admin-block .h4 {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    font-size: 1.1em; }
  .page-admin-panel .video-admin-block h4 small, .page-admin-panel .video-admin-block .h4 small, .page-admin-panel .video-admin-block h4 .small, .page-admin-panel .video-admin-block .h4 .small,
  .page-sessison-virtual .video-admin-block h4 small,
  .page-sessison-virtual .video-admin-block .h4 small,
  .page-sessison-virtual .video-admin-block h4 .small,
  .page-sessison-virtual .video-admin-block .h4 .small,
  .page-session-zoom .video-admin-block h4 small,
  .page-session-zoom .video-admin-block .h4 small,
  .page-session-zoom .video-admin-block h4 .small,
  .page-session-zoom .video-admin-block .h4 .small,
  .page-video-testing .video-admin-block h4 small,
  .page-video-testing .video-admin-block .h4 small,
  .page-video-testing .video-admin-block h4 .small,
  .page-video-testing .video-admin-block .h4 .small,
  .page-virtual-exhibitor .video-admin-block h4 small,
  .page-virtual-exhibitor .video-admin-block .h4 small,
  .page-virtual-exhibitor .video-admin-block h4 .small,
  .page-virtual-exhibitor .video-admin-block .h4 .small {
    text-transform: initial;
    letter-spacing: 0;
    display: block;
    font-weight: 700;
    font-size: .8em;
    margin: .5em 0 0; }
  .page-admin-panel .video-admin-block .inner-content,
  .page-sessison-virtual .video-admin-block .inner-content,
  .page-session-zoom .video-admin-block .inner-content,
  .page-video-testing .video-admin-block .inner-content,
  .page-virtual-exhibitor .video-admin-block .inner-content {
    transition-duration: .2s;
    padding-top: 15px; }
  .page-admin-panel .video-admin-block.minimized .inner-content,
  .page-sessison-virtual .video-admin-block.minimized .inner-content,
  .page-session-zoom .video-admin-block.minimized .inner-content,
  .page-video-testing .video-admin-block.minimized .inner-content,
  .page-virtual-exhibitor .video-admin-block.minimized .inner-content {
    height: 0 !important;
    padding: 0;
    overflow: hidden;
    transition-duration: .2s; }
  .page-admin-panel .video-admin-block.minimized .video-admin-toggle,
  .page-sessison-virtual .video-admin-block.minimized .video-admin-toggle,
  .page-session-zoom .video-admin-block.minimized .video-admin-toggle,
  .page-video-testing .video-admin-block.minimized .video-admin-toggle,
  .page-virtual-exhibitor .video-admin-block.minimized .video-admin-toggle {
    transform: rotate(180deg);
    transition-duration: .2s; }
  .page-admin-panel .video-admin-block .btn,
  .page-sessison-virtual .video-admin-block .btn,
  .page-session-zoom .video-admin-block .btn,
  .page-video-testing .video-admin-block .btn,
  .page-virtual-exhibitor .video-admin-block .btn {
    line-height: 1.7em;
    height: 2.5em; }
  .page-admin-panel .video-admin-toggle,
  .page-sessison-virtual .video-admin-toggle,
  .page-session-zoom .video-admin-toggle,
  .page-video-testing .video-admin-toggle,
  .page-virtual-exhibitor .video-admin-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    font-size: 1.25em;
    transform: rotate(0);
    transition-duration: .2s; }
  .page-admin-panel .search-container.loading,
  .page-sessison-virtual .search-container.loading,
  .page-session-zoom .search-container.loading,
  .page-video-testing .search-container.loading,
  .page-virtual-exhibitor .search-container.loading {
    position: relative; }
  .page-admin-panel .search-container.loading:after,
  .page-sessison-virtual .search-container.loading:after,
  .page-session-zoom .search-container.loading:after,
  .page-video-testing .search-container.loading:after,
  .page-virtual-exhibitor .search-container.loading:after {
    content: '\f3f4';
    font-family: 'Font Awesome 5 Pro';
    position: absolute;
    top: 5px;
    right: 5px;
    -webkit-animation: fa-spin 2s linear infinite;
    animation: fa-spin 2s linear infinite;
    opacity: .5; }
  .page-admin-panel .workshop-list,
  .page-sessison-virtual .workshop-list,
  .page-session-zoom .workshop-list,
  .page-video-testing .workshop-list,
  .page-virtual-exhibitor .workshop-list {
    max-height: 300px;
    overflow: auto;
    font-size: .85em;
    padding-bottom: 2px; }
  .page-admin-panel .workshop-list#list-audience,
  .page-sessison-virtual .workshop-list#list-audience,
  .page-session-zoom .workshop-list#list-audience,
  .page-video-testing .workshop-list#list-audience,
  .page-virtual-exhibitor .workshop-list#list-audience {
    font-size: .75em; }
  .page-admin-panel .workshop-list-item,
  .page-sessison-virtual .workshop-list-item,
  .page-session-zoom .workshop-list-item,
  .page-video-testing .workshop-list-item,
  .page-virtual-exhibitor .workshop-list-item {
    padding: 0.5em .9em 0.5em 3em;
    border: 1px solid #ccc;
    background: #fafafa;
    color: #000;
    position: relative;
    border-radius: 4px;
    margin-bottom: 1px; }
  .page-admin-panel .workshop-list-item > .fa,
  .page-admin-panel .workshop-list-item > .fal,
  .page-admin-panel .workshop-list-item > .fas,
  .page-sessison-virtual .workshop-list-item > .fa,
  .page-sessison-virtual .workshop-list-item > .fal,
  .page-sessison-virtual .workshop-list-item > .fas,
  .page-session-zoom .workshop-list-item > .fa,
  .page-session-zoom .workshop-list-item > .fal,
  .page-session-zoom .workshop-list-item > .fas,
  .page-video-testing .workshop-list-item > .fa,
  .page-video-testing .workshop-list-item > .fal,
  .page-video-testing .workshop-list-item > .fas,
  .page-virtual-exhibitor .workshop-list-item > .fa,
  .page-virtual-exhibitor .workshop-list-item > .fal,
  .page-virtual-exhibitor .workshop-list-item > .fas {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -.5em;
    width: 2em;
    text-align: center; }
  .page-admin-panel .workshop-list-item .me,
  .page-sessison-virtual .workshop-list-item .me,
  .page-session-zoom .workshop-list-item .me,
  .page-video-testing .workshop-list-item .me,
  .page-virtual-exhibitor .workshop-list-item .me {
    position: absolute;
    top: 50%;
    right: 120px;
    width: 2.5em;
    background: #3f5e88;
    border-radius: 4px;
    text-align: center;
    line-height: 1.5em;
    color: #fff;
    font-size: .85em;
    margin-top: -0.65em; }
  .page-admin-panel #list-live .workshop-list-item .me,
  .page-sessison-virtual #list-live .workshop-list-item .me,
  .page-session-zoom #list-live .workshop-list-item .me,
  .page-video-testing #list-live .workshop-list-item .me,
  .page-virtual-exhibitor #list-live .workshop-list-item .me {
    top: 5px;
    margin-top: 0;
    right: 10px; }
  .page-admin-panel .workshop-list-item .workshop-list-item-heading,
  .page-sessison-virtual .workshop-list-item .workshop-list-item-heading,
  .page-session-zoom .workshop-list-item .workshop-list-item-heading,
  .page-video-testing .workshop-list-item .workshop-list-item-heading,
  .page-virtual-exhibitor .workshop-list-item .workshop-list-item-heading {
    font-size: 1.2em;
    margin: 0;
    padding: 0; }
  .page-admin-panel .workshop-list-item button,
  .page-sessison-virtual .workshop-list-item button,
  .page-session-zoom .workshop-list-item button,
  .page-video-testing .workshop-list-item button,
  .page-virtual-exhibitor .workshop-list-item button {
    float: right;
    font-size: 0.8em;
    margin-top: -1.95em;
    position: relative;
    padding: .35em .5em; }
  .page-admin-panel .workshop-list-item button .fa,
  .page-sessison-virtual .workshop-list-item button .fa,
  .page-session-zoom .workshop-list-item button .fa,
  .page-video-testing .workshop-list-item button .fa,
  .page-virtual-exhibitor .workshop-list-item button .fa {
    margin-right: .5em; }
  .page-admin-panel .workshop-list#list-live button,
  .page-sessison-virtual .workshop-list#list-live button,
  .page-session-zoom .workshop-list#list-live button,
  .page-video-testing .workshop-list#list-live button,
  .page-virtual-exhibitor .workshop-list#list-live button {
    float: none;
    margin: 5px 0 -2px 0;
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    height: auto;
    line-height: 2em; }
  .page-admin-panel .workshop-list#list-live .fa-hand-paper,
  .page-sessison-virtual .workshop-list#list-live .fa-hand-paper,
  .page-session-zoom .workshop-list#list-live .fa-hand-paper,
  .page-video-testing .workshop-list#list-live .fa-hand-paper,
  .page-virtual-exhibitor .workshop-list#list-live .fa-hand-paper {
    display: none; }

.page-session-library {
  /*overlay filter*/
  /*#all-videos.filter-container {
		display: block!important;
		flex-wrap: nowrap;
	}*/
  /* ==========================================================================
	   bx-slider for promoted videos
	   ========================================================================== */ }
  .page-session-library .scrollable {
    padding-bottom: 100px; }
  .page-session-library .video-item-column p {
    font-size: 16px;
    line-height: 18px; }
  .page-session-library .video-item {
    position: relative; }
  .page-session-library .video-item i.fa-fire {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 22px;
    color: #fff; }
  .page-session-library .video-item img {
    display: block;
    width: 100%;
    height: auto; }
  .page-session-library .play-btn {
    display: block;
    width: 60px;
    height: 40px;
    border-radius: 3px;
    text-align: center;
    position: absolute;
    bottom: 10px;
    right: 5px;
    padding: 5px 10px;
    background: rgba(0, 0, 0, 0.7);
    font-size: 20px;
    color: #fff; }
  .page-session-library .promoted {
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    padding: 3px 7px;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-size: 11px; }
  .page-session-library .video-link:hover {
    cursor: pointer; }
  .page-session-library #promoted-video .col-sm-9 {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 60px; }
  .page-session-library #filter-tags-btn, .page-session-library #filter-tracks-btn {
    background: #5d5d5d;
    border-color: #5d5d5d; }
  .page-session-library #session-library-content {
    max-width: 1200px;
    margin: 0 auto; }
  .page-session-library #session-library h2, .page-session-library #session-library .h2 {
    font-weight: bold;
    text-transform: uppercase; }
  .page-session-library .video-info {
    height: 100px;
    overflow: hidden; }
  .page-session-library .video-image {
    width: 100%;
    height: 140px; }
  .page-session-library .filter-widget {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: none;
    z-index: 4000; }
  .page-session-library .filter-widget-content {
    width: 300px;
    margin: 70px auto 0;
    background: #fff;
    -webkit-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3); }
  .page-session-library .filter-list {
    height: 350px;
    padding: 10px;
    overflow-y: auto; }
  .page-session-library .filter-footer {
    padding: 10px;
    background: #ddd; }
  .page-session-library .filter-list ul {
    margin-left: 0;
    padding-left: 0; }
  .page-session-library .filter-list .filter-header, .page-session-library .filter-list .filter-link {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .page-session-library .filter-list .filter-header {
    border-bottom: 2px solid #eaeaea;
    font-size: 15px;
    font-weight: bold;
    padding: 8px 5px 8px 5px;
    background-color: #d5d5d5; }
  .page-session-library .filter-list .filter-link {
    border-bottom: 1px solid #eaeaea;
    font-size: 14px;
    padding: 5px 5px 8px 5px;
    cursor: pointer;
    transition: 0.2s ease-in-out all; }
    .page-session-library .filter-list .filter-link.selected {
      background-color: #005eb8;
      color: #fff; }
  .page-session-library #all-videos {
    min-height: 800px; }
  .page-session-library .bx-wrapper {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: none !important;
    background: none !important; }
  .page-session-library .video-item-column {
    width: 24.5% !important;
    padding: 10px; }
  .page-session-library #all-videos {
    min-height: 800px;
    width: 100%; }
  .page-session-library .item .video-image {
    width: 100%; }
  .page-session-library #all-videos {
    overflow-x: hidden;
    overflow-y: visible; }
  .page-session-library .filter-container {
    overflow-y: hidden; }
  .page-session-library .vid-description {
    display: none;
    font-size: 13px;
    margin: 5px 0;
    font-style: italic; }
  .page-session-library .list-vid-name {
    display: none; }
  .page-session-library .video-item-column.list-view .grid-vid-name {
    display: none; }
  .page-session-library .video-item-column.list-view .list-vid-name {
    display: block; }
  .page-session-library .video-item-column.list-view {
    width: 49% !important;
    margin-top: 0 !important; }
  .page-session-library .video-item-column.list-view .play-btn,
  .page-session-library .video-item-column.list-view .hot-tag {
    display: none; }
  .page-session-library .video-item-column.list-view .video-image {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 80px;
    width: 130px; }
  .page-session-library .video-item-column.list-view .video-info {
    padding-right: 25px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 120px;
    padding-left: 160px;
    background: #fff; }
  .page-session-library .video-item-column.list-view .vid-description {
    display: block; }
  .page-session-library .video-item-column.list-view .vid-name {
    margin-top: 10px !important; }
  @media (max-width: 768px) {
    .page-session-library .video-item-column {
      width: 49% !important;
      padding: 10px; }
    .page-session-library .video-item-column.list-view {
      width: 100% !important; }
    .page-session-library #all-videos {
      width: 100% !important; } }
  @media (max-width: 500px) {
    .page-session-library .video-item-column {
      width: 100% !important; } }
  @media (max-width: 700px) {
    .page-session-library .play-btn {
      left: 6px; } }
  @media only screen and (max-width: 991px) {
    .page-session-library .video-image {
      width: 100%;
      height: 210px; } }

.page-admin-panel,
.page-session-engagement,
.page-session-overview,
.page-sessison-virtual,
.page-session-zoom,
.page-session,
.page-virtual-exhibitor {
  /* header styles
	==============================================================================*/
  /*sidebar tabs
	==============================================================================*/
  /** Remove browser outline **/
  /*minimal scroll bars*/
  /* Track */
  /* Handle */
  /* Handle on hover */
  /* Track */
  /* Handle */
  /* Handle on hover */
  /*recommended videos
	==============================================================================*/
  /*list groups session speakers
	==============================================================================*/
  /*base layout
	==============================================================================*/
  /*** Fix to layout bug on collab ***/
  /*Conversation wall style chat
	==============================================================================*/
  /* ==========================================================================
	Live Chat
	========================================================================== */
  /* Footer
	========================================================================== */
  /* Reply to chat
	========================================================================== */
  /* ==========================================================================
	Polls
	========================================================================== */
  /* .poll-sidebar-content {
		position: absolute;
		top: 65px;
		right: 0;
		bottom: 94px;
		left: 0;
		width: 100%;
		height: auto;
		background: #fff;
		z-index: 99;
		overflow-y: auto;
	} */
  /* ==========================================================================
	JS Helpers
	========================================================================== */
  /*video overlays*/
  /*slider*/
  /* ==========================================================================
	video embeds
	========================================================================== */
  /*collaborative*/
  /*new modals*/
  /* ==========================================================================
	Live Questions
	========================================================================== */
  /*size toggling*/
  /*Presenter Availability*/
  /* ==========================================================================
	help tutorial
	========================================================================== */
  /** Action Panel **/
  /*presenter availability ui*/
  /*Theatre mode*/
  /* end Theatre mode*/
  /*end responsive container*/
  /* new theatre on styles */
  /* Condensed
	========================================================================== */
  /** Form **/
  /** Chat block **/
  /** Chat block Header **/
  /** Chat block Comment **/
  /** Chat block Footer **/
  /** Chat block reply form **/
  /* ==========================================================================
	New Rating Module
	========================================================================== */
  /* ZOOMING ISSUE ON SESSIONCHAT/HYBRID - do NOT change if using hybrid apps*/
  /*** Custom loader ***/
  /** chat slide in **/ }
  .page-admin-panel .custom-modal,
  .page-session-engagement .custom-modal,
  .page-session-overview .custom-modal,
  .page-sessison-virtual .custom-modal,
  .page-session-zoom .custom-modal,
  .page-session .custom-modal,
  .page-virtual-exhibitor .custom-modal {
    position: absolute; }
  .page-admin-panel a.action-item,
  .page-session-engagement a.action-item,
  .page-session-overview a.action-item,
  .page-sessison-virtual a.action-item,
  .page-session-zoom a.action-item,
  .page-session a.action-item,
  .page-virtual-exhibitor a.action-item {
    display: inline-block;
    padding: 10px 8px;
    text-decoration: none;
    font-size: 14px; }
  .page-admin-panel a.action-item:focus, .page-admin-panel a.action-item.display,
  .page-session-engagement a.action-item:focus,
  .page-session-engagement a.action-item.display,
  .page-session-overview a.action-item:focus,
  .page-session-overview a.action-item.display,
  .page-sessison-virtual a.action-item:focus,
  .page-sessison-virtual a.action-item.display,
  .page-session-zoom a.action-item:focus,
  .page-session-zoom a.action-item.display,
  .page-session a.action-item:focus,
  .page-session a.action-item.display,
  .page-virtual-exhibitor a.action-item:focus,
  .page-virtual-exhibitor a.action-item.display {
    outline: none;
    font-weight: bold; }
  .page-admin-panel #action-panel-nav,
  .page-session-engagement #action-panel-nav,
  .page-session-overview #action-panel-nav,
  .page-sessison-virtual #action-panel-nav,
  .page-session-zoom #action-panel-nav,
  .page-session #action-panel-nav,
  .page-virtual-exhibitor #action-panel-nav {
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
    background: #fff; }
  .page-admin-panel #action-panel-tabs,
  .page-session-engagement #action-panel-tabs,
  .page-session-overview #action-panel-tabs,
  .page-sessison-virtual #action-panel-tabs,
  .page-session-zoom #action-panel-tabs,
  .page-session #action-panel-tabs,
  .page-virtual-exhibitor #action-panel-tabs {
    padding: 20px; }
  .page-admin-panel .action-tab,
  .page-session-engagement .action-tab,
  .page-session-overview .action-tab,
  .page-sessison-virtual .action-tab,
  .page-session-zoom .action-tab,
  .page-session .action-tab,
  .page-virtual-exhibitor .action-tab {
    display: none; }
  .page-admin-panel .action-tab.display, .page-admin-panel .with-action-tab .action-tab,
  .page-session-engagement .action-tab.display,
  .page-session-engagement .with-action-tab .action-tab,
  .page-session-overview .action-tab.display,
  .page-session-overview .with-action-tab .action-tab,
  .page-sessison-virtual .action-tab.display,
  .page-sessison-virtual .with-action-tab .action-tab,
  .page-session-zoom .action-tab.display,
  .page-session-zoom .with-action-tab .action-tab,
  .page-session .action-tab.display,
  .page-session .with-action-tab .action-tab,
  .page-virtual-exhibitor .action-tab.display,
  .page-virtual-exhibitor .with-action-tab .action-tab {
    display: block !important; }
  .page-admin-panel .action-tab:focus,
  .page-session-engagement .action-tab:focus,
  .page-session-overview .action-tab:focus,
  .page-sessison-virtual .action-tab:focus,
  .page-session-zoom .action-tab:focus,
  .page-session .action-tab:focus,
  .page-virtual-exhibitor .action-tab:focus {
    border: none;
    outline: none; }
  .page-admin-panel .action-tab.display:focus,
  .page-admin-panel .action-tab.display *:focus,
  .page-admin-panel .action-tab.display *:focus-within,
  .page-session-engagement .action-tab.display:focus,
  .page-session-engagement .action-tab.display *:focus,
  .page-session-engagement .action-tab.display *:focus-within,
  .page-session-overview .action-tab.display:focus,
  .page-session-overview .action-tab.display *:focus,
  .page-session-overview .action-tab.display *:focus-within,
  .page-sessison-virtual .action-tab.display:focus,
  .page-sessison-virtual .action-tab.display *:focus,
  .page-sessison-virtual .action-tab.display *:focus-within,
  .page-session-zoom .action-tab.display:focus,
  .page-session-zoom .action-tab.display *:focus,
  .page-session-zoom .action-tab.display *:focus-within,
  .page-session .action-tab.display:focus,
  .page-session .action-tab.display *:focus,
  .page-session .action-tab.display *:focus-within,
  .page-virtual-exhibitor .action-tab.display:focus,
  .page-virtual-exhibitor .action-tab.display *:focus,
  .page-virtual-exhibitor .action-tab.display *:focus-within {
    outline: none; }
  .page-admin-panel #action-tab-3 .media img,
  .page-session-engagement #action-tab-3 .media img,
  .page-session-overview #action-tab-3 .media img,
  .page-sessison-virtual #action-tab-3 .media img,
  .page-session-zoom #action-tab-3 .media img,
  .page-session #action-tab-3 .media img,
  .page-virtual-exhibitor #action-tab-3 .media img {
    border: 1px solid #ccc; }
  .page-admin-panel #action-panel-tabs::-webkit-scrollbar,
  .page-session-engagement #action-panel-tabs::-webkit-scrollbar,
  .page-session-overview #action-panel-tabs::-webkit-scrollbar,
  .page-sessison-virtual #action-panel-tabs::-webkit-scrollbar,
  .page-session-zoom #action-panel-tabs::-webkit-scrollbar,
  .page-session #action-panel-tabs::-webkit-scrollbar,
  .page-virtual-exhibitor #action-panel-tabs::-webkit-scrollbar {
    width: 10px; }
  .page-admin-panel #action-panel-tabs::-webkit-scrollbar-track,
  .page-session-engagement #action-panel-tabs::-webkit-scrollbar-track,
  .page-session-overview #action-panel-tabs::-webkit-scrollbar-track,
  .page-sessison-virtual #action-panel-tabs::-webkit-scrollbar-track,
  .page-session-zoom #action-panel-tabs::-webkit-scrollbar-track,
  .page-session #action-panel-tabs::-webkit-scrollbar-track,
  .page-virtual-exhibitor #action-panel-tabs::-webkit-scrollbar-track {
    background: #ddd; }
  .page-admin-panel #action-panel-tabs::-webkit-scrollbar-thumb,
  .page-session-engagement #action-panel-tabs::-webkit-scrollbar-thumb,
  .page-session-overview #action-panel-tabs::-webkit-scrollbar-thumb,
  .page-sessison-virtual #action-panel-tabs::-webkit-scrollbar-thumb,
  .page-session-zoom #action-panel-tabs::-webkit-scrollbar-thumb,
  .page-session #action-panel-tabs::-webkit-scrollbar-thumb,
  .page-virtual-exhibitor #action-panel-tabs::-webkit-scrollbar-thumb {
    background: #ccc; }
  .page-admin-panel #action-panel-tabs::-webkit-scrollbar-thumb:hover,
  .page-session-engagement #action-panel-tabs::-webkit-scrollbar-thumb:hover,
  .page-session-overview #action-panel-tabs::-webkit-scrollbar-thumb:hover,
  .page-sessison-virtual #action-panel-tabs::-webkit-scrollbar-thumb:hover,
  .page-session-zoom #action-panel-tabs::-webkit-scrollbar-thumb:hover,
  .page-session #action-panel-tabs::-webkit-scrollbar-thumb:hover,
  .page-virtual-exhibitor #action-panel-tabs::-webkit-scrollbar-thumb:hover {
    background: #bbb; }
  .page-admin-panel #action-panel-nav::-webkit-scrollbar,
  .page-session-engagement #action-panel-nav::-webkit-scrollbar,
  .page-session-overview #action-panel-nav::-webkit-scrollbar,
  .page-sessison-virtual #action-panel-nav::-webkit-scrollbar,
  .page-session-zoom #action-panel-nav::-webkit-scrollbar,
  .page-session #action-panel-nav::-webkit-scrollbar,
  .page-virtual-exhibitor #action-panel-nav::-webkit-scrollbar {
    height: 10px; }
  .page-admin-panel #action-panel-nav::-webkit-scrollbar-track,
  .page-session-engagement #action-panel-nav::-webkit-scrollbar-track,
  .page-session-overview #action-panel-nav::-webkit-scrollbar-track,
  .page-sessison-virtual #action-panel-nav::-webkit-scrollbar-track,
  .page-session-zoom #action-panel-nav::-webkit-scrollbar-track,
  .page-session #action-panel-nav::-webkit-scrollbar-track,
  .page-virtual-exhibitor #action-panel-nav::-webkit-scrollbar-track {
    background: #ddd; }
  .page-admin-panel #action-panel-nav::-webkit-scrollbar-thumb,
  .page-session-engagement #action-panel-nav::-webkit-scrollbar-thumb,
  .page-session-overview #action-panel-nav::-webkit-scrollbar-thumb,
  .page-sessison-virtual #action-panel-nav::-webkit-scrollbar-thumb,
  .page-session-zoom #action-panel-nav::-webkit-scrollbar-thumb,
  .page-session #action-panel-nav::-webkit-scrollbar-thumb,
  .page-virtual-exhibitor #action-panel-nav::-webkit-scrollbar-thumb {
    background: #ccc; }
  .page-admin-panel #action-panel-nav::-webkit-scrollbar-thumb:hover,
  .page-session-engagement #action-panel-nav::-webkit-scrollbar-thumb:hover,
  .page-session-overview #action-panel-nav::-webkit-scrollbar-thumb:hover,
  .page-sessison-virtual #action-panel-nav::-webkit-scrollbar-thumb:hover,
  .page-session-zoom #action-panel-nav::-webkit-scrollbar-thumb:hover,
  .page-session #action-panel-nav::-webkit-scrollbar-thumb:hover,
  .page-virtual-exhibitor #action-panel-nav::-webkit-scrollbar-thumb:hover {
    background: #bbb; }
  .page-admin-panel .list-group-item:first-child,
  .page-admin-panel a.list-group-item:first-child,
  .page-session-engagement .list-group-item:first-child,
  .page-session-engagement a.list-group-item:first-child,
  .page-session-overview .list-group-item:first-child,
  .page-session-overview a.list-group-item:first-child,
  .page-sessison-virtual .list-group-item:first-child,
  .page-sessison-virtual a.list-group-item:first-child,
  .page-session-zoom .list-group-item:first-child,
  .page-session-zoom a.list-group-item:first-child,
  .page-session .list-group-item:first-child,
  .page-session a.list-group-item:first-child,
  .page-virtual-exhibitor .list-group-item:first-child,
  .page-virtual-exhibitor a.list-group-item:first-child {
    margin-top: 0; }
  .page-admin-panel .video-item-column p,
  .page-session-engagement .video-item-column p,
  .page-session-overview .video-item-column p,
  .page-sessison-virtual .video-item-column p,
  .page-session-zoom .video-item-column p,
  .page-session .video-item-column p,
  .page-virtual-exhibitor .video-item-column p {
    font-size: 16px;
    line-height: 18px; }
  .page-admin-panel .video-item,
  .page-session-engagement .video-item,
  .page-session-overview .video-item,
  .page-sessison-virtual .video-item,
  .page-session-zoom .video-item,
  .page-session .video-item,
  .page-virtual-exhibitor .video-item {
    position: relative; }
  .page-admin-panel .video-item i.fa-fire,
  .page-session-engagement .video-item i.fa-fire,
  .page-session-overview .video-item i.fa-fire,
  .page-sessison-virtual .video-item i.fa-fire,
  .page-session-zoom .video-item i.fa-fire,
  .page-session .video-item i.fa-fire,
  .page-virtual-exhibitor .video-item i.fa-fire {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 22px;
    color: #fff; }
  .page-admin-panel .video-item img,
  .page-session-engagement .video-item img,
  .page-session-overview .video-item img,
  .page-sessison-virtual .video-item img,
  .page-session-zoom .video-item img,
  .page-session .video-item img,
  .page-virtual-exhibitor .video-item img {
    background-color: #ddd;
    border: 1px solid #ccc;
    display: block;
    width: 450px; }
  .page-admin-panel .video-item:hover,
  .page-session-engagement .video-item:hover,
  .page-session-overview .video-item:hover,
  .page-sessison-virtual .video-item:hover,
  .page-session-zoom .video-item:hover,
  .page-session .video-item:hover,
  .page-virtual-exhibitor .video-item:hover {
    cursor: pointer; }
  .page-admin-panel .video-info,
  .page-session-engagement .video-info,
  .page-session-overview .video-info,
  .page-sessison-virtual .video-info,
  .page-session-zoom .video-info,
  .page-session .video-info,
  .page-virtual-exhibitor .video-info {
    height: 80px;
    overflow: hidden; }
  .page-admin-panel .play-btn,
  .page-session-engagement .play-btn,
  .page-session-overview .play-btn,
  .page-sessison-virtual .play-btn,
  .page-session-zoom .play-btn,
  .page-session .play-btn,
  .page-virtual-exhibitor .play-btn {
    display: block;
    width: 60px;
    height: 40px;
    border-radius: 3px;
    text-align: center;
    position: absolute;
    bottom: 10px;
    right: 5px;
    padding: 5px 10px;
    background: rgba(0, 162, 141, 0.7);
    font-size: 20px;
    color: #fff; }
  .page-admin-panel .page .list-group-item.session-speaker a,
  .page-session-engagement .page .list-group-item.session-speaker a,
  .page-session-overview .page .list-group-item.session-speaker a,
  .page-sessison-virtual .page .list-group-item.session-speaker a,
  .page-session-zoom .page .list-group-item.session-speaker a,
  .page-session .page .list-group-item.session-speaker a,
  .page-virtual-exhibitor .page .list-group-item.session-speaker a {
    padding: 0 0 0 0; }
  .page-admin-panel .list-group-item.session-speaker .media-left,
  .page-session-engagement .list-group-item.session-speaker .media-left,
  .page-session-overview .list-group-item.session-speaker .media-left,
  .page-sessison-virtual .list-group-item.session-speaker .media-left,
  .page-session-zoom .list-group-item.session-speaker .media-left,
  .page-session .list-group-item.session-speaker .media-left,
  .page-virtual-exhibitor .list-group-item.session-speaker .media-left {
    max-width: 80px; }
  @media screen and (max-height: 1200px) {
    .page-admin-panel #virtual-main-content.session-type-collaborative,
    .page-admin-panel #virtual-main-content.session-type-collaborative-workshop,
    .page-session-engagement #virtual-main-content.session-type-collaborative,
    .page-session-engagement #virtual-main-content.session-type-collaborative-workshop,
    .page-session-overview #virtual-main-content.session-type-collaborative,
    .page-session-overview #virtual-main-content.session-type-collaborative-workshop,
    .page-sessison-virtual #virtual-main-content.session-type-collaborative,
    .page-sessison-virtual #virtual-main-content.session-type-collaborative-workshop,
    .page-session-zoom #virtual-main-content.session-type-collaborative,
    .page-session-zoom #virtual-main-content.session-type-collaborative-workshop,
    .page-session #virtual-main-content.session-type-collaborative,
    .page-session #virtual-main-content.session-type-collaborative-workshop,
    .page-virtual-exhibitor #virtual-main-content.session-type-collaborative,
    .page-virtual-exhibitor #virtual-main-content.session-type-collaborative-workshop {
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch; } }
  .page-admin-panel iframe,
  .page-session-engagement iframe,
  .page-session-overview iframe,
  .page-sessison-virtual iframe,
  .page-session-zoom iframe,
  .page-session iframe,
  .page-virtual-exhibitor iframe {
    margin: 0 0 0 0; }
  .page-admin-panel .video-bg-wrapper,
  .page-session-engagement .video-bg-wrapper,
  .page-session-overview .video-bg-wrapper,
  .page-sessison-virtual .video-bg-wrapper,
  .page-session-zoom .video-bg-wrapper,
  .page-session .video-bg-wrapper,
  .page-virtual-exhibitor .video-bg-wrapper {
    position: relative; }
  .page-admin-panel .video-bg-wrapper .video-start-buffer-message,
  .page-session-engagement .video-bg-wrapper .video-start-buffer-message,
  .page-session-overview .video-bg-wrapper .video-start-buffer-message,
  .page-sessison-virtual .video-bg-wrapper .video-start-buffer-message,
  .page-session-zoom .video-bg-wrapper .video-start-buffer-message,
  .page-session .video-bg-wrapper .video-start-buffer-message,
  .page-virtual-exhibitor .video-bg-wrapper .video-start-buffer-message {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding: 50px 15px;
    background: rgba(200, 200, 200, 0.98);
    letter-spacing: 2px;
    line-height: 1em border 1px solid #444; }
  .page-admin-panel .video-bg-wrapper .video-start-buffer-message p,
  .page-session-engagement .video-bg-wrapper .video-start-buffer-message p,
  .page-session-overview .video-bg-wrapper .video-start-buffer-message p,
  .page-sessison-virtual .video-bg-wrapper .video-start-buffer-message p,
  .page-session-zoom .video-bg-wrapper .video-start-buffer-message p,
  .page-session .video-bg-wrapper .video-start-buffer-message p,
  .page-virtual-exhibitor .video-bg-wrapper .video-start-buffer-message p {
    color: #555;
    font-size: 20px;
    line-height: 1.5em;
    text-align: center; }
  .page-admin-panel #discussion-filter,
  .page-session-engagement #discussion-filter,
  .page-session-overview #discussion-filter,
  .page-sessison-virtual #discussion-filter,
  .page-session-zoom #discussion-filter,
  .page-session #discussion-filter,
  .page-virtual-exhibitor #discussion-filter {
    padding: 5px; }
  .page-admin-panel .switch,
  .page-session-engagement .switch,
  .page-session-overview .switch,
  .page-sessison-virtual .switch,
  .page-session-zoom .switch,
  .page-session .switch,
  .page-virtual-exhibitor .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px; }
  .page-admin-panel .switch input,
  .page-session-engagement .switch input,
  .page-session-overview .switch input,
  .page-sessison-virtual .switch input,
  .page-session-zoom .switch input,
  .page-session .switch input,
  .page-virtual-exhibitor .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .page-admin-panel .slider,
  .page-session-engagement .slider,
  .page-session-overview .slider,
  .page-sessison-virtual .slider,
  .page-session-zoom .slider,
  .page-session .slider,
  .page-virtual-exhibitor .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .page-admin-panel .slider:before,
  .page-session-engagement .slider:before,
  .page-session-overview .slider:before,
  .page-sessison-virtual .slider:before,
  .page-session-zoom .slider:before,
  .page-session .slider:before,
  .page-virtual-exhibitor .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .page-admin-panel .label-success,
  .page-session-engagement .label-success,
  .page-session-overview .label-success,
  .page-sessison-virtual .label-success,
  .page-session-zoom .label-success,
  .page-session .label-success,
  .page-virtual-exhibitor .label-success {
    background-color: #d92228; }
  .page-admin-panel input:checked + .slider,
  .page-session-engagement input:checked + .slider,
  .page-session-overview input:checked + .slider,
  .page-sessison-virtual input:checked + .slider,
  .page-session-zoom input:checked + .slider,
  .page-session input:checked + .slider,
  .page-virtual-exhibitor input:checked + .slider {
    background-color: #2196f3; }
  .page-admin-panel input:focus + .slider,
  .page-session-engagement input:focus + .slider,
  .page-session-overview input:focus + .slider,
  .page-sessison-virtual input:focus + .slider,
  .page-session-zoom input:focus + .slider,
  .page-session input:focus + .slider,
  .page-virtual-exhibitor input:focus + .slider {
    box-shadow: 0 0 1px #2196f3; }
  .page-admin-panel input:checked + .slider:before,
  .page-session-engagement input:checked + .slider:before,
  .page-session-overview input:checked + .slider:before,
  .page-sessison-virtual input:checked + .slider:before,
  .page-session-zoom input:checked + .slider:before,
  .page-session input:checked + .slider:before,
  .page-virtual-exhibitor input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px); }
  .page-admin-panel .slider.round,
  .page-session-engagement .slider.round,
  .page-session-overview .slider.round,
  .page-sessison-virtual .slider.round,
  .page-session-zoom .slider.round,
  .page-session .slider.round,
  .page-virtual-exhibitor .slider.round {
    border-radius: 34px; }
  .page-admin-panel .slider.round:before,
  .page-session-engagement .slider.round:before,
  .page-session-overview .slider.round:before,
  .page-sessison-virtual .slider.round:before,
  .page-session-zoom .slider.round:before,
  .page-session .slider.round:before,
  .page-virtual-exhibitor .slider.round:before {
    border-radius: 50%; }
  .page-admin-panel .mt-2,
  .page-session-engagement .mt-2,
  .page-session-overview .mt-2,
  .page-sessison-virtual .mt-2,
  .page-session-zoom .mt-2,
  .page-session .mt-2,
  .page-virtual-exhibitor .mt-2 {
    margin-top: 20px; }
  .page-admin-panel #panel-upper-content h3:first-child, .page-admin-panel #panel-upper-content .h3:first-child,
  .page-session-engagement #panel-upper-content h3:first-child,
  .page-session-engagement #panel-upper-content .h3:first-child,
  .page-session-overview #panel-upper-content h3:first-child,
  .page-session-overview #panel-upper-content .h3:first-child,
  .page-sessison-virtual #panel-upper-content h3:first-child,
  .page-sessison-virtual #panel-upper-content .h3:first-child,
  .page-session-zoom #panel-upper-content h3:first-child,
  .page-session-zoom #panel-upper-content .h3:first-child,
  .page-session #panel-upper-content h3:first-child,
  .page-session #panel-upper-content .h3:first-child,
  .page-virtual-exhibitor #panel-upper-content h3:first-child,
  .page-virtual-exhibitor #panel-upper-content .h3:first-child {
    margin-top: 0; }
  .page-admin-panel .like-link.liked i,
  .page-admin-panel .like-link.liked,
  .page-session-engagement .like-link.liked i,
  .page-session-engagement .like-link.liked,
  .page-session-overview .like-link.liked i,
  .page-session-overview .like-link.liked,
  .page-sessison-virtual .like-link.liked i,
  .page-sessison-virtual .like-link.liked,
  .page-session-zoom .like-link.liked i,
  .page-session-zoom .like-link.liked,
  .page-session .like-link.liked i,
  .page-session .like-link.liked,
  .page-virtual-exhibitor .like-link.liked i,
  .page-virtual-exhibitor .like-link.liked {
    color: #003357; }
  .page-admin-panel #post-reply-form button,
  .page-session-engagement #post-reply-form button,
  .page-session-overview #post-reply-form button,
  .page-sessison-virtual #post-reply-form button,
  .page-session-zoom #post-reply-form button,
  .page-session #post-reply-form button,
  .page-virtual-exhibitor #post-reply-form button {
    margin-top: 0; }
  .page-admin-panel h4.sm-thick-title, .page-admin-panel .sm-thick-title.h4,
  .page-session-engagement h4.sm-thick-title,
  .page-session-engagement .sm-thick-title.h4,
  .page-session-overview h4.sm-thick-title,
  .page-session-overview .sm-thick-title.h4,
  .page-sessison-virtual h4.sm-thick-title,
  .page-sessison-virtual .sm-thick-title.h4,
  .page-session-zoom h4.sm-thick-title,
  .page-session-zoom .sm-thick-title.h4,
  .page-session h4.sm-thick-title,
  .page-session .sm-thick-title.h4,
  .page-virtual-exhibitor h4.sm-thick-title,
  .page-virtual-exhibitor .sm-thick-title.h4 {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px; }
  .page-admin-panel h5.sm-thick-title, .page-admin-panel .sm-thick-title.h5,
  .page-session-engagement h5.sm-thick-title,
  .page-session-engagement .sm-thick-title.h5,
  .page-session-overview h5.sm-thick-title,
  .page-session-overview .sm-thick-title.h5,
  .page-sessison-virtual h5.sm-thick-title,
  .page-sessison-virtual .sm-thick-title.h5,
  .page-session-zoom h5.sm-thick-title,
  .page-session-zoom .sm-thick-title.h5,
  .page-session h5.sm-thick-title,
  .page-session .sm-thick-title.h5,
  .page-virtual-exhibitor h5.sm-thick-title,
  .page-virtual-exhibitor .sm-thick-title.h5 {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px; }
  .page-admin-panel .media-heading,
  .page-session-engagement .media-heading,
  .page-session-overview .media-heading,
  .page-sessison-virtual .media-heading,
  .page-session-zoom .media-heading,
  .page-session .media-heading,
  .page-virtual-exhibitor .media-heading {
    font-size: 15px;
    color: #003357;
    font-weight: bold;
    line-height: 18px; }
  .page-admin-panel .media-body,
  .page-session-engagement .media-body,
  .page-session-overview .media-body,
  .page-sessison-virtual .media-body,
  .page-session-zoom .media-body,
  .page-session .media-body,
  .page-virtual-exhibitor .media-body {
    padding-right: 25px !important; }
  .page-admin-panel .media a,
  .page-session-engagement .media a,
  .page-session-overview .media a,
  .page-sessison-virtual .media a,
  .page-session-zoom .media a,
  .page-session .media a,
  .page-virtual-exhibitor .media a {
    display: block;
    width: 70px; }
  .page-admin-panel #session-chat-history,
  .page-session-engagement #session-chat-history,
  .page-session-overview #session-chat-history,
  .page-sessison-virtual #session-chat-history,
  .page-session-zoom #session-chat-history,
  .page-session #session-chat-history,
  .page-virtual-exhibitor #session-chat-history {
    margin-top: 2em;
    padding-right: 5px;
    /* max-height: 400px;
		overflow: hidden;
		overflow-y: auto; */
    position: relative; }
  .page-admin-panel .session-chat-item,
  .page-session-engagement .session-chat-item,
  .page-session-overview .session-chat-item,
  .page-sessison-virtual .session-chat-item,
  .page-session-zoom .session-chat-item,
  .page-session .session-chat-item,
  .page-virtual-exhibitor .session-chat-item {
    position: relative;
    margin: 1em 0; }
  .page-admin-panel .session-chat-item.unapproved,
  .page-session-engagement .session-chat-item.unapproved,
  .page-session-overview .session-chat-item.unapproved,
  .page-sessison-virtual .session-chat-item.unapproved,
  .page-session-zoom .session-chat-item.unapproved,
  .page-session .session-chat-item.unapproved,
  .page-virtual-exhibitor .session-chat-item.unapproved {
    margin: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    border: none;
    display: none; }
  .page-admin-panel .session-chat-item + .session-chat-item,
  .page-session-engagement .session-chat-item + .session-chat-item,
  .page-session-overview .session-chat-item + .session-chat-item,
  .page-sessison-virtual .session-chat-item + .session-chat-item,
  .page-session-zoom .session-chat-item + .session-chat-item,
  .page-session .session-chat-item + .session-chat-item,
  .page-virtual-exhibitor .session-chat-item + .session-chat-item {
    margin-top: 2em; }
  .page-admin-panel .session-chat-item-header .profile-img,
  .page-session-engagement .session-chat-item-header .profile-img,
  .page-session-overview .session-chat-item-header .profile-img,
  .page-sessison-virtual .session-chat-item-header .profile-img,
  .page-session-zoom .session-chat-item-header .profile-img,
  .page-session .session-chat-item-header .profile-img,
  .page-virtual-exhibitor .session-chat-item-header .profile-img {
    width: 3.825em;
    height: 3.825em;
    border-radius: 1.9125em; }
  .page-admin-panel .session-chat-item-header .chat-username,
  .page-session-engagement .session-chat-item-header .chat-username,
  .page-session-overview .session-chat-item-header .chat-username,
  .page-sessison-virtual .session-chat-item-header .chat-username,
  .page-session-zoom .session-chat-item-header .chat-username,
  .page-session .session-chat-item-header .chat-username,
  .page-virtual-exhibitor .session-chat-item-header .chat-username {
    font-weight: 700;
    font-size: 1em;
    line-height: 1em;
    padding: 0 0 0 1em; }
  .page-admin-panel .session-chat-item-header .like-sessionChat,
  .page-session-engagement .session-chat-item-header .like-sessionChat,
  .page-session-overview .session-chat-item-header .like-sessionChat,
  .page-sessison-virtual .session-chat-item-header .like-sessionChat,
  .page-session-zoom .session-chat-item-header .like-sessionChat,
  .page-session .session-chat-item-header .like-sessionChat,
  .page-virtual-exhibitor .session-chat-item-header .like-sessionChat {
    background: transparent;
    margin-left: 5px; }
  .page-admin-panel .session-chat-item-comment,
  .page-session-engagement .session-chat-item-comment,
  .page-session-overview .session-chat-item-comment,
  .page-sessison-virtual .session-chat-item-comment,
  .page-session-zoom .session-chat-item-comment,
  .page-session .session-chat-item-comment,
  .page-virtual-exhibitor .session-chat-item-comment {
    font-size: 1em;
    font-weight: 300;
    padding: 0.25em 0;
    position: relative; }
  .page-admin-panel .session-chat-item-footer:before,
  .page-admin-panel .session-chat-item-footer:after,
  .page-session-engagement .session-chat-item-footer:before,
  .page-session-engagement .session-chat-item-footer:after,
  .page-session-overview .session-chat-item-footer:before,
  .page-session-overview .session-chat-item-footer:after,
  .page-sessison-virtual .session-chat-item-footer:before,
  .page-sessison-virtual .session-chat-item-footer:after,
  .page-session-zoom .session-chat-item-footer:before,
  .page-session-zoom .session-chat-item-footer:after,
  .page-session .session-chat-item-footer:before,
  .page-session .session-chat-item-footer:after,
  .page-virtual-exhibitor .session-chat-item-footer:before,
  .page-virtual-exhibitor .session-chat-item-footer:after {
    content: "";
    display: table; }
  .page-admin-panel .session-chat-item-footer:after,
  .page-session-engagement .session-chat-item-footer:after,
  .page-session-overview .session-chat-item-footer:after,
  .page-sessison-virtual .session-chat-item-footer:after,
  .page-session-zoom .session-chat-item-footer:after,
  .page-session .session-chat-item-footer:after,
  .page-virtual-exhibitor .session-chat-item-footer:after {
    clear: both; }
  .page-admin-panel .form-chat-reply,
  .page-session-engagement .form-chat-reply,
  .page-session-overview .form-chat-reply,
  .page-sessison-virtual .form-chat-reply,
  .page-session-zoom .form-chat-reply,
  .page-session .form-chat-reply,
  .page-virtual-exhibitor .form-chat-reply {
    position: absolute;
    left: 50px;
    right: 0;
    background: rgba(238, 238, 238, 0.1);
    padding: 1em;
    border-radius: 4px;
    margin: 0 0 2em 0; }
  .page-admin-panel .session-chat-item.has-replies:before,
  .page-session-engagement .session-chat-item.has-replies:before,
  .page-session-overview .session-chat-item.has-replies:before,
  .page-sessison-virtual .session-chat-item.has-replies:before,
  .page-session-zoom .session-chat-item.has-replies:before,
  .page-session .session-chat-item.has-replies:before,
  .page-virtual-exhibitor .session-chat-item.has-replies:before {
    content: "";
    position: absolute;
    display: block;
    width: 2px;
    top: 0;
    bottom: 0;
    left: 30px;
    background: #eee;
    margin-left: -1px; }
  .page-admin-panel .condensed .session-chat-item.has-replies:before,
  .page-session-engagement .condensed .session-chat-item.has-replies:before,
  .page-session-overview .condensed .session-chat-item.has-replies:before,
  .page-sessison-virtual .condensed .session-chat-item.has-replies:before,
  .page-session-zoom .condensed .session-chat-item.has-replies:before,
  .page-session .condensed .session-chat-item.has-replies:before,
  .page-virtual-exhibitor .condensed .session-chat-item.has-replies:before {
    left: 17px; }
  .page-admin-panel .poll-sidebar-wrapper,
  .page-session-engagement .poll-sidebar-wrapper,
  .page-session-overview .poll-sidebar-wrapper,
  .page-sessison-virtual .poll-sidebar-wrapper,
  .page-session-zoom .poll-sidebar-wrapper,
  .page-session .poll-sidebar-wrapper,
  .page-virtual-exhibitor .poll-sidebar-wrapper {
    position: relative;
    background: #eee; }
  .page-admin-panel .poll-sidebar-wrapper .question-header legend,
  .page-session-engagement .poll-sidebar-wrapper .question-header legend,
  .page-session-overview .poll-sidebar-wrapper .question-header legend,
  .page-sessison-virtual .poll-sidebar-wrapper .question-header legend,
  .page-session-zoom .poll-sidebar-wrapper .question-header legend,
  .page-session .poll-sidebar-wrapper .question-header legend,
  .page-virtual-exhibitor .poll-sidebar-wrapper .question-header legend {
    font-size: 1.3em; }
  .page-admin-panel .poll-sidebar-wrapper .poll-nav .btn,
  .page-session-engagement .poll-sidebar-wrapper .poll-nav .btn,
  .page-session-overview .poll-sidebar-wrapper .poll-nav .btn,
  .page-sessison-virtual .poll-sidebar-wrapper .poll-nav .btn,
  .page-session-zoom .poll-sidebar-wrapper .poll-nav .btn,
  .page-session .poll-sidebar-wrapper .poll-nav .btn,
  .page-virtual-exhibitor .poll-sidebar-wrapper .poll-nav .btn {
    font-size: 13px;
    letter-spacing: 0; }
  .page-admin-panel .list-group-item .disabled,
  .page-session-engagement .list-group-item .disabled,
  .page-session-overview .list-group-item .disabled,
  .page-sessison-virtual .list-group-item .disabled,
  .page-session-zoom .list-group-item .disabled,
  .page-session .list-group-item .disabled,
  .page-virtual-exhibitor .list-group-item .disabled {
    opacity: 0.5;
    background: #ddd;
    cursor: not-allowed;
    pointer-events: none; }
  .page-admin-panel .poll-sidebar-content .answer-list,
  .page-session-engagement .poll-sidebar-content .answer-list,
  .page-session-overview .poll-sidebar-content .answer-list,
  .page-sessison-virtual .poll-sidebar-content .answer-list,
  .page-session-zoom .poll-sidebar-content .answer-list,
  .page-session .poll-sidebar-content .answer-list,
  .page-virtual-exhibitor .poll-sidebar-content .answer-list {
    background: #fff; }
  .page-admin-panel #sidebar-panel-overlay,
  .page-session-engagement #sidebar-panel-overlay,
  .page-session-overview #sidebar-panel-overlay,
  .page-sessison-virtual #sidebar-panel-overlay,
  .page-session-zoom #sidebar-panel-overlay,
  .page-session #sidebar-panel-overlay,
  .page-virtual-exhibitor #sidebar-panel-overlay {
    position: absolute;
    z-index: 5000;
    background: #fff;
    left: 105%;
    right: 0;
    bottom: 0;
    top: 0;
    overflow-y: auto;
    width: 100%;
    pointer-events: none; }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 51, 87, 0.5); }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0); } }

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 51, 87, 0.5); }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0); } }
  .page-admin-panel .pulsing,
  .page-session-engagement .pulsing,
  .page-session-overview .pulsing,
  .page-sessison-virtual .pulsing,
  .page-session-zoom .pulsing,
  .page-session .pulsing,
  .page-virtual-exhibitor .pulsing {
    animation: pulse 0.5s infinite;
    -webkit-animation: pulse 0.5s infinite; }
  .page-admin-panel #video-container,
  .page-session-engagement #video-container,
  .page-session-overview #video-container,
  .page-sessison-virtual #video-container,
  .page-session-zoom #video-container,
  .page-session #video-container,
  .page-virtual-exhibitor #video-container {
    position: relative; }
  .page-admin-panel #virtual-main-content-container,
  .page-session-engagement #virtual-main-content-container,
  .page-session-overview #virtual-main-content-container,
  .page-sessison-virtual #virtual-main-content-container,
  .page-session-zoom #virtual-main-content-container,
  .page-session #virtual-main-content-container,
  .page-virtual-exhibitor #virtual-main-content-container {
    position: relative; }
  .page-admin-panel #interstitial-image,
  .page-session-engagement #interstitial-image,
  .page-session-overview #interstitial-image,
  .page-sessison-virtual #interstitial-image,
  .page-session-zoom #interstitial-image,
  .page-session #interstitial-image,
  .page-virtual-exhibitor #interstitial-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px); }
  .page-admin-panel #interstitial-image img,
  .page-session-engagement #interstitial-image img,
  .page-session-overview #interstitial-image img,
  .page-sessison-virtual #interstitial-image img,
  .page-session-zoom #interstitial-image img,
  .page-session #interstitial-image img,
  .page-virtual-exhibitor #interstitial-image img {
    display: block;
    margin: 20px auto;
    max-width: 800px; }
  .page-admin-panel #interstitial-image:focus,
  .page-session-engagement #interstitial-image:focus,
  .page-session-overview #interstitial-image:focus,
  .page-sessison-virtual #interstitial-image:focus,
  .page-session-zoom #interstitial-image:focus,
  .page-session #interstitial-image:focus,
  .page-virtual-exhibitor #interstitial-image:focus {
    outline: none; }
  .page-admin-panel #video-continue,
  .page-session-engagement #video-continue,
  .page-session-overview #video-continue,
  .page-sessison-virtual #video-continue,
  .page-session-zoom #video-continue,
  .page-session #video-continue,
  .page-virtual-exhibitor #video-continue {
    background: #000;
    border-color: #000;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-transform: uppercase; }
  .page-admin-panel .rslides,
  .page-session-engagement .rslides,
  .page-session-overview .rslides,
  .page-sessison-virtual .rslides,
  .page-session-zoom .rslides,
  .page-session .rslides,
  .page-virtual-exhibitor .rslides {
    position: relative;
    list-style: none;
    overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 0; }
  .page-admin-panel .rslides li,
  .page-session-engagement .rslides li,
  .page-session-overview .rslides li,
  .page-sessison-virtual .rslides li,
  .page-session-zoom .rslides li,
  .page-session .rslides li,
  .page-virtual-exhibitor .rslides li {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    position: absolute;
    display: none;
    width: 100%;
    left: 0;
    top: 0; }
  .page-admin-panel .rslides li:first-child,
  .page-session-engagement .rslides li:first-child,
  .page-session-overview .rslides li:first-child,
  .page-sessison-virtual .rslides li:first-child,
  .page-session-zoom .rslides li:first-child,
  .page-session .rslides li:first-child,
  .page-virtual-exhibitor .rslides li:first-child {
    position: relative;
    display: block;
    float: left; }
  .page-admin-panel .rslides img,
  .page-session-engagement .rslides img,
  .page-session-overview .rslides img,
  .page-sessison-virtual .rslides img,
  .page-session-zoom .rslides img,
  .page-session .rslides img,
  .page-virtual-exhibitor .rslides img {
    display: block;
    height: auto;
    float: left;
    width: 100%;
    border: 0; }
  .page-admin-panel .embed-responsive-16by9,
  .page-session-engagement .embed-responsive-16by9,
  .page-session-overview .embed-responsive-16by9,
  .page-sessison-virtual .embed-responsive-16by9,
  .page-session-zoom .embed-responsive-16by9,
  .page-session .embed-responsive-16by9,
  .page-virtual-exhibitor .embed-responsive-16by9 {
    padding-bottom: 56.25%; }
  .page-admin-panel #virtual-content-column .embed-responsive,
  .page-session-engagement #virtual-content-column .embed-responsive,
  .page-session-overview #virtual-content-column .embed-responsive,
  .page-sessison-virtual #virtual-content-column .embed-responsive,
  .page-session-zoom #virtual-content-column .embed-responsive,
  .page-session #virtual-content-column .embed-responsive,
  .page-virtual-exhibitor #virtual-content-column .embed-responsive {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden; }
  .page-admin-panel .embed-responsive .embed-responsive-item,
  .page-admin-panel .embed-responsive embed,
  .page-admin-panel .embed-responsive iframe,
  .page-admin-panel .embed-responsive object,
  .page-admin-panel .embed-responsive video,
  .page-session-engagement .embed-responsive .embed-responsive-item,
  .page-session-engagement .embed-responsive embed,
  .page-session-engagement .embed-responsive iframe,
  .page-session-engagement .embed-responsive object,
  .page-session-engagement .embed-responsive video,
  .page-session-overview .embed-responsive .embed-responsive-item,
  .page-session-overview .embed-responsive embed,
  .page-session-overview .embed-responsive iframe,
  .page-session-overview .embed-responsive object,
  .page-session-overview .embed-responsive video,
  .page-sessison-virtual .embed-responsive .embed-responsive-item,
  .page-sessison-virtual .embed-responsive embed,
  .page-sessison-virtual .embed-responsive iframe,
  .page-sessison-virtual .embed-responsive object,
  .page-sessison-virtual .embed-responsive video,
  .page-session-zoom .embed-responsive .embed-responsive-item,
  .page-session-zoom .embed-responsive embed,
  .page-session-zoom .embed-responsive iframe,
  .page-session-zoom .embed-responsive object,
  .page-session-zoom .embed-responsive video,
  .page-session .embed-responsive .embed-responsive-item,
  .page-session .embed-responsive embed,
  .page-session .embed-responsive iframe,
  .page-session .embed-responsive object,
  .page-session .embed-responsive video,
  .page-virtual-exhibitor .embed-responsive .embed-responsive-item,
  .page-virtual-exhibitor .embed-responsive embed,
  .page-virtual-exhibitor .embed-responsive iframe,
  .page-virtual-exhibitor .embed-responsive object,
  .page-virtual-exhibitor .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }
  .page-admin-panel #toggle-speaker,
  .page-session-engagement #toggle-speaker,
  .page-session-overview #toggle-speaker,
  .page-sessison-virtual #toggle-speaker,
  .page-session-zoom #toggle-speaker,
  .page-session #toggle-speaker,
  .page-virtual-exhibitor #toggle-speaker {
    margin-bottom: 2px; }
  .page-admin-panel #sidebar-panel .live-feed-container,
  .page-session-engagement #sidebar-panel .live-feed-container,
  .page-session-overview #sidebar-panel .live-feed-container,
  .page-sessison-virtual #sidebar-panel .live-feed-container,
  .page-session-zoom #sidebar-panel .live-feed-container,
  .page-session #sidebar-panel .live-feed-container,
  .page-virtual-exhibitor #sidebar-panel .live-feed-container {
    margin-bottom: 0; }
  .page-admin-panel .live-feed-title .title,
  .page-session-engagement .live-feed-title .title,
  .page-session-overview .live-feed-title .title,
  .page-sessison-virtual .live-feed-title .title,
  .page-session-zoom .live-feed-title .title,
  .page-session .live-feed-title .title,
  .page-virtual-exhibitor .live-feed-title .title {
    font-size: 20px; }
  .page-admin-panel .video-status.no-video,
  .page-session-engagement .video-status.no-video,
  .page-session-overview .video-status.no-video,
  .page-sessison-virtual .video-status.no-video,
  .page-session-zoom .video-status.no-video,
  .page-session .video-status.no-video,
  .page-virtual-exhibitor .video-status.no-video {
    position: absolute;
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
    background: #ccc;
    border-radius: 5%;
    color: #000; }
  .page-admin-panel .video-status.no-video span,
  .page-session-engagement .video-status.no-video span,
  .page-session-overview .video-status.no-video span,
  .page-sessison-virtual .video-status.no-video span,
  .page-session-zoom .video-status.no-video span,
  .page-session .video-status.no-video span,
  .page-virtual-exhibitor .video-status.no-video span {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    text-align: center;
    font-size: 10em;
    margin: -0.5em;
    color: #666;
    text-shadow: 1px 1px #eee; }
  .page-admin-panel .presenter-feed .video-status.no-video,
  .page-admin-panel #liveFeed-presenter .video-status.no-video,
  .page-session-engagement .presenter-feed .video-status.no-video,
  .page-session-engagement #liveFeed-presenter .video-status.no-video,
  .page-session-overview .presenter-feed .video-status.no-video,
  .page-session-overview #liveFeed-presenter .video-status.no-video,
  .page-sessison-virtual .presenter-feed .video-status.no-video,
  .page-sessison-virtual #liveFeed-presenter .video-status.no-video,
  .page-session-zoom .presenter-feed .video-status.no-video,
  .page-session-zoom #liveFeed-presenter .video-status.no-video,
  .page-session .presenter-feed .video-status.no-video,
  .page-session #liveFeed-presenter .video-status.no-video,
  .page-virtual-exhibitor .presenter-feed .video-status.no-video,
  .page-virtual-exhibitor #liveFeed-presenter .video-status.no-video {
    font-size: 0.5em; }
  .page-admin-panel .outer-modal.show .modal-dialog,
  .page-session-engagement .outer-modal.show .modal-dialog,
  .page-session-overview .outer-modal.show .modal-dialog,
  .page-sessison-virtual .outer-modal.show .modal-dialog,
  .page-session-zoom .outer-modal.show .modal-dialog,
  .page-session .outer-modal.show .modal-dialog,
  .page-virtual-exhibitor .outer-modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  .page-admin-panel .outer-modal.fade .modal-dialog,
  .page-session-engagement .outer-modal.fade .modal-dialog,
  .page-session-overview .outer-modal.fade .modal-dialog,
  .page-sessison-virtual .outer-modal.fade .modal-dialog,
  .page-session-zoom .outer-modal.fade .modal-dialog,
  .page-session .outer-modal.fade .modal-dialog,
  .page-virtual-exhibitor .outer-modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%); }
  .page-admin-panel .outer-modal,
  .page-session-engagement .outer-modal,
  .page-session-overview .outer-modal,
  .page-sessison-virtual .outer-modal,
  .page-session-zoom .outer-modal,
  .page-session .outer-modal,
  .page-virtual-exhibitor .outer-modal {
    background: #fff;
    border: 1px solid #dadada;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }
  .page-admin-panel .modal-close,
  .page-session-engagement .modal-close,
  .page-session-overview .modal-close,
  .page-sessison-virtual .modal-close,
  .page-session-zoom .modal-close,
  .page-session .modal-close,
  .page-virtual-exhibitor .modal-close {
    font-size: 26px;
    width: 100%;
    text-align: right;
    text-decoration: none !important;
    color: #000;
    position: relative;
    height: auto;
    display: block; }
  .page-admin-panel h3.modal-title, .page-admin-panel .modal-title.h3,
  .page-session-engagement h3.modal-title,
  .page-session-engagement .modal-title.h3,
  .page-session-overview h3.modal-title,
  .page-session-overview .modal-title.h3,
  .page-sessison-virtual h3.modal-title,
  .page-sessison-virtual .modal-title.h3,
  .page-session-zoom h3.modal-title,
  .page-session-zoom .modal-title.h3,
  .page-session h3.modal-title,
  .page-session .modal-title.h3,
  .page-virtual-exhibitor h3.modal-title,
  .page-virtual-exhibitor .modal-title.h3 {
    margin-top: -40px; }
  @media (max-width: 750px) {
    .page-admin-panel #virtual-main-content,
    .page-session-engagement #virtual-main-content,
    .page-session-overview #virtual-main-content,
    .page-sessison-virtual #virtual-main-content,
    .page-session-zoom #virtual-main-content,
    .page-session #virtual-main-content,
    .page-virtual-exhibitor #virtual-main-content {
      width: 100%;
      padding-right: 0; }
    .page-admin-panel #page-session.desktop #session-action-btns,
    .page-session-engagement #page-session.desktop #session-action-btns,
    .page-session-overview #page-session.desktop #session-action-btns,
    .page-sessison-virtual #page-session.desktop #session-action-btns,
    .page-session-zoom #page-session.desktop #session-action-btns,
    .page-session #page-session.desktop #session-action-btns,
    .page-virtual-exhibitor #page-session.desktop #session-action-btns {
      margin-right: 0;
      margin-top: 0; }
    .page-admin-panel #session-action-btns a,
    .page-session-engagement #session-action-btns a,
    .page-session-overview #session-action-btns a,
    .page-sessison-virtual #session-action-btns a,
    .page-session-zoom #session-action-btns a,
    .page-session #session-action-btns a,
    .page-virtual-exhibitor #session-action-btns a {
      width: auto; }
    .page-admin-panel #page-session.desktop .page-header h3, .page-admin-panel #page-session.desktop .page-header .h3,
    .page-session-engagement #page-session.desktop .page-header h3,
    .page-session-engagement #page-session.desktop .page-header .h3,
    .page-session-overview #page-session.desktop .page-header h3,
    .page-session-overview #page-session.desktop .page-header .h3,
    .page-sessison-virtual #page-session.desktop .page-header h3,
    .page-sessison-virtual #page-session.desktop .page-header .h3,
    .page-session-zoom #page-session.desktop .page-header h3,
    .page-session-zoom #page-session.desktop .page-header .h3,
    .page-session #page-session.desktop .page-header h3,
    .page-session #page-session.desktop .page-header .h3,
    .page-virtual-exhibitor #page-session.desktop .page-header h3,
    .page-virtual-exhibitor #page-session.desktop .page-header .h3 {
      padding-right: 15px; }
    .page-admin-panel #virtual-main-content,
    .page-session-engagement #virtual-main-content,
    .page-session-overview #virtual-main-content,
    .page-sessison-virtual #virtual-main-content,
    .page-session-zoom #virtual-main-content,
    .page-session #virtual-main-content,
    .page-virtual-exhibitor #virtual-main-content {
      height: auto !important;
      top: 0 !important; } }
  .page-admin-panel .response-container,
  .page-session-engagement .response-container,
  .page-session-overview .response-container,
  .page-sessison-virtual .response-container,
  .page-session-zoom .response-container,
  .page-session .response-container,
  .page-virtual-exhibitor .response-container {
    padding: 10px; }
  .page-admin-panel .admin-responses,
  .page-session-engagement .admin-responses,
  .page-session-overview .admin-responses,
  .page-sessison-virtual .admin-responses,
  .page-session-zoom .admin-responses,
  .page-session .admin-responses,
  .page-virtual-exhibitor .admin-responses {
    margin-top: 5px;
    padding: 10px;
    background: #eee; }
  .page-admin-panel .admin-responses p,
  .page-session-engagement .admin-responses p,
  .page-session-overview .admin-responses p,
  .page-sessison-virtual .admin-responses p,
  .page-session-zoom .admin-responses p,
  .page-session .admin-responses p,
  .page-virtual-exhibitor .admin-responses p {
    margin: 0 0 10px 0;
    font-size: 12px;
    line-height: 18px; }
  .page-admin-panel .admin-responses,
  .page-session-engagement .admin-responses,
  .page-session-overview .admin-responses,
  .page-sessison-virtual .admin-responses,
  .page-session-zoom .admin-responses,
  .page-session .admin-responses,
  .page-virtual-exhibitor .admin-responses {
    display: none; }
  .page-admin-panel #responses-content p,
  .page-session-engagement #responses-content p,
  .page-session-overview #responses-content p,
  .page-sessison-virtual #responses-content p,
  .page-session-zoom #responses-content p,
  .page-session #responses-content p,
  .page-virtual-exhibitor #responses-content p {
    margin: 0 0 0 0;
    padding: 15px 0;
    font-size: 14px;
    line-height: 20px; }
  .page-admin-panel #live-questions,
  .page-session-engagement #live-questions,
  .page-session-overview #live-questions,
  .page-sessison-virtual #live-questions,
  .page-session-zoom #live-questions,
  .page-session #live-questions,
  .page-virtual-exhibitor #live-questions {
    margin: 0;
    padding: 1px 0;
    background: transparent; }
  .page-admin-panel #live-questions .question,
  .page-session-engagement #live-questions .question,
  .page-session-overview #live-questions .question,
  .page-sessison-virtual #live-questions .question,
  .page-session-zoom #live-questions .question,
  .page-session #live-questions .question,
  .page-virtual-exhibitor #live-questions .question {
    margin: 0;
    padding: 15px 10px 5px;
    border: none;
    position: relative;
    margin-bottom: 20px;
    background: transparent;
    box-shadow: none; }
  .page-admin-panel #live-questions .question + .question,
  .page-session-engagement #live-questions .question + .question,
  .page-session-overview #live-questions .question + .question,
  .page-sessison-virtual #live-questions .question + .question,
  .page-session-zoom #live-questions .question + .question,
  .page-session #live-questions .question + .question,
  .page-virtual-exhibitor #live-questions .question + .question {
    border-top: 1px dashed rgba(0, 0, 0, 0.2);
    margin-top: 20px; }
  .page-admin-panel #live-questions .question-actions,
  .page-session-engagement #live-questions .question-actions,
  .page-session-overview #live-questions .question-actions,
  .page-sessison-virtual #live-questions .question-actions,
  .page-session-zoom #live-questions .question-actions,
  .page-session #live-questions .question-actions,
  .page-virtual-exhibitor #live-questions .question-actions {
    text-align: center;
    font-weight: bold;
    position: relative;
    overflow: hidden;
    width: 35%;
    padding: 3px 0;
    float: right;
    margin-top: 0; }
  .page-admin-panel .votes-count,
  .page-session-engagement .votes-count,
  .page-session-overview .votes-count,
  .page-sessison-virtual .votes-count,
  .page-session-zoom .votes-count,
  .page-session .votes-count,
  .page-virtual-exhibitor .votes-count {
    display: block;
    margin-top: 8px; }
  .page-admin-panel #live-questions .responses,
  .page-session-engagement #live-questions .responses,
  .page-session-overview #live-questions .responses,
  .page-sessison-virtual #live-questions .responses,
  .page-session-zoom #live-questions .responses,
  .page-session #live-questions .responses,
  .page-virtual-exhibitor #live-questions .responses {
    padding: 3px 15px 0;
    overflow: hidden;
    background: #f5f5f5;
    border-radius: 8px;
    color: #202020;
    height: 30px; }
  .page-admin-panel #live-questions .responses.toggled,
  .page-session-engagement #live-questions .responses.toggled,
  .page-session-overview #live-questions .responses.toggled,
  .page-sessison-virtual #live-questions .responses.toggled,
  .page-session-zoom #live-questions .responses.toggled,
  .page-session #live-questions .responses.toggled,
  .page-virtual-exhibitor #live-questions .responses.toggled {
    height: auto; }
  .page-admin-panel #live-questions .responses .response-toggle,
  .page-session-engagement #live-questions .responses .response-toggle,
  .page-session-overview #live-questions .responses .response-toggle,
  .page-sessison-virtual #live-questions .responses .response-toggle,
  .page-session-zoom #live-questions .responses .response-toggle,
  .page-session #live-questions .responses .response-toggle,
  .page-virtual-exhibitor #live-questions .responses .response-toggle {
    height: 30px;
    background: transparent;
    color: #202020;
    display: block;
    text-align: center; }
  .page-admin-panel #live-questions .response-item,
  .page-session-engagement #live-questions .response-item,
  .page-session-overview #live-questions .response-item,
  .page-sessison-virtual #live-questions .response-item,
  .page-session-zoom #live-questions .response-item,
  .page-session #live-questions .response-item,
  .page-virtual-exhibitor #live-questions .response-item {
    padding: 10px 0; }
  .page-admin-panel #live-questions .responses .response-toggle:hover,
  .page-admin-panel #live-questions .responses .response-toggle:focus,
  .page-admin-panel #live-questions .responses .response-toggle:active,
  .page-session-engagement #live-questions .responses .response-toggle:hover,
  .page-session-engagement #live-questions .responses .response-toggle:focus,
  .page-session-engagement #live-questions .responses .response-toggle:active,
  .page-session-overview #live-questions .responses .response-toggle:hover,
  .page-session-overview #live-questions .responses .response-toggle:focus,
  .page-session-overview #live-questions .responses .response-toggle:active,
  .page-sessison-virtual #live-questions .responses .response-toggle:hover,
  .page-sessison-virtual #live-questions .responses .response-toggle:focus,
  .page-sessison-virtual #live-questions .responses .response-toggle:active,
  .page-session-zoom #live-questions .responses .response-toggle:hover,
  .page-session-zoom #live-questions .responses .response-toggle:focus,
  .page-session-zoom #live-questions .responses .response-toggle:active,
  .page-session #live-questions .responses .response-toggle:hover,
  .page-session #live-questions .responses .response-toggle:focus,
  .page-session #live-questions .responses .response-toggle:active,
  .page-virtual-exhibitor #live-questions .responses .response-toggle:hover,
  .page-virtual-exhibitor #live-questions .responses .response-toggle:focus,
  .page-virtual-exhibitor #live-questions .responses .response-toggle:active {
    text-decoration: none;
    background: transparent; }
  .page-admin-panel #live-questions .response-item + .response-item,
  .page-session-engagement #live-questions .response-item + .response-item,
  .page-session-overview #live-questions .response-item + .response-item,
  .page-sessison-virtual #live-questions .response-item + .response-item,
  .page-session-zoom #live-questions .response-item + .response-item,
  .page-session #live-questions .response-item + .response-item,
  .page-virtual-exhibitor #live-questions .response-item + .response-item {
    margin-top: 20px; }
  .page-admin-panel #live-questions .responses .response-toggle .fa,
  .page-session-engagement #live-questions .responses .response-toggle .fa,
  .page-session-overview #live-questions .responses .response-toggle .fa,
  .page-sessison-virtual #live-questions .responses .response-toggle .fa,
  .page-session-zoom #live-questions .responses .response-toggle .fa,
  .page-session #live-questions .responses .response-toggle .fa,
  .page-virtual-exhibitor #live-questions .responses .response-toggle .fa {
    margin-right: 5px; }
  .page-admin-panel #live-questions .response-item .header,
  .page-session-engagement #live-questions .response-item .header,
  .page-session-overview #live-questions .response-item .header,
  .page-sessison-virtual #live-questions .response-item .header,
  .page-session-zoom #live-questions .response-item .header,
  .page-session #live-questions .response-item .header,
  .page-virtual-exhibitor #live-questions .response-item .header {
    padding: 0;
    line-height: 15px; }
  .page-admin-panel #live-questions .response-item .question-content,
  .page-session-engagement #live-questions .response-item .question-content,
  .page-session-overview #live-questions .response-item .question-content,
  .page-sessison-virtual #live-questions .response-item .question-content,
  .page-session-zoom #live-questions .response-item .question-content,
  .page-session #live-questions .response-item .question-content,
  .page-virtual-exhibitor #live-questions .response-item .question-content {
    font-size: 1.15em; }
  .page-admin-panel #toggle-chat-size,
  .page-session-engagement #toggle-chat-size,
  .page-session-overview #toggle-chat-size,
  .page-sessison-virtual #toggle-chat-size,
  .page-session-zoom #toggle-chat-size,
  .page-session #toggle-chat-size,
  .page-virtual-exhibitor #toggle-chat-size {
    margin-right: 10px;
    color: #000;
    display: inline-block;
    padding: 3px 8px;
    border-radius: 3px; }
  .page-admin-panel #toggle-chat-size:hover,
  .page-session-engagement #toggle-chat-size:hover,
  .page-session-overview #toggle-chat-size:hover,
  .page-sessison-virtual #toggle-chat-size:hover,
  .page-session-zoom #toggle-chat-size:hover,
  .page-session #toggle-chat-size:hover,
  .page-virtual-exhibitor #toggle-chat-size:hover {
    background: #ddd; }
  .page-admin-panel .video-bg-wrapper.small,
  .page-session-engagement .video-bg-wrapper.small,
  .page-session-overview .video-bg-wrapper.small,
  .page-sessison-virtual .video-bg-wrapper.small,
  .page-session-zoom .video-bg-wrapper.small,
  .page-session .video-bg-wrapper.small,
  .page-virtual-exhibitor .video-bg-wrapper.small {
    height: 200px; }
  .page-admin-panel .embed-responsive.small,
  .page-session-engagement .embed-responsive.small,
  .page-session-overview .embed-responsive.small,
  .page-sessison-virtual .embed-responsive.small,
  .page-session-zoom .embed-responsive.small,
  .page-session .embed-responsive.small,
  .page-virtual-exhibitor .embed-responsive.small {
    padding-bottom: 0;
    height: 200px; }
  .page-admin-panel #session-chat-history.large,
  .page-admin-panel #session-comments-wrapper.large,
  .page-session-engagement #session-chat-history.large,
  .page-session-engagement #session-comments-wrapper.large,
  .page-session-overview #session-chat-history.large,
  .page-session-overview #session-comments-wrapper.large,
  .page-sessison-virtual #session-chat-history.large,
  .page-sessison-virtual #session-comments-wrapper.large,
  .page-session-zoom #session-chat-history.large,
  .page-session-zoom #session-comments-wrapper.large,
  .page-session #session-chat-history.large,
  .page-session #session-comments-wrapper.large,
  .page-virtual-exhibitor #session-chat-history.large,
  .page-virtual-exhibitor #session-comments-wrapper.large {
    height: 400px;
    height: 50vh; }
  .page-admin-panel #presenter-video,
  .page-session-engagement #presenter-video,
  .page-session-overview #presenter-video,
  .page-sessison-virtual #presenter-video,
  .page-session-zoom #presenter-video,
  .page-session #presenter-video,
  .page-virtual-exhibitor #presenter-video {
    position: relative; }
  .page-admin-panel #presenter-video.not-live,
  .page-session-engagement #presenter-video.not-live,
  .page-session-overview #presenter-video.not-live,
  .page-sessison-virtual #presenter-video.not-live,
  .page-session-zoom #presenter-video.not-live,
  .page-session #presenter-video.not-live,
  .page-virtual-exhibitor #presenter-video.not-live {
    background: url(https://hbint20.mpeventapps.com/includes/images/virtual/presenter-away.jpg) no-repeat center center;
    background-size: cover;
    height: 1px !important; }
  .page-admin-panel #presenter-video.live,
  .page-session-engagement #presenter-video.live,
  .page-session-overview #presenter-video.live,
  .page-sessison-virtual #presenter-video.live,
  .page-session-zoom #presenter-video.live,
  .page-session #presenter-video.live,
  .page-virtual-exhibitor #presenter-video.live {
    cursor: pointer;
    background: url(https://hbint20.mpeventapps.com/includes/images/virtual/presenter-live.jpg) no-repeat center center;
    background-size: cover;
    height: 200px !important; }
  .page-admin-panel #presenter-video.live.isPresenter,
  .page-session-engagement #presenter-video.live.isPresenter,
  .page-session-overview #presenter-video.live.isPresenter,
  .page-sessison-virtual #presenter-video.live.isPresenter,
  .page-session-zoom #presenter-video.live.isPresenter,
  .page-session #presenter-video.live.isPresenter,
  .page-virtual-exhibitor #presenter-video.live.isPresenter {
    background: #000; }
  .page-admin-panel p#loading-message,
  .page-session-engagement p#loading-message,
  .page-session-overview p#loading-message,
  .page-sessison-virtual p#loading-message,
  .page-session-zoom p#loading-message,
  .page-session p#loading-message,
  .page-virtual-exhibitor p#loading-message {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 9999;
    font-family: sans-serif;
    padding: 5px;
    color: #000;
    font-weight: bold;
    text-align: center; }
  .page-admin-panel #btn-go-live,
  .page-session-engagement #btn-go-live,
  .page-session-overview #btn-go-live,
  .page-sessison-virtual #btn-go-live,
  .page-session-zoom #btn-go-live,
  .page-session #btn-go-live,
  .page-virtual-exhibitor #btn-go-live {
    border-radius: 0 0 0 0;
    -webkit-border-radius: 0 0 0 0; }
  .page-admin-panel #availability-controls,
  .page-session-engagement #availability-controls,
  .page-session-overview #availability-controls,
  .page-sessison-virtual #availability-controls,
  .page-session-zoom #availability-controls,
  .page-session #availability-controls,
  .page-virtual-exhibitor #availability-controls {
    padding: 5px; }
  .page-admin-panel #availability-controls select,
  .page-session-engagement #availability-controls select,
  .page-session-overview #availability-controls select,
  .page-sessison-virtual #availability-controls select,
  .page-session-zoom #availability-controls select,
  .page-session #availability-controls select,
  .page-virtual-exhibitor #availability-controls select {
    margin-top: 5px; }
  .page-admin-panel #audio-bar,
  .page-session-engagement #audio-bar,
  .page-session-overview #audio-bar,
  .page-sessison-virtual #audio-bar,
  .page-session-zoom #audio-bar,
  .page-session #audio-bar,
  .page-virtual-exhibitor #audio-bar {
    height: 10px;
    background: #e4e4e4;
    margin-top: 5px; }
  .page-admin-panel #audio-bar-inner,
  .page-session-engagement #audio-bar-inner,
  .page-session-overview #audio-bar-inner,
  .page-sessison-virtual #audio-bar-inner,
  .page-session-zoom #audio-bar-inner,
  .page-session #audio-bar-inner,
  .page-virtual-exhibitor #audio-bar-inner {
    height: 10px;
    background: #28e828;
    width: 0; }
  .page-admin-panel .help-tutorial,
  .page-session-engagement .help-tutorial,
  .page-session-overview .help-tutorial,
  .page-sessison-virtual .help-tutorial,
  .page-session-zoom .help-tutorial,
  .page-session .help-tutorial,
  .page-virtual-exhibitor .help-tutorial {
    padding: 5px 15px;
    text-align: center;
    color: #666;
    font-size: 24px; }
  .page-admin-panel .help-tutorial:hover,
  .page-session-engagement .help-tutorial:hover,
  .page-session-overview .help-tutorial:hover,
  .page-sessison-virtual .help-tutorial:hover,
  .page-session-zoom .help-tutorial:hover,
  .page-session .help-tutorial:hover,
  .page-virtual-exhibitor .help-tutorial:hover {
    color: #666;
    text-decoration: none; }
  .page-admin-panel #live-questions .question.admin-enabled,
  .page-session-engagement #live-questions .question.admin-enabled,
  .page-session-overview #live-questions .question.admin-enabled,
  .page-sessison-virtual #live-questions .question.admin-enabled,
  .page-session-zoom #live-questions .question.admin-enabled,
  .page-session #live-questions .question.admin-enabled,
  .page-virtual-exhibitor #live-questions .question.admin-enabled {
    margin-bottom: 120px; }
  .page-admin-panel #live-questions .action-panel,
  .page-session-engagement #live-questions .action-panel,
  .page-session-overview #live-questions .action-panel,
  .page-sessison-virtual #live-questions .action-panel,
  .page-session-zoom #live-questions .action-panel,
  .page-session #live-questions .action-panel,
  .page-virtual-exhibitor #live-questions .action-panel {
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    padding: 15px 0;
    margin: 20px 0 0 0;
    background: #eee;
    border-top: 1px solid #ccc;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05); }
  .page-admin-panel #video-control-options,
  .page-session-engagement #video-control-options,
  .page-session-overview #video-control-options,
  .page-sessison-virtual #video-control-options,
  .page-session-zoom #video-control-options,
  .page-session #video-control-options,
  .page-virtual-exhibitor #video-control-options {
    padding: 10px; }
  .page-admin-panel #video-control-options select,
  .page-session-engagement #video-control-options select,
  .page-session-overview #video-control-options select,
  .page-sessison-virtual #video-control-options select,
  .page-session-zoom #video-control-options select,
  .page-session #video-control-options select,
  .page-virtual-exhibitor #video-control-options select {
    margin-top: 5px; }
  .page-admin-panel #presenter-video,
  .page-session-engagement #presenter-video,
  .page-session-overview #presenter-video,
  .page-sessison-virtual #presenter-video,
  .page-session-zoom #presenter-video,
  .page-session #presenter-video,
  .page-virtual-exhibitor #presenter-video {
    position: relative;
    text-align: center; }
  .page-admin-panel #presenter-video-wrapper,
  .page-session-engagement #presenter-video-wrapper,
  .page-session-overview #presenter-video-wrapper,
  .page-sessison-virtual #presenter-video-wrapper,
  .page-session-zoom #presenter-video-wrapper,
  .page-session #presenter-video-wrapper,
  .page-virtual-exhibitor #presenter-video-wrapper {
    text-align: center;
    background: #000; }
  .page-admin-panel .presenter-video-item,
  .page-session-engagement .presenter-video-item,
  .page-session-overview .presenter-video-item,
  .page-sessison-virtual .presenter-video-item,
  .page-session-zoom .presenter-video-item,
  .page-session .presenter-video-item,
  .page-virtual-exhibitor .presenter-video-item {
    display: inline-block;
    margin: 0 0 0 0;
    width: 100%;
    height: 200px; }
  .page-admin-panel .presenter-video-item.half,
  .page-session-engagement .presenter-video-item.half,
  .page-session-overview .presenter-video-item.half,
  .page-sessison-virtual .presenter-video-item.half,
  .page-session-zoom .presenter-video-item.half,
  .page-session .presenter-video-item.half,
  .page-virtual-exhibitor .presenter-video-item.half {
    display: inline-block;
    width: 49%;
    margin: 0 0 0 0;
    height: 100px; }
  .page-admin-panel #online-status,
  .page-session-engagement #online-status,
  .page-session-overview #online-status,
  .page-sessison-virtual #online-status,
  .page-session-zoom #online-status,
  .page-session #online-status,
  .page-virtual-exhibitor #online-status {
    color: #fff;
    font-style: italic; }
  .page-admin-panel #join-feed,
  .page-session-engagement #join-feed,
  .page-session-overview #join-feed,
  .page-sessison-virtual #join-feed,
  .page-session-zoom #join-feed,
  .page-session #join-feed,
  .page-virtual-exhibitor #join-feed {
    font-weight: bold;
    color: #fff;
    text-decoration: underline; }
  .page-admin-panel #local-feed,
  .page-session-engagement #local-feed,
  .page-session-overview #local-feed,
  .page-sessison-virtual #local-feed,
  .page-session-zoom #local-feed,
  .page-session #local-feed,
  .page-virtual-exhibitor #local-feed {
    position: relative; }
  .page-admin-panel #local-feed p,
  .page-session-engagement #local-feed p,
  .page-session-overview #local-feed p,
  .page-sessison-virtual #local-feed p,
  .page-session-zoom #local-feed p,
  .page-session #local-feed p,
  .page-virtual-exhibitor #local-feed p {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 12px;
    margin: 0 0 0 0;
    color: #fff;
    font-weight: bold;
    z-index: 200;
    background: rgba(0, 0, 0, 0.5);
    padding: 3px; }
  .page-admin-panel .presenter-video-item,
  .page-session-engagement .presenter-video-item,
  .page-session-overview .presenter-video-item,
  .page-sessison-virtual .presenter-video-item,
  .page-session-zoom .presenter-video-item,
  .page-session .presenter-video-item,
  .page-virtual-exhibitor .presenter-video-item {
    background: #000; }
  .page-admin-panel .presenter-video-item video,
  .page-session-engagement .presenter-video-item video,
  .page-session-overview .presenter-video-item video,
  .page-sessison-virtual .presenter-video-item video,
  .page-session-zoom .presenter-video-item video,
  .page-session .presenter-video-item video,
  .page-virtual-exhibitor .presenter-video-item video {
    left: 0; }
  .page-admin-panel p#loading-message,
  .page-session-engagement p#loading-message,
  .page-session-overview p#loading-message,
  .page-sessison-virtual p#loading-message,
  .page-session-zoom p#loading-message,
  .page-session p#loading-message,
  .page-virtual-exhibitor p#loading-message {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 9999;
    font-family: sans-serif;
    padding: 5px;
    color: #000;
    font-weight: bold;
    text-align: center; }
  .page-admin-panel #btn-go-live,
  .page-session-engagement #btn-go-live,
  .page-session-overview #btn-go-live,
  .page-sessison-virtual #btn-go-live,
  .page-session-zoom #btn-go-live,
  .page-session #btn-go-live,
  .page-virtual-exhibitor #btn-go-live {
    border-radius: 0 0 0 0;
    -webkit-border-radius: 0 0 0 0; }
  .page-admin-panel #availability-controls,
  .page-session-engagement #availability-controls,
  .page-session-overview #availability-controls,
  .page-sessison-virtual #availability-controls,
  .page-session-zoom #availability-controls,
  .page-session #availability-controls,
  .page-virtual-exhibitor #availability-controls {
    padding: 5px; }
  .page-admin-panel #availability-controls select,
  .page-session-engagement #availability-controls select,
  .page-session-overview #availability-controls select,
  .page-sessison-virtual #availability-controls select,
  .page-session-zoom #availability-controls select,
  .page-session #availability-controls select,
  .page-virtual-exhibitor #availability-controls select {
    margin-top: 5px; }
  .page-admin-panel #audio-bar,
  .page-session-engagement #audio-bar,
  .page-session-overview #audio-bar,
  .page-sessison-virtual #audio-bar,
  .page-session-zoom #audio-bar,
  .page-session #audio-bar,
  .page-virtual-exhibitor #audio-bar {
    height: 10px;
    background: #e4e4e4;
    margin-top: 5px; }
  .page-admin-panel #audio-bar-inner,
  .page-session-engagement #audio-bar-inner,
  .page-session-overview #audio-bar-inner,
  .page-sessison-virtual #audio-bar-inner,
  .page-session-zoom #audio-bar-inner,
  .page-session #audio-bar-inner,
  .page-virtual-exhibitor #audio-bar-inner {
    height: 10px;
    background: #28e828;
    width: 0; }
  .page-admin-panel #enable-theatre,
  .page-session-engagement #enable-theatre,
  .page-session-overview #enable-theatre,
  .page-sessison-virtual #enable-theatre,
  .page-session-zoom #enable-theatre,
  .page-session #enable-theatre,
  .page-virtual-exhibitor #enable-theatre {
    text-decoration: none; }
  .page-admin-panel body.theatre-on #sidebar-left,
  .page-session-engagement body.theatre-on #sidebar-left,
  .page-session-overview body.theatre-on #sidebar-left,
  .page-sessison-virtual body.theatre-on #sidebar-left,
  .page-session-zoom body.theatre-on #sidebar-left,
  .page-session body.theatre-on #sidebar-left,
  .page-virtual-exhibitor body.theatre-on #sidebar-left {
    display: none; }
  .page-admin-panel body.theatre-on #global-container,
  .page-session-engagement body.theatre-on #global-container,
  .page-session-overview body.theatre-on #global-container,
  .page-sessison-virtual body.theatre-on #global-container,
  .page-session-zoom body.theatre-on #global-container,
  .page-session body.theatre-on #global-container,
  .page-virtual-exhibitor body.theatre-on #global-container {
    padding-left: 0; }
  .page-admin-panel body.theatre-on #global-container,
  .page-session-engagement body.theatre-on #global-container,
  .page-session-overview body.theatre-on #global-container,
  .page-sessison-virtual body.theatre-on #global-container,
  .page-session-zoom body.theatre-on #global-container,
  .page-session body.theatre-on #global-container,
  .page-virtual-exhibitor body.theatre-on #global-container {
    max-width: 100%; }
  .page-admin-panel .poll-sidebar-wrapper,
  .page-session-engagement .poll-sidebar-wrapper,
  .page-session-overview .poll-sidebar-wrapper,
  .page-sessison-virtual .poll-sidebar-wrapper,
  .page-session-zoom .poll-sidebar-wrapper,
  .page-session .poll-sidebar-wrapper,
  .page-virtual-exhibitor .poll-sidebar-wrapper {
    z-index: 99; }
  .page-admin-panel .live-feed-wrapper,
  .page-session-engagement .live-feed-wrapper,
  .page-session-overview .live-feed-wrapper,
  .page-sessison-virtual .live-feed-wrapper,
  .page-session-zoom .live-feed-wrapper,
  .page-session .live-feed-wrapper,
  .page-virtual-exhibitor .live-feed-wrapper {
    position: relative; }
  .page-admin-panel .live-feed-wrapper[data-feed="users"],
  .page-session-engagement .live-feed-wrapper[data-feed="users"],
  .page-session-overview .live-feed-wrapper[data-feed="users"],
  .page-sessison-virtual .live-feed-wrapper[data-feed="users"],
  .page-session-zoom .live-feed-wrapper[data-feed="users"],
  .page-session .live-feed-wrapper[data-feed="users"],
  .page-virtual-exhibitor .live-feed-wrapper[data-feed="users"] {
    background: none; }
  .page-admin-panel .toggle-feed-size,
  .page-session-engagement .toggle-feed-size,
  .page-session-overview .toggle-feed-size,
  .page-sessison-virtual .toggle-feed-size,
  .page-session-zoom .toggle-feed-size,
  .page-session .toggle-feed-size,
  .page-virtual-exhibitor .toggle-feed-size {
    position: absolute;
    right: 10px;
    bottom: 10px; }
  .page-admin-panel .session-type-collaborative-workshop .toggle-feed-size,
  .page-session-engagement .session-type-collaborative-workshop .toggle-feed-size,
  .page-session-overview .session-type-collaborative-workshop .toggle-feed-size,
  .page-sessison-virtual .session-type-collaborative-workshop .toggle-feed-size,
  .page-session-zoom .session-type-collaborative-workshop .toggle-feed-size,
  .page-session .session-type-collaborative-workshop .toggle-feed-size,
  .page-virtual-exhibitor .session-type-collaborative-workshop .toggle-feed-size {
    max-height: 100px; }
  @media (max-width: 900px) {
    .page-admin-panel #interstitial-image img,
    .page-session-engagement #interstitial-image img,
    .page-session-overview #interstitial-image img,
    .page-sessison-virtual #interstitial-image img,
    .page-session-zoom #interstitial-image img,
    .page-session #interstitial-image img,
    .page-virtual-exhibitor #interstitial-image img {
      max-width: 100%; } }
  .page-admin-panel body.theatre-on #sidebar-left,
  .page-session-engagement body.theatre-on #sidebar-left,
  .page-session-overview body.theatre-on #sidebar-left,
  .page-sessison-virtual body.theatre-on #sidebar-left,
  .page-session-zoom body.theatre-on #sidebar-left,
  .page-session body.theatre-on #sidebar-left,
  .page-virtual-exhibitor body.theatre-on #sidebar-left {
    display: none; }
  .page-admin-panel body.theatre-on #global-container,
  .page-session-engagement body.theatre-on #global-container,
  .page-session-overview body.theatre-on #global-container,
  .page-sessison-virtual body.theatre-on #global-container,
  .page-session-zoom body.theatre-on #global-container,
  .page-session body.theatre-on #global-container,
  .page-virtual-exhibitor body.theatre-on #global-container {
    padding-left: 0; }
  .page-admin-panel body.theatre-on #global-container,
  .page-session-engagement body.theatre-on #global-container,
  .page-session-overview body.theatre-on #global-container,
  .page-sessison-virtual body.theatre-on #global-container,
  .page-session-zoom body.theatre-on #global-container,
  .page-session body.theatre-on #global-container,
  .page-virtual-exhibitor body.theatre-on #global-container {
    max-width: 100%; }
  .page-admin-panel #sidebar-panel::-webkit-scrollbar-track,
  .page-session-engagement #sidebar-panel::-webkit-scrollbar-track,
  .page-session-overview #sidebar-panel::-webkit-scrollbar-track,
  .page-sessison-virtual #sidebar-panel::-webkit-scrollbar-track,
  .page-session-zoom #sidebar-panel::-webkit-scrollbar-track,
  .page-session #sidebar-panel::-webkit-scrollbar-track,
  .page-virtual-exhibitor #sidebar-panel::-webkit-scrollbar-track {
    background: transparent; }
  .page-admin-panel .condensed form .row,
  .page-admin-panel .condensed form .col-thin,
  .page-session-engagement .condensed form .row,
  .page-session-engagement .condensed form .col-thin,
  .page-session-overview .condensed form .row,
  .page-session-overview .condensed form .col-thin,
  .page-sessison-virtual .condensed form .row,
  .page-sessison-virtual .condensed form .col-thin,
  .page-session-zoom .condensed form .row,
  .page-session-zoom .condensed form .col-thin,
  .page-session .condensed form .row,
  .page-session .condensed form .col-thin,
  .page-virtual-exhibitor .condensed form .row,
  .page-virtual-exhibitor .condensed form .col-thin {
    padding: 0; }
  .page-admin-panel .condensed form label,
  .page-session-engagement .condensed form label,
  .page-session-overview .condensed form label,
  .page-sessison-virtual .condensed form label,
  .page-session-zoom .condensed form label,
  .page-session .condensed form label,
  .page-virtual-exhibitor .condensed form label {
    display: none; }
  .page-admin-panel .condensed form .container-input,
  .page-admin-panel .condensed form .container-submit,
  .page-session-engagement .condensed form .container-input,
  .page-session-engagement .condensed form .container-submit,
  .page-session-overview .condensed form .container-input,
  .page-session-overview .condensed form .container-submit,
  .page-sessison-virtual .condensed form .container-input,
  .page-sessison-virtual .condensed form .container-submit,
  .page-session-zoom .condensed form .container-input,
  .page-session-zoom .condensed form .container-submit,
  .page-session .condensed form .container-input,
  .page-session .condensed form .container-submit,
  .page-virtual-exhibitor .condensed form .container-input,
  .page-virtual-exhibitor .condensed form .container-submit {
    width: 100%;
    flex: none;
    max-width: 100%; }
  .page-admin-panel .condensed form .container-submit,
  .page-session-engagement .condensed form .container-submit,
  .page-session-overview .condensed form .container-submit,
  .page-sessison-virtual .condensed form .container-submit,
  .page-session-zoom .condensed form .container-submit,
  .page-session .condensed form .container-submit,
  .page-virtual-exhibitor .condensed form .container-submit {
    margin-top: 0.5em; }
  .page-admin-panel .condensed .session-chat-item,
  .page-session-engagement .condensed .session-chat-item,
  .page-session-overview .condensed .session-chat-item,
  .page-sessison-virtual .condensed .session-chat-item,
  .page-session-zoom .condensed .session-chat-item,
  .page-session .condensed .session-chat-item,
  .page-virtual-exhibitor .condensed .session-chat-item {
    font-size: 0.85em;
    padding: 0;
    margin: 1.5em 0;
    position: relative; }
  .page-admin-panel .condensed .session-chat-item .session-chat-item-header,
  .page-session-engagement .condensed .session-chat-item .session-chat-item-header,
  .page-session-overview .condensed .session-chat-item .session-chat-item-header,
  .page-sessison-virtual .condensed .session-chat-item .session-chat-item-header,
  .page-session-zoom .condensed .session-chat-item .session-chat-item-header,
  .page-session .condensed .session-chat-item .session-chat-item-header,
  .page-virtual-exhibitor .condensed .session-chat-item .session-chat-item-header {
    min-height: 20px;
    margin-bottom: 0.3em;
    line-height: 1.5em; }
  .page-admin-panel .condensed .session-chat-item-header .profile-img,
  .page-session-engagement .condensed .session-chat-item-header .profile-img,
  .page-session-overview .condensed .session-chat-item-header .profile-img,
  .page-sessison-virtual .condensed .session-chat-item-header .profile-img,
  .page-session-zoom .condensed .session-chat-item-header .profile-img,
  .page-session .condensed .session-chat-item-header .profile-img,
  .page-virtual-exhibitor .condensed .session-chat-item-header .profile-img {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em; }
  .page-admin-panel .condensed .session-chat-item .session-chat-item-header .chat-username,
  .page-session-engagement .condensed .session-chat-item .session-chat-item-header .chat-username,
  .page-session-overview .condensed .session-chat-item .session-chat-item-header .chat-username,
  .page-sessison-virtual .condensed .session-chat-item .session-chat-item-header .chat-username,
  .page-session-zoom .condensed .session-chat-item .session-chat-item-header .chat-username,
  .page-session .condensed .session-chat-item .session-chat-item-header .chat-username,
  .page-virtual-exhibitor .condensed .session-chat-item .session-chat-item-header .chat-username {
    line-height: 1.25em;
    display: block; }
  .page-admin-panel .condensed .chat-username .chat-company,
  .page-session-engagement .condensed .chat-username .chat-company,
  .page-session-overview .condensed .chat-username .chat-company,
  .page-sessison-virtual .condensed .chat-username .chat-company,
  .page-session-zoom .condensed .chat-username .chat-company,
  .page-session .condensed .chat-username .chat-company,
  .page-virtual-exhibitor .condensed .chat-username .chat-company {
    display: block;
    font-style: italic;
    font-weight: normal;
    opacity: 0.75; }
  .page-admin-panel .condensed .chat-username .chat-company:before,
  .page-session-engagement .condensed .chat-username .chat-company:before,
  .page-session-overview .condensed .chat-username .chat-company:before,
  .page-sessison-virtual .condensed .chat-username .chat-company:before,
  .page-session-zoom .condensed .chat-username .chat-company:before,
  .page-session .condensed .chat-username .chat-company:before,
  .page-virtual-exhibitor .condensed .chat-username .chat-company:before {
    display: none; }
  .page-admin-panel .condensed .session-chat-item-comment,
  .page-session-engagement .condensed .session-chat-item-comment,
  .page-session-overview .condensed .session-chat-item-comment,
  .page-sessison-virtual .condensed .session-chat-item-comment,
  .page-session-zoom .condensed .session-chat-item-comment,
  .page-session .condensed .session-chat-item-comment,
  .page-virtual-exhibitor .condensed .session-chat-item-comment {
    font-size: 16px;
    margin: -5px 0 0 45px;
    position: relative;
    margin-top: -5px; }
  .page-admin-panel .condensed .session-chat-item .session-chat-item-footer .chat-relativeTimestamp,
  .page-session-engagement .condensed .session-chat-item .session-chat-item-footer .chat-relativeTimestamp,
  .page-session-overview .condensed .session-chat-item .session-chat-item-footer .chat-relativeTimestamp,
  .page-sessison-virtual .condensed .session-chat-item .session-chat-item-footer .chat-relativeTimestamp,
  .page-session-zoom .condensed .session-chat-item .session-chat-item-footer .chat-relativeTimestamp,
  .page-session .condensed .session-chat-item .session-chat-item-footer .chat-relativeTimestamp,
  .page-virtual-exhibitor .condensed .session-chat-item .session-chat-item-footer .chat-relativeTimestamp {
    display: none !important; }
  .page-admin-panel .condensed .form-chat-reply,
  .page-session-engagement .condensed .form-chat-reply,
  .page-session-overview .condensed .form-chat-reply,
  .page-sessison-virtual .condensed .form-chat-reply,
  .page-session-zoom .condensed .form-chat-reply,
  .page-session .condensed .form-chat-reply,
  .page-virtual-exhibitor .condensed .form-chat-reply {
    font-size: 0.85em;
    left: 0; }
  .page-admin-panel .condensed .btn.btn-sm, .page-admin-panel .condensed .btn-group-sm > .btn,
  .page-session-engagement .condensed .btn.btn-sm,
  .page-session-engagement .condensed .btn-group-sm > .btn,
  .page-session-overview .condensed .btn.btn-sm,
  .page-session-overview .condensed .btn-group-sm > .btn,
  .page-sessison-virtual .condensed .btn.btn-sm,
  .page-sessison-virtual .condensed .btn-group-sm > .btn,
  .page-session-zoom .condensed .btn.btn-sm,
  .page-session-zoom .condensed .btn-group-sm > .btn,
  .page-session .condensed .btn.btn-sm,
  .page-session .condensed .btn-group-sm > .btn,
  .page-virtual-exhibitor .condensed .btn.btn-sm,
  .page-virtual-exhibitor .condensed .btn-group-sm > .btn {
    padding: .15rem .15rem;
    border: none; }
  .page-admin-panel .condensed div.chatname,
  .page-session-engagement .condensed div.chatname,
  .page-session-overview .condensed div.chatname,
  .page-sessison-virtual .condensed div.chatname,
  .page-session-zoom .condensed div.chatname,
  .page-session .condensed div.chatname,
  .page-virtual-exhibitor .condensed div.chatname {
    font-size: .9em; }
  .page-admin-panel .condensed .form-chat-reply [type="submit"],
  .page-admin-panel .condensed .form-chat-reply [type="text"],
  .page-session-engagement .condensed .form-chat-reply [type="submit"],
  .page-session-engagement .condensed .form-chat-reply [type="text"],
  .page-session-overview .condensed .form-chat-reply [type="submit"],
  .page-session-overview .condensed .form-chat-reply [type="text"],
  .page-sessison-virtual .condensed .form-chat-reply [type="submit"],
  .page-sessison-virtual .condensed .form-chat-reply [type="text"],
  .page-session-zoom .condensed .form-chat-reply [type="submit"],
  .page-session-zoom .condensed .form-chat-reply [type="text"],
  .page-session .condensed .form-chat-reply [type="submit"],
  .page-session .condensed .form-chat-reply [type="text"],
  .page-virtual-exhibitor .condensed .form-chat-reply [type="submit"],
  .page-virtual-exhibitor .condensed .form-chat-reply [type="text"] {
    font-size: 0.9em; }
  .page-admin-panel .condensed .session-chat-item-replies,
  .page-session-engagement .condensed .session-chat-item-replies,
  .page-session-overview .condensed .session-chat-item-replies,
  .page-sessison-virtual .condensed .session-chat-item-replies,
  .page-session-zoom .condensed .session-chat-item-replies,
  .page-session .condensed .session-chat-item-replies,
  .page-virtual-exhibitor .condensed .session-chat-item-replies {
    margin: 1.5em 0 0 -1em; }
  .page-admin-panel .condensed .session-chat-item-replies .session-chat-item,
  .page-session-engagement .condensed .session-chat-item-replies .session-chat-item,
  .page-session-overview .condensed .session-chat-item-replies .session-chat-item,
  .page-sessison-virtual .condensed .session-chat-item-replies .session-chat-item,
  .page-session-zoom .condensed .session-chat-item-replies .session-chat-item,
  .page-session .condensed .session-chat-item-replies .session-chat-item,
  .page-virtual-exhibitor .condensed .session-chat-item-replies .session-chat-item {
    margin: 1em 0; }
  .page-admin-panel .condensed .session-chat-item-reply .profile-img,
  .page-session-engagement .condensed .session-chat-item-reply .profile-img,
  .page-session-overview .condensed .session-chat-item-reply .profile-img,
  .page-sessison-virtual .condensed .session-chat-item-reply .profile-img,
  .page-session-zoom .condensed .session-chat-item-reply .profile-img,
  .page-session .condensed .session-chat-item-reply .profile-img,
  .page-virtual-exhibitor .condensed .session-chat-item-reply .profile-img {
    left: 1em; }
  .page-admin-panel .condensed .session-chat-item-reply .session-chat-item-comment,
  .page-session-engagement .condensed .session-chat-item-reply .session-chat-item-comment,
  .page-session-overview .condensed .session-chat-item-reply .session-chat-item-comment,
  .page-sessison-virtual .condensed .session-chat-item-reply .session-chat-item-comment,
  .page-session-zoom .condensed .session-chat-item-reply .session-chat-item-comment,
  .page-session .condensed .session-chat-item-reply .session-chat-item-comment,
  .page-virtual-exhibitor .condensed .session-chat-item-reply .session-chat-item-comment {
    margin-bottom: 1em; }
  .page-admin-panel .condensed .form-chat-reply,
  .page-session-engagement .condensed .form-chat-reply,
  .page-session-overview .condensed .form-chat-reply,
  .page-sessison-virtual .condensed .form-chat-reply,
  .page-session-zoom .condensed .form-chat-reply,
  .page-session .condensed .form-chat-reply,
  .page-virtual-exhibitor .condensed .form-chat-reply {
    margin-top: 20px; }
  .page-admin-panel .minimized-likes,
  .page-session-engagement .minimized-likes,
  .page-session-overview .minimized-likes,
  .page-sessison-virtual .minimized-likes,
  .page-session-zoom .minimized-likes,
  .page-session .minimized-likes,
  .page-virtual-exhibitor .minimized-likes {
    display: none; }
  .page-admin-panel .condensed .minimized-likes,
  .page-session-engagement .condensed .minimized-likes,
  .page-session-overview .condensed .minimized-likes,
  .page-sessison-virtual .condensed .minimized-likes,
  .page-session-zoom .condensed .minimized-likes,
  .page-session .condensed .minimized-likes,
  .page-virtual-exhibitor .condensed .minimized-likes {
    display: block;
    position: absolute;
    top: 20px;
    left: 20px; }
  .page-admin-panel .condensed .minimized-likes i,
  .page-session-engagement .condensed .minimized-likes i,
  .page-session-overview .condensed .minimized-likes i,
  .page-sessison-virtual .condensed .minimized-likes i,
  .page-session-zoom .condensed .minimized-likes i,
  .page-session .condensed .minimized-likes i,
  .page-virtual-exhibitor .condensed .minimized-likes i {
    margin-right: 5px;
    display: inline-block; }
  .page-admin-panel .minimized-likes-inner,
  .page-session-engagement .minimized-likes-inner,
  .page-session-overview .minimized-likes-inner,
  .page-sessison-virtual .minimized-likes-inner,
  .page-session-zoom .minimized-likes-inner,
  .page-session .minimized-likes-inner,
  .page-virtual-exhibitor .minimized-likes-inner {
    position: absolute;
    bottom: -5px;
    right: -5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    text-align: center;
    line-height: 15px;
    font-size: 10px; }
  .page-admin-panel button span,
  .page-session-engagement button span,
  .page-session-overview button span,
  .page-sessison-virtual button span,
  .page-session-zoom button span,
  .page-session button span,
  .page-virtual-exhibitor button span {
    margin-left: 3px; }
  .page-admin-panel .condensed button span,
  .page-session-engagement .condensed button span,
  .page-session-overview .condensed button span,
  .page-sessison-virtual .condensed button span,
  .page-session-zoom .condensed button span,
  .page-session .condensed button span,
  .page-virtual-exhibitor .condensed button span {
    display: none; }
  .page-admin-panel .star-rating a,
  .page-session-engagement .star-rating a,
  .page-session-overview .star-rating a,
  .page-sessison-virtual .star-rating a,
  .page-session-zoom .star-rating a,
  .page-session .star-rating a,
  .page-virtual-exhibitor .star-rating a {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    float: none;
    display: inline-block; }
  .page-admin-panel .rating-block,
  .page-session-engagement .rating-block,
  .page-session-overview .rating-block,
  .page-sessison-virtual .rating-block,
  .page-session-zoom .rating-block,
  .page-session .rating-block,
  .page-virtual-exhibitor .rating-block {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .page-admin-panel .rating-header,
  .page-session-engagement .rating-header,
  .page-session-overview .rating-header,
  .page-sessison-virtual .rating-header,
  .page-session-zoom .rating-header,
  .page-session .rating-header,
  .page-virtual-exhibitor .rating-header {
    text-align: center; }
  .page-admin-panel .rating-block .rating-header h3, .page-admin-panel .rating-block .rating-header .h3,
  .page-session-engagement .rating-block .rating-header h3,
  .page-session-engagement .rating-block .rating-header .h3,
  .page-session-overview .rating-block .rating-header h3,
  .page-session-overview .rating-block .rating-header .h3,
  .page-sessison-virtual .rating-block .rating-header h3,
  .page-sessison-virtual .rating-block .rating-header .h3,
  .page-session-zoom .rating-block .rating-header h3,
  .page-session-zoom .rating-block .rating-header .h3,
  .page-session .rating-block .rating-header h3,
  .page-session .rating-block .rating-header .h3,
  .page-virtual-exhibitor .rating-block .rating-header h3,
  .page-virtual-exhibitor .rating-block .rating-header .h3 {
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #000;
    font: 700 1.2em/1.25em 'Lato', sans-serif;
    margin: 0 0 .5em; }
  .page-admin-panel .rating-block .rating-header p,
  .page-session-engagement .rating-block .rating-header p,
  .page-session-overview .rating-block .rating-header p,
  .page-sessison-virtual .rating-block .rating-header p,
  .page-session-zoom .rating-block .rating-header p,
  .page-session .rating-block .rating-header p,
  .page-virtual-exhibitor .rating-block .rating-header p {
    font: 400 1em/1.4em 'Lato', sans-serif;
    color: #666;
    margin: 0; }
  .page-admin-panel .rating-block .rating-content,
  .page-session-engagement .rating-block .rating-content,
  .page-session-overview .rating-block .rating-content,
  .page-sessison-virtual .rating-block .rating-content,
  .page-session-zoom .rating-block .rating-content,
  .page-session .rating-block .rating-content,
  .page-virtual-exhibitor .rating-block .rating-content {
    display: none; }
  .page-admin-panel .rating-block .star-rating,
  .page-session-engagement .rating-block .star-rating,
  .page-session-overview .rating-block .star-rating,
  .page-sessison-virtual .rating-block .star-rating,
  .page-session-zoom .rating-block .star-rating,
  .page-session .rating-block .star-rating,
  .page-virtual-exhibitor .rating-block .star-rating {
    display: block;
    padding: 10px 0;
    text-align: center; }
  .page-admin-panel .rating-block .star-rating a,
  .page-session-engagement .rating-block .star-rating a,
  .page-session-overview .rating-block .star-rating a,
  .page-sessison-virtual .rating-block .star-rating a,
  .page-session-zoom .rating-block .star-rating a,
  .page-session .rating-block .star-rating a,
  .page-virtual-exhibitor .rating-block .star-rating a {
    font-size: 30px;
    width: 40px;
    height: 40px;
    color: #ccc;
    float: none;
    display: inline-block; }
  .page-admin-panel .rating-block .star-rating a.active .fa-star,
  .page-session-engagement .rating-block .star-rating a.active .fa-star,
  .page-session-overview .rating-block .star-rating a.active .fa-star,
  .page-sessison-virtual .rating-block .star-rating a.active .fa-star,
  .page-session-zoom .rating-block .star-rating a.active .fa-star,
  .page-session .rating-block .star-rating a.active .fa-star,
  .page-virtual-exhibitor .rating-block .star-rating a.active .fa-star {
    font-weight: 700;
    color: gold; }
  .page-admin-panel .rating-block .star-rating a:hover,
  .page-session-engagement .rating-block .star-rating a:hover,
  .page-session-overview .rating-block .star-rating a:hover,
  .page-sessison-virtual .rating-block .star-rating a:hover,
  .page-session-zoom .rating-block .star-rating a:hover,
  .page-session .rating-block .star-rating a:hover,
  .page-virtual-exhibitor .rating-block .star-rating a:hover {
    color: #aaa; }
  .page-admin-panel .rating-block .rating-content,
  .page-session-engagement .rating-block .rating-content,
  .page-session-overview .rating-block .rating-content,
  .page-sessison-virtual .rating-block .rating-content,
  .page-session-zoom .rating-block .rating-content,
  .page-session .rating-block .rating-content,
  .page-virtual-exhibitor .rating-block .rating-content {
    display: none;
    background: #eee;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
    border: 1px solid #ccc;
    margin: 0 -16px; }
  .page-admin-panel .rating-block .rating-content label,
  .page-session-engagement .rating-block .rating-content label,
  .page-session-overview .rating-block .rating-content label,
  .page-sessison-virtual .rating-block .rating-content label,
  .page-session-zoom .rating-block .rating-content label,
  .page-session .rating-block .rating-content label,
  .page-virtual-exhibitor .rating-block .rating-content label {
    display: block;
    margin: 20px 2.5% 0; }
  .page-admin-panel .rating-block .rating-content textarea,
  .page-session-engagement .rating-block .rating-content textarea,
  .page-session-overview .rating-block .rating-content textarea,
  .page-sessison-virtual .rating-block .rating-content textarea,
  .page-session-zoom .rating-block .rating-content textarea,
  .page-session .rating-block .rating-content textarea,
  .page-virtual-exhibitor .rating-block .rating-content textarea {
    width: 95%;
    margin: 10px 2.5%;
    padding: 10px 1%;
    border: 1px solid #ccc; }
  .page-admin-panel .rating-block .rating-content .btn,
  .page-session-engagement .rating-block .rating-content .btn,
  .page-session-overview .rating-block .rating-content .btn,
  .page-sessison-virtual .rating-block .rating-content .btn,
  .page-session-zoom .rating-block .rating-content .btn,
  .page-session .rating-block .rating-content .btn,
  .page-virtual-exhibitor .rating-block .rating-content .btn {
    width: 95%;
    display: block;
    margin: 10px 2.5% 20px;
    text-align: center; }
  .page-admin-panel .custom-modal,
  .page-session-engagement .custom-modal,
  .page-session-overview .custom-modal,
  .page-sessison-virtual .custom-modal,
  .page-session-zoom .custom-modal,
  .page-session .custom-modal,
  .page-virtual-exhibitor .custom-modal {
    position: absolute; }
  .page-admin-panel .rating-block .star-rating a:focus span,
  .page-session-engagement .rating-block .star-rating a:focus span,
  .page-session-overview .rating-block .star-rating a:focus span,
  .page-sessison-virtual .rating-block .star-rating a:focus span,
  .page-session-zoom .rating-block .star-rating a:focus span,
  .page-session .rating-block .star-rating a:focus span,
  .page-virtual-exhibitor .rating-block .star-rating a:focus span {
    color: #000; }
  @media (min-width: 751px) {
    .page-admin-panel #mobile-flyout,
    .page-session-engagement #mobile-flyout,
    .page-session-overview #mobile-flyout,
    .page-sessison-virtual #mobile-flyout,
    .page-session-zoom #mobile-flyout,
    .page-session #mobile-flyout,
    .page-virtual-exhibitor #mobile-flyout {
      display: none !important; } }
  @media (max-width: 750px) {
    .page-admin-panel #mobile-flyout,
    .page-session-engagement #mobile-flyout,
    .page-session-overview #mobile-flyout,
    .page-sessison-virtual #mobile-flyout,
    .page-session-zoom #mobile-flyout,
    .page-session #mobile-flyout,
    .page-virtual-exhibitor #mobile-flyout {
      display: inline-block !important; }
    .page-admin-panel #sidebar-column.expanded,
    .page-session-engagement #sidebar-column.expanded,
    .page-session-overview #sidebar-column.expanded,
    .page-sessison-virtual #sidebar-column.expanded,
    .page-session-zoom #sidebar-column.expanded,
    .page-session #sidebar-column.expanded,
    .page-virtual-exhibitor #sidebar-column.expanded {
      top: 60px !important; }
    .page-admin-panel #page-virtual-exhibitor #sidebar-column.expanded,
    .page-session-engagement #page-virtual-exhibitor #sidebar-column.expanded,
    .page-session-overview #page-virtual-exhibitor #sidebar-column.expanded,
    .page-sessison-virtual #page-virtual-exhibitor #sidebar-column.expanded,
    .page-session-zoom #page-virtual-exhibitor #sidebar-column.expanded,
    .page-session #page-virtual-exhibitor #sidebar-column.expanded,
    .page-virtual-exhibitor #page-virtual-exhibitor #sidebar-column.expanded {
      margin-top: 115px !important; }
    .page-admin-panel .session-chat-item-footer,
    .page-session-engagement .session-chat-item-footer,
    .page-session-overview .session-chat-item-footer,
    .page-sessison-virtual .session-chat-item-footer,
    .page-session-zoom .session-chat-item-footer,
    .page-session .session-chat-item-footer,
    .page-virtual-exhibitor .session-chat-item-footer {
      display: block !important; } }
  .page-admin-panel .emojionearea,
  .page-admin-panel .emojionearea.form-control,
  .page-session-engagement .emojionearea,
  .page-session-engagement .emojionearea.form-control,
  .page-session-overview .emojionearea,
  .page-session-overview .emojionearea.form-control,
  .page-sessison-virtual .emojionearea,
  .page-sessison-virtual .emojionearea.form-control,
  .page-session-zoom .emojionearea,
  .page-session-zoom .emojionearea.form-control,
  .page-session .emojionearea,
  .page-session .emojionearea.form-control,
  .page-virtual-exhibitor .emojionearea,
  .page-virtual-exhibitor .emojionearea.form-control {
    font-size: 16px !important;
    width: auto !important; }
  .page-admin-panel .loader,
  .page-session-engagement .loader,
  .page-session-overview .loader,
  .page-sessison-virtual .loader,
  .page-session-zoom .loader,
  .page-session .loader,
  .page-virtual-exhibitor .loader {
    --duration: 3s;
    width: 48px;
    height: 44px;
    margin: 1rem auto;
    display: block;
    position: relative; }
  .page-admin-panel .loader:before,
  .page-session-engagement .loader:before,
  .page-session-overview .loader:before,
  .page-sessison-virtual .loader:before,
  .page-session-zoom .loader:before,
  .page-session .loader:before,
  .page-virtual-exhibitor .loader:before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    display: block;
    background: var(--primary-bc);
    top: 20px;
    left: 2px;
    transform: translate(0, 0);
    -webkit-animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite; }
  .page-admin-panel .loader svg,
  .page-session-engagement .loader svg,
  .page-session-overview .loader svg,
  .page-sessison-virtual .loader svg,
  .page-session-zoom .loader svg,
  .page-session .loader svg,
  .page-virtual-exhibitor .loader svg {
    display: block;
    width: 100%;
    height: 100%; }
  .page-admin-panel .loader polygon,
  .page-session-engagement .loader polygon,
  .page-session-overview .loader polygon,
  .page-sessison-virtual .loader polygon,
  .page-session-zoom .loader polygon,
  .page-session .loader polygon,
  .page-virtual-exhibitor .loader polygon {
    fill: none;
    stroke: var(--secondary-bc);
    stroke-width: 10px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-dasharray: 145 76 145 76;
    stroke-dashoffset: 0;
    -webkit-animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite; }

@-webkit-keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74; }
  66% {
    stroke-dashoffset: 147; }
  100% {
    stroke-dashoffset: 221; } }

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74; }
  66% {
    stroke-dashoffset: 147; }
  100% {
    stroke-dashoffset: 221; } }

@-webkit-keyframes dotTriangle {
  33% {
    transform: translate(0, 15px); }
  66% {
    transform: translate(35px, 0); }
  100% {
    transform: translate(-10px, -18px); } }

@keyframes dotTriangle {
  33% {
    transform: translate(17px, 8px); }
  66% {
    transform: translate(17px, -10px); }
  100% {
    transform: translate(0, 0); } }
  .page-admin-panel .fade-move,
  .page-admin-panel .fade-enter-active,
  .page-admin-panel .fade-leave-active,
  .page-session-engagement .fade-move,
  .page-session-engagement .fade-enter-active,
  .page-session-engagement .fade-leave-active,
  .page-session-overview .fade-move,
  .page-session-overview .fade-enter-active,
  .page-session-overview .fade-leave-active,
  .page-sessison-virtual .fade-move,
  .page-sessison-virtual .fade-enter-active,
  .page-sessison-virtual .fade-leave-active,
  .page-session-zoom .fade-move,
  .page-session-zoom .fade-enter-active,
  .page-session-zoom .fade-leave-active,
  .page-session .fade-move,
  .page-session .fade-enter-active,
  .page-session .fade-leave-active,
  .page-virtual-exhibitor .fade-move,
  .page-virtual-exhibitor .fade-enter-active,
  .page-virtual-exhibitor .fade-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1); }
  .page-admin-panel .fade-enter-from,
  .page-admin-panel .fade-leave-to,
  .page-session-engagement .fade-enter-from,
  .page-session-engagement .fade-leave-to,
  .page-session-overview .fade-enter-from,
  .page-session-overview .fade-leave-to,
  .page-sessison-virtual .fade-enter-from,
  .page-sessison-virtual .fade-leave-to,
  .page-session-zoom .fade-enter-from,
  .page-session-zoom .fade-leave-to,
  .page-session .fade-enter-from,
  .page-session .fade-leave-to,
  .page-virtual-exhibitor .fade-enter-from,
  .page-virtual-exhibitor .fade-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translate(30px, 0); }
  .page-admin-panel .fade-leave-active,
  .page-session-engagement .fade-leave-active,
  .page-session-overview .fade-leave-active,
  .page-sessison-virtual .fade-leave-active,
  .page-session-zoom .fade-leave-active,
  .page-session .fade-leave-active,
  .page-virtual-exhibitor .fade-leave-active {
    position: absolute; }
  .page-admin-panel #session-nav-tabContent .tab-pane,
  .page-session-engagement #session-nav-tabContent .tab-pane,
  .page-session-overview #session-nav-tabContent .tab-pane,
  .page-sessison-virtual #session-nav-tabContent .tab-pane,
  .page-session-zoom #session-nav-tabContent .tab-pane,
  .page-session #session-nav-tabContent .tab-pane,
  .page-virtual-exhibitor #session-nav-tabContent .tab-pane {
    min-height: 400px;
    padding-top: 15px; }
  .page-admin-panel #sidebar-column,
  .page-session-engagement #sidebar-column,
  .page-session-overview #sidebar-column,
  .page-sessison-virtual #sidebar-column,
  .page-session-zoom #sidebar-column,
  .page-session #sidebar-column,
  .page-virtual-exhibitor #sidebar-column {
    height: calc(100vh - 55px);
    overflow-y: auto;
    overflow-x: hidden; }
  .page-admin-panel .close-sidebar,
  .page-session-engagement .close-sidebar,
  .page-session-overview .close-sidebar,
  .page-sessison-virtual .close-sidebar,
  .page-session-zoom .close-sidebar,
  .page-session .close-sidebar,
  .page-virtual-exhibitor .close-sidebar {
    display: block; }
  @media (max-width: 991px) {
    .page-admin-panel #session-nav-tabContent .tab-pane,
    .page-session-engagement #session-nav-tabContent .tab-pane,
    .page-session-overview #session-nav-tabContent .tab-pane,
    .page-sessison-virtual #session-nav-tabContent .tab-pane,
    .page-session-zoom #session-nav-tabContent .tab-pane,
    .page-session #session-nav-tabContent .tab-pane,
    .page-virtual-exhibitor #session-nav-tabContent .tab-pane {
      min-height: 0; }
    .page-admin-panel #sidebar-column,
    .page-session-engagement #sidebar-column,
    .page-session-overview #sidebar-column,
    .page-sessison-virtual #sidebar-column,
    .page-session-zoom #sidebar-column,
    .page-session #sidebar-column,
    .page-virtual-exhibitor #sidebar-column {
      height: auto; } }
  .page-admin-panel .addeventatc,
  .page-session-engagement .addeventatc,
  .page-session-overview .addeventatc,
  .page-sessison-virtual .addeventatc,
  .page-session-zoom .addeventatc,
  .page-session .addeventatc,
  .page-virtual-exhibitor .addeventatc {
    z-index: 999 !important; }
  .page-admin-panel .session-chat-item-replies .minimized-likes-inner,
  .page-session-engagement .session-chat-item-replies .minimized-likes-inner,
  .page-session-overview .session-chat-item-replies .minimized-likes-inner,
  .page-sessison-virtual .session-chat-item-replies .minimized-likes-inner,
  .page-session-zoom .session-chat-item-replies .minimized-likes-inner,
  .page-session .session-chat-item-replies .minimized-likes-inner,
  .page-virtual-exhibitor .session-chat-item-replies .minimized-likes-inner {
    display: none; }

.page-sessison-virtual {
  /*.video-js .vjs-control-bar {
		background: transparent;
	}*/
  /* overwriting styles added to light.css ...  */ }
  .page-sessison-virtual .embed-responsive-item {
    position: relative; }
  .page-sessison-virtual #s-live-container .video-js .vjs-tech {
    pointer-events: none; }
  .page-sessison-virtual .live-tag {
    position: absolute;
    bottom: 3px;
    right: 3px;
    padding: 3px 6px;
    background: black;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    display: none; }
  .page-sessison-virtual #s-live-vid-wrapper {
    min-height: 200px;
    overflow: hidden;
    background: #000000; }
  .page-sessison-virtual #vid-processing {
    background: #000;
    color: #fff;
    font-size: 18px;
    z-index: 1000;
    text-align: center;
    line-height: 120px;
    overflow: hidden; }
  .page-sessison-virtual #s-live-vid-wrapper p {
    color: #fff;
    margin-top: 23%; }
  .page-sessison-virtual #countdown-timer {
    font-size: 32px;
    font-weight: bold;
    color: #fff; }
  .page-sessison-virtual .video-js .vjs-big-play-button {
    font-size: 3em;
    line-height: 60px;
    height: 60px;
    width: 60px;
    margin-left: -30px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    margin-top: -30px;
    cursor: pointer;
    opacity: 1;
    border: 0.06666em solid #fff;
    background-color: #2B333F;
    background-color: rgba(43, 51, 63, 0.3);
    border-radius: 0.3em;
    transition: all 0.4s;
    border-radius: 50%; }
  .page-sessison-virtual .playback-issues {
    background: #7b7b7b;
    border-radius: 2px;
    padding: 5px 0;
    color: #fff;
    font-weight: normal; }
  .page-sessison-virtual .playback-issues a {
    color: #fff;
    text-decoration: underline; }
  .page-sessison-virtual #additional-help ul {
    list-style-type: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0; }
  .page-sessison-virtual #additional-help li {
    font-size: 13px;
    font-weight: normal;
    list-style-type: none;
    padding-top: 10px;
    color: #666; }
  .page-sessison-virtual .disabled {
    background: transparent !important;
    border-color: transparent !important;
    color: inherit !important; }

.page-virtual-exhibitor #audience-container {
  height: auto;
  min-height: 250px;
  overflow: hidden;
  padding: 0 0 0 0; }

.page-virtual-exhibitor .live-feed-overlay {
  display: none !important; }

.page-virtual-exhibitor .schedule-item.active {
  border-color: #00a18d; }

.page-virtual-exhibitor .schedule-item .live-indicator {
  background: #00a18d;
  padding: 2px 8px;
  color: #fff;
  font-size: 12px;
  display: inline-block; }

.page-virtual-exhibitor #audience-container .toggle-feed-size {
  display: none; }

.page-virtual-exhibitor #audience-container {
  position: relative; }

.page-virtual-exhibitor #session-dicussion {
  display: none !important; }

.page-virtual-exhibitor #audience-container .toggle-feed-size {
  display: none; }

.page-virtual-exhibitor .minimized[data-feed="users"] .live-feed-container {
  width: 100% !important; }

.page-virtual-exhibitor .virtual-product img {
  border-bottom: 1px solid #ccc;
  margin-top: 10px; }

.page-virtual-exhibitor .virtual-product {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  background: #fff; }

.page-virtual-exhibitor .virtual-product .media-body {
  padding: 10px; }

.page-virtual-exhibitor .virtual-product .media a {
  display: block;
  width: 100%; }

.page-virtual-exhibitor .live-feed-wrapper.minimized .live-feed-title {
  padding-left: 125px !important; }

.page-session-zoom {
  /*Tabs */ }
  .page-session-zoom #training-welcome .badge {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 0 0 0 0;
    padding: 5px 15px 5px 15px; }
  .page-session-zoom .attendee-bubble-row {
    position: relative;
    padding-right: 14.286%; }
  .page-session-zoom .attendee-bubble-row:before,
  .page-session-zoom .attendee-bubble-row:after {
    content: '';
    display: table; }
  .page-session-zoom .attendee-bubble-row:after {
    clear: both; }
  .page-session-zoom .bubble-item-other {
    position: absolute;
    right: 0;
    top: 0; }
  .page-session-zoom .bubble-item {
    float: left;
    text-align: center;
    width: 14.286%; }
  .page-session-zoom .bubble-photo {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #eee;
    border: 1px solid #ccc; }
  .page-session-zoom .bubble-text {
    font-weight: 300;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .page-session-zoom .bubble-photo.end-item {
    background: #cacaca;
    font-weight: bold;
    line-height: 60px;
    font-size: 14px; }
  .page-session-zoom #zoom-info-container .direction-detail span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    text-align: center;
    line-height: 20px;
    background: #202020;
    color: #fff;
    font-weight: bold;
    font-size: 12px; }
  .page-session-zoom .queue-action {
    text-align: center;
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase; }
  .page-session-zoom .queue-action i {
    display: block;
    margin-bottom: 5px;
    font-size: 24px; }
  .page-session-zoom #cc-tab-4 .session-chat-item {
    padding: 15px 10px 15px 10px;
    overflow: hidden;
    border-bottom: 1px solid #000; }
  .page-session-zoom #cc-tab-4 .chat-user-details {
    padding-left: 70px; }
  .page-session-zoom #cc-tab-4 .chat-user-details .initials {
    top: 5px; }
  .page-session-zoom #cc-tab-4 .session-chat-item a.mark-answered {
    font-size: 22px;
    display: block;
    text-align: center;
    color: #00b379;
    margin-top: 10px;
    border-radius: 3px; }
  .page-session-zoom #cc-tab-4 .session-chat-item a.mark-answered:hover {
    background: #00b379;
    color: #fff; }
  .page-session-zoom #asked-questions {
    min-height: 400px;
    margin-bottom: 100px; }
  .page-session-zoom .simple-tab {
    display: none;
    padding: 20px 0; }
  .page-session-zoom .simple-tab.current-item {
    display: block; }
  .page-session-zoom .simple-tab-nav a {
    display: inline-block;
    text-decoration: none;
    text-align: center;
    padding: 10px 15px;
    color: #000; }
  .page-session-zoom .simple-tab-nav a.current-item {
    border-bottom: 4px solid #000;
    font-weight: bold; }

/**
 * Tabs
 */
.nav-tabs {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden; }

.nav-tabs .nav-link {
  display: inline-block;
  font-size: 0.875rem;
  border-left: none;
  border-right: none;
  border-top: none; }

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus-visible {
  background: transparent;
  font-weight: bold;
  border: none;
  border-bottom: 4px solid #005eb8;
  outline: none; }

.mh-500 {
  min-height: 500px; }

/**
 * Scrollbars
 */
::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

::-webkit-scrollbar-track {
  background: #e5e5e5; }

::-webkit-scrollbar-thumb {
  background: #b7b7b7; }

/**
 * Images
 */
.sq-img-50 img {
  display: block;
  width: 50px;
  height: 50px; }

.image-placeholder {
  font-weight: 300;
  font-size: 20px;
  line-height: 48px;
  height: 50px;
  width: 50px;
  text-align: center;
  color: #555;
  border: 1px solid #ccc;
  background: #ddd;
  text-shadow: 1px 1px #eee; }

.circle-img-thumbnail {
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #000; }
  .circle-img-thumbnail.--lg {
    min-width: 100px;
    width: 100px;
    height: 100px; }

.circle-img-placeholder {
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #555;
  background: #ddd;
  text-shadow: 1px 1px #eee;
  font-weight: 300;
  font-size: 20px;
  line-height: 48px; }

.img-block {
  display: block;
  max-width: 100%; }
  .img-block.--med {
    width: 400px; }
  .img-block.--rounded {
    border-radius: 4px; }

/**
 * Typography
 */
.fs-small {
  font-size: .8rem; }

/**
 * Navigations that overflow-x
 */
.nav-overflow-x {
  white-space: nowrap;
  overflow-x: auto; }

/**
 * Standard Links / Anchors
 */
a {
  text-decoration: none; }

.with-hover:hover {
  cursor: pointer; }

/**
 * List Groups
 */
.li-btn-container-2 {
  min-width: 93px; }

.list-group-item .list-group-item-primary {
  font-size: 0.875rem; }

.list-group-item.--arrow {
  position: relative;
  padding-right: 40px; }
  .list-group-item.--arrow::after {
    content: '\f054';
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%); }

.list-group-item.--plus {
  position: relative;
  padding-right: 40px; }
  .list-group-item.--plus::after {
    content: '\f055';
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%); }

/**
 * Forms
 */
.form-label {
  font-size: .9rem;
  font-weight: bold; }

.form-label-hint {
  font-size: .9rem; }

.required::after {
  content: ' *';
  color: red; }

.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 35px !important; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 35px !important; }

/**
 * Icons
 */
i.fx {
  min-width: 20px;
  text-align: center; }

/**
 * Followed States
 */
.isActive .fal {
  font-weight: 700; }

/**
 * Cards
 */
.mh-100 {
  min-height: 100px; }

.card.--shadow {
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; }

.mp-card {
  min-height: 15.625rem;
  color: #101820;
  transition: 0.2s ease-in-out all; }
  .mp-card .mp-card__img {
    height: 6.25rem;
    position: relative;
    overflow: hidden; }
    .mp-card .mp-card__img img {
      position: absolute;
      top: 0px;
      left: 0px;
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .mp-card__desc {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  .mp-card:hover {
    border-color: #005eb8; }

/**
 * Notes
 */
.note-item {
  border-left: 4px solid #002f6c; }

/*** Card Tile used by admin tools ***/
.card.card-tile {
  margin-bottom: 1em;
  box-shadow: 0 2px 8px rgba(100, 100, 100, 0.1);
  position: relative;
  margin-left: 1.5rem; }

.card.card-tile .card-body,
.card.card-tile .card-header {
  padding: 1rem 1rem 1rem 2rem; }

.card-icon {
  position: absolute;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  left: -1.5rem;
  top: 1rem;
  margin: -1px 0 0 -1px;
  line-height: 3rem;
  color: #fff;
  text-align: center;
  box-shadow: 0 2px 8px rgba(100, 100, 100, 0.1);
  border: 1px solid #ccc;
  font-size: 1.25rem;
  z-index: 1; }

.card-tile-count {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1em; }

.card-tile-description {
  font-size: 0.9rem;
  margin: 0.25rem 0 0 0;
  font-weight: 700;
  opacity: 0.7; }

.card.card-tile .card-cta {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  width: auto;
  font-size: 0.7em;
  padding: 0.5em 1em; }

/*** Vue Table ***/
.vtl-checkbox-th {
  width: 1%; }

.vtl-both {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC"); }

.vtl-sortable {
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 30px !important; }

.vtl-asc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==); }

.vtl-desc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=); }

.vtl-loading-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: column;
  transition: opacity 0.3s ease; }

.vtl-loading-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center; }

.vtl-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box; }

select {
  width: auto;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  height: auto;
  padding: 0;
  margin-bottom: 0; }

.vtl-table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse; }

th {
  text-align: inherit; }

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit; }

.vtl-table-bordered thead td,
.vtl-table-bordered thead th {
  border-bottom-width: 2px; }

.vtl-table thead th {
  vertical-align: bottom;
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
  border-bottom: 2px solid #dee2e6; }

.vtl-table-bordered td,
.vtl-table-bordered th {
  border: 1px solid #dee2e6; }

.vtl-table td,
.vtl-table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  vertical-align: middle; }

.vtl-table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.vtl-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

.vtl-table-responsive > .vtl-table-bordered {
  border: 0; }

.vtl-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -15px; }

.vtl-pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.limiter-100 {
  color: #aaa; }

.limiter-90 {
  color: #afa09f; }

.limiter-70 {
  color: #b49695; }

.limiter-60 {
  color: #b98d8b; }

.limiter-50 {
  color: #be8381; }

.limiter-40 {
  color: #c47977; }

.limiter-30 {
  color: #c9706d; }

.limiter-30 {
  color: #ce6663; }

.limiter-10 {
  color: #d35c59; }

.limiter-0 {
  color: red; }

/* Global notification widget $notification $global
  =================================================================== */
.notification-banner {
  padding: 1em 4em 1em 5em;
  border-radius: 4px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  background: #fff;
  opacity: .95;
  border: 1px solid #ddd; }

.notification-banner .banner-light {
  position: absolute;
  inset: 0.8em auto 0.8em .8em;
  width: 8px;
  border-radius: 8px;
  background: #aaa; }

.notification-banner .banner-icon {
  position: absolute;
  inset: 50% auto auto 25px;
  margin: -.5em 0 0 0;
  font-size: 2em;
  font-weight: 300;
  color: #aaa;
  width: 50px;
  line-height: 1em;
  text-align: center; }

.notification-banner .banner-icon:before {
  font-family: 'Font Awesome 5 Pro';
  content: '?'; }

.notification-banner .banner-message {
  font-size: 1.15em;
  font-weight: 700;
  color: #000; }

.notification-banner .banner-link {
  display: block;
  max-width: 100%;
  background: #aaa;
  margin-top: 5px;
  color: #fff;
  font-weight: 700; }

.notification-banner .banner-hide {
  position: absolute;
  inset: 50% 10px auto auto;
  border: none;
  color: #aaa;
  line-height: 1em;
  background: none;
  font-size: 1.75em;
  margin: -.5em 0 0 0; }

/*** Notification Types ***/
.notification-banner.notification-banner-info .banner-light, .notification-banner.notification-banner-info .banner-link {
  background: #50A0B5;
  border-color: #50A0B5; }

.notification-banner.notification-banner-info .banner-icon:before {
  content: '\f05a';
  color: #50A0B5; }

.notification-banner.notification-banner-danger .banner-light,
.notification-banner.notification-banner-danger .banner-link {
  background: #dc3545;
  border-color: #dc3545; }

.notification-banner.notification-banner-danger .banner-icon:before {
  content: '\f071';
  color: #dc3545; }

/*** Notification Admin ***/
.notification-list .list-item {
  position: relative;
  padding: 1em;
  border: 1px solid #ccc;
  border-left: 5px solid #ccc;
  background: #fff;
  font-size: .9em; }

.notification-list .list-item.isPast {
  padding-top: .5em;
  padding-bottom: -5em;
  font-size: .75em; }

.notification-list .list-item.type-info {
  border-left-color: #17a2b8; }

.notification-list .list-item.type-danger {
  border-left-color: #dc3545; }

.notification-list .list-item.inactive:empty {
  display: none; }

.notification-list .list-item h4, .notification-list .list-item .h4 {
  font-size: 1.25em;
  line-height: 1.5em;
  margin: 0 200px 0 0;
  padding-right: 1em; }

.notification-list .list-tem.isPast h4, .notification-list .list-tem.isPast .h4 {
  opacity: .65; }

.notification-list .list-item p {
  margin: 0; }

.notification-list .list-item .action-buttons {
  position: absolute;
  top: 1em;
  right: 1em;
  width: 200px; }

.relative {
  position: relative; }

.o-hidden {
  overflow: hidden; }

.sticky-top {
  top: 0.9375rem; }

.sidebar {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  transition: transform 0.4s ease;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

#sidebar-left {
  right: auto;
  left: 0;
  width: 250px;
  overflow-y: hidden;
  height: 100%;
  -o-transition: 0.2s ease-in-out transform;
  -webkit-transition: 0.2s ease-in-out transform;
  transition: 0.2s ease-in-out transform; }
  #sidebar-left:hover {
    overflow-y: auto; }

#close-sidebar-background-mask {
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out all; }

#left-panel .list-group {
  margin-bottom: 0px; }

#left-panel a.list-group-item {
  font-size: 13px;
  line-height: 22px;
  border: none; }

#left-panel .list-group-item {
  display: block;
  font-weight: 400;
  position: relative;
  min-height: 45px;
  padding-left: 50px;
  background: transparent;
  border: none; }
  #left-panel .list-group-item span {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 25px;
    font-size: 18px;
    margin-top: -13px; }
  #left-panel .list-group-item img {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 25px;
    font-size: 18px;
    margin-top: -13px;
    padding: 0 !important; }

#search-wrap {
  display: none; }

body.sidebar-in {
  position: fixed;
  overflow: hidden; }

#global-container {
  max-width: 1440px;
  padding-left: 250px;
  margin: 0 auto;
  position: relative; }

.page-contents {
  padding: 20px;
  /* setting a min height to help prevent noticeable flicker on load for pages light on content...  */
  min-height: 500px; }

#skip-to {
  margin: 0 15px; }

#app-header #left-sidebar-toggle {
  display: none; }

#app-header a {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 20px; }

#searchForm {
  display: inline-block;
  position: relative; }

#search {
  border: none;
  outline: none;
  border-radius: 30px 30px 30px 30px;
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px; }

#search-btn {
  position: absolute;
  top: 0;
  right: 2px;
  font-size: 12px;
  z-index: 10; }

#app-header {
  background: transparent;
  padding: 6px 15px 10px 10px; }

#app-header a.header-btn {
  display: inline-block;
  position: relative;
  font-size: 12px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  padding: 7px 10px;
  width: auto;
  height: auto;
  line-height: 100%;
  margin-left: 5px;
  top: -2px;
  text-decoration: none; }

#app-header a#header-edit-profile {
  padding: 14px;
  top: 8px; }

.header-btn span.count {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
  font-size: 11px;
  color: #fff;
  text-align: center;
  line-height: 15px;
  margin-left: 5px;
  top: -1px; }

.skip-link {
  position: absolute;
  top: -240px;
  left: 0;
  color: white !important;
  background: #353535;
  padding: 8px;
  z-index: 9999; }

.skip-link:focus {
  top: 0; }

/* First breakpoint for left side nav, hide it */
#mobile-flyout {
  display: none !important; }

@media (max-width: 1024px) {
  .hidden-mobile {
    display: none; }
  .hidden-desktop {
    display: block; }
  #global-container {
    padding-left: 0; }
  #sidebar-left {
    transform: translateX(-250px); }
  .sidebar-in #sidebar-left {
    transform: translateX(0px) !important; }
  #app-header #left-sidebar-toggle {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px; }
  #app-header #left-sidebar-toggle .bar {
    width: 25px;
    padding: 2px;
    border-radius: 4px;
    background: white;
    transition: .2s ease-in-out transform; }
  #app-header #left-sidebar-toggle .bar + .bar {
    margin-top: 3px; }
  #app-header #left-sidebar-toggle:hover .bar.-center {
    transform: scaleX(0.8); }
  #close-sidebar-background-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.7); }
  .sidebar-in #close-sidebar-background-mask {
    opacity: 1;
    visibility: visible; }
  body.sidebar-in .sidebar {
    overflow-y: auto !important; }
  #virtual-exhibit-hall .ex-header-img {
    height: auto; }
  .profile-header img {
    height: 100px; }
  body .tile.height-1 {
    padding-top: calc(50% - 100px); }
  body .tile.height-2 {
    padding-top: calc(100% - 200px); }
  .promoted-description {
    margin-right: 26%; }
  #networking-form {
    padding-bottom: 400px; }
  .forget {
    padding-bottom: 200px; }
  #skip-to, #page-agenda, #page-general-information, #page-question-hub-list, #page-followed-items {
    margin: 0 0 0 0;
    border-radius: 0 0 0 0;
    overflow: auto; } }

@media (max-width: 990px) {
  #mobile-flyout {
    display: inline-block !important; } }

@media (min-width: 700px) {
  #custom-nav {
    display: none; }
  p.lead {
    font-size: 22px; }
  .session-list {
    position: relative; }
  #hf-session-container {
    height: 500px; }
  #app-only-pulse-container {
    padding-bottom: 0 !important; }
  #hf-nav-container .tile.height-1 {
    padding-top: calc(50% - 60px); } }

@media (max-width: 768px) {
  body .page-contents {
    height: auto !important;
    min-height: auto !important; }
  #hf-session-container {
    height: 200px; }
  #hero-background {
    max-width: 100%; }
  .promoted-description {
    margin-right: -15px; }
  .bx-wrapper img {
    margin-bottom: 15px; }
  .hidden-sm-down {
    display: none !important; }
  #app-header {
    padding: 10px !important; }
  #pulse-app-container {
    padding: 5px; }
  .p-5, .p-4, .p-3, .p-2, .p-1 {
    padding: 1rem !important; }
  body .page-contents {
    padding: 10px; }
  /* .page-header .col-1 a {
        margin-top: -15px;
    } */
  .profile-header img {
    height: auto;
    border-radius: 4px;
    max-width: 100px; }
  #enable-theatre {
    display: none !important; }
  .tile {
    min-height: 150px; }
  .notification-banner {
    padding-left: 40px;
    padding-right: 40px;
    font-size: .8em; }
  .notification-banner .banner-icon {
    top: 50%;
    left: -10px;
    margin: -18px 0 0;
    width: auto;
    background: #fff;
    padding: 5px;
    border-radius: 6px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
  .notification-banner .banner-light {
    inset: inherit; }
  .notification-banner .banner-hide {
    right: 0;
    left: auto;
    margin: -18px 0 0 0;
    color: #333;
    width: 36px;
    height: 36px; }
  body #global-container {
    padding-top: 0 !important; }
  #page-poll .answer-list {
    position: relative; }
  #page-attendee-search .col-sm-6,
  #page-attendee-search .col-sm-6.mt-5 {
    margin-top: 10px !important; }
  .left-side-login {
    min-height: 0px !important;
    height: auto !important; }
  #login-background {
    padding: 150px 0px; }
  /* fix sweet alert on mobile */
  .swal2-container {
    display: block !important; } }

/*Nav styles*/
@media (max-width: 900px) {
  .profile-header img {
    height: auto; } }

/* new mobile fixes brought over from scla / mite / etc modify as needed */
@media only screen and (max-width: 991px) {
  #virtual-content-column .page-contents {
    min-height: auto !important;
    height: auto !important; }
  #sidebar-panel.mini-nav {
    margin: 0 0 0 0 !important; }
  #sidebar-column {
    margin-left: 0 !important;
    margin-top: 0;
    border-top: 1px solid #ccc; }
  #sidebar-panel.mini-nav {
    border-radius: 0 0 0 0; }
  body .page-contents {
    min-height: 50vh !important; }
  body #favorites-sidebar {
    min-height: 50vh; }
  .form-chat-reply button[type="submit"], #session_chat_form button[type="submit"] {
    margin-top: 5px; }
  #gamification h6, #gamification .h6 {
    font-size: 16px !important; }
  #gamification .mini-subhead small, #gamification .mini-subhead .small {
    color: #fafafa; }
  #page-profile .profile-header a {
    position: relative;
    top: -10px; }
  .page-header h4, .page-header .h4 {
    font-size: 1rem; }
  .page-header h1, .page-header .h1 {
    font-size: 1.2rem; }
  #virtual-main-content {
    padding: 15px !important; }
  .page-header p {
    font-size: 15px; }
  body .session-chat-item-replies {
    padding-top: 0 !important; }
  body .form-chat-reply {
    position: static !important; }
  body .session-chat-item-footer button {
    margin-bottom: 3px; }
  body .form-chat-reply {
    background: #e8e8e8 !important; }
  .modal-open .modal {
    padding-bottom: 100px; } }

/**
* Custom Event Styles
**/
#global-container {
  max-width: 1440px; }

.sidebar-logo-insert {
  border-radius: 0.375rem;
  height: 175px;
  overflow: hidden; }

.rounded-corners {
  border-radius: 0.375rem; }

.btn-link {
  text-decoration: none; }

.accordion-item {
  border-left: none;
  border-right: none;
  border-top: none; }

.fs-sm {
  font-size: 12px; }

.accordion-button:not(.collapsed) {
  box-shadow: none; }

.logo-block {
  height: 150px;
  border-radius: 0.375rem; }

.event-blocks .badge {
  border-radius: 10px; }

.event-blocks .card {
  border: 1px solid #fff; }

.event-blocks .badge.bg-primary {
  background: #edf4ff !important;
  color: #3687fe; }

.page-header {
  margin-top: 20px; }

.venue {
  transition: .2s all; }
  .venue:hover {
    cursor: pointer;
    border: 1px solid #dedede; }
  .venue.active {
    border: 1px solid #3687fe; }

#events-scroll-container {
  border-radius: 8px; }

#events-scroll {
  position: relative; }
  #events-scroll .list-group-item {
    border: none; }
    #events-scroll .list-group-item .date {
      line-height: 100%; }

.loader {
  display: block;
  width: 40px;
  margin: 30px auto; }

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  background: rgba(255, 255, 255, 0.5); }

.text-primary {
  color: #005eb8 !important; }

.btn-primary {
  background: #005eb8 !important; }
  .btn-primary:hover {
    background: #004692 !important; }
  .btn-primary:focus {
    background: #002f6c !important; }

.alert.border-left {
  border-left: 6px solid #005eb8;
  border-radius: 0 0 0 0; }
