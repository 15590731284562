/**
 * Event Theme - Code below should focus on setting colors only, use page specific scss or components.scss for event specific styles
 */

.page-contents {
	background: $white;
}

.page-header,
.alpha-nav-wrap {
	background: $pageheader;
	color: color-contrast($pageheader);
}

.page-header-alt {
	background: mix(black,$pageheader,40);
	color: color-contrast($pageheader);
}

.page-header-alt a,
.page-header-alt .nav-tabs .nav-link,
.page-header-alt .nav-tabs .nav-link.active,
.page-header-alt .nav-tabs .nav-link:hover,
.page-header-alt .nav-tabs .nav-link:focus,
#favorites-sidebar a,
#favorites-sidebar .nav-tabs .nav-link.active,
#favorites-sidebar .nav-tabs .nav-link:hover,
#favorites-sidebar .nav-tabs .nav-link:focus {
	color: color-contrast($pageheader);
}

.page-header a.btn-link,
.page-header a.btn-link:hover,
.page-header a.btn-link:focus {
	color: color-contrast($pageheader);
}

.alpha-nav-item.active {
	background: mix(white,$pageheader,20);
	color: color-contrast($pageheader);
}

#favorites-sidebar {
	background: mix(black,$pageheader,20);
	color: color-contrast($pageheader);
}

#favorites-sidebar a.list-group-item {
	background: transparent;
	color: color-contrast($pageheader);
}

#favorites-sidebar a.list-group-item:hover {
	background: mix(white,$pageheader,20);
}

.bg-primary {
	color: color-contrast($primary);
}

.bg-info {
	color: color-contrast($info);
}

.list-group-item a:not(.btn) {
	color: $list-group-color;
}

#favorites-sidebar .accordion-item, #favorites-sidebar .list-group-item {
	border-color: mix(white,$pageheader,10);
}

#favorites-sidebar .accordion-button, #favorites-sidebar .accordion-item {
	color: color-contrast($pageheader);
}

#favorites-sidebar .accordion-button::after {
	filter: invert(1);
}

#favorites-sidebar .accordion-button:not(.collapsed) {
	background: mix(white,$pageheader,10);
}

#sidebar-column {
	background: white;
}

.btn-success {
	color: #fff !important;
}

.nav-tabs .nav-link, .action-item {
	color: black;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	border-color: $tabcolor;
	transition: unset;
	color: $tabcolor;
}

a.action-item.display, a.action-item:focus {
	border-bottom: 4px solid $tabcolor;
	color: $tabcolor;
}

#app-header a.header-btn {
	background: $customcolor1;
	color: color-contrast($customcolor1);
}

#sidebar-left {
	background: $sidebar;
	color: color-contrast($sidebar);
}

#sidebar-left a, #sidebar-left table {
	color: color-contrast($sidebar);
}

#app-header a#left-sidebar-toggle > .bar {
	background: $white;
}

/* session admin panel */
.notification-banner.notification-banner-info .banner-light, .notification-banner.notification-banner-info .banner-link{
	border-color:$primary;
	background:$primary;
}
.notification-banner.notification-banner-info .banner-icon:before{
	color:$primary;
}
.swal2-popup .swal2-styled.swal2-confirm{
	border-color:$primary;
	background:$primary;
	color: color-contrast($primary);
}