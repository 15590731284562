.page-login {
	#sidebar-left {
		display: none;
	}
	#global-container {
		padding-left: 0;
	}
	.gdpr-scroll {
		max-height: 200px;
		overflow-y: auto;
		padding-right: 20px;
	}


	.btn-light {
		border-color: rgba(0, 94, 184, 1.00);
		background: #fff;
		color: rgba(0, 94, 184, 1.00);
		&:hover {
			border-color: #fff;
			background:  rgba(0, 94, 184, 1.00);
			color: #fff;
		}
	}
	/* Login Page $login =================================================================== */
	#gdpr-scroll {
		height: 140px;
		overflow-y: auto;
		font-size: 12px;
		line-height: 16px;
	}
	#page-login .page-contents {
		min-height: 100vh;
		padding: 0 0 0 0;
	}
	#page-login h1 {
		font-size: 2rem;
	}
	#page-login .left-side-login {
		max-height: 100vh;
		overflow: auto;
	}
	#page-login .page-contents {
		background: transparent !important;
	}
	#page-login .center-form {
		border-radius: 5px;
		min-height: 697px;
		box-shadow: #000 0px 5px 15px;
	}
	#page-login .login-form {
		width: 520px;
		max-width: 520px;
		background: #fff;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	#page-login .teaser-info {
		transition: max-width 0.3s linear 0s, min-width 0.3s linear 0.25s, padding 0.3s linear 0.25s, width 0.3s linear 0.25s;
		width: 340px;
		max-width: 340px;
		padding: 2rem;
		background-color: #1f262d;
		color: #fff;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		background: url("/includes/images/default/bg-login-left.png");
	}
	#page-login .teaser-header {
		display: inline;
		font-size: 46px !important;
		font-weight: 600;
		line-height: 1.25;
		margin: 2px 0;
	}
	#page-login .teaser-header-container {
		padding-bottom: 0;
		padding-top: 15px;
		width: 100%;
	}
	#page-login .teaser-text {
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;
		margin: 2px 0;
		padding-top: 32px;
	}
	#page-login .delay-display {
		visibility: visible;
		transition-delay: 750ms;
	}
	@media only screen and (max-width: 991px) {
		#page-login .page-contents {
			min-height: 100vh !important;
	   }
	}
	@media only screen and (max-width: 883px) {
		#page-login .teaser-info {
			width: 0px;
			max-width: 0px;
			padding: 0px;
			transition: max-width 0.3s linear 0s, min-width 0.3s linear 0.25s, padding 0.3s linear 0.25s, width 0.3s linear 0.25s;
	   }
		#page-login .delay-display {
			visibility: hidden;
			transition-delay: 1ms;
	   }
		#page-login .login-form {
			width: 100%;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
	   }
	}
	@media only screen and (max-width: 576px) {
		#page-login .login-form {
			height: 100vh;
			width: 100%;
			margin: 0rem;
	   }
	}
	#ie-message {
		display: none;
		padding: 40px;
	}
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		#page-login {
			display: none;
	   }
		#ie-message {
			display: block;
	   }
	}
	#mobile-message {
		display: none;
	}
	@media (max-width: 769px) {
		#mobile-message {
			display: block;
	   }
	}
}
