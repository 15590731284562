.video-js.popped-out:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .9);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    z-index: 2;
}

.video-js.popped-out:after {
    content: '\f35d';
    font-family: 'Font Awesome 5 Pro';
    color: #666;
    text-shadow: 1px 1px #ccc;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -.5em;
    text-align: center;
    font-size: 8em;
    z-index: 3;
    transform: rotate(90deg);
}

.persistent-video {
    position: fixed;
    bottom: 10px;
    right: 20px;
    width: 400px;
    height: 225px;
    transform: translateY(300px);
    transition: transform .2s ease-in-out;
    z-index: 10000;
    box-shadow: -2px -2px 20px rgba(0, 0, 0, .15);
    border: 1px solid #fff;
    overflow: hidden;
    border-radius: 6
}

.persistent-video.visible {
    transform: translateY(0);
    transition: transform .2s ease-in-out;
}

.persistent-video.focus {
    animation: pulsing 0.5s infinite;
    -webkit-animation: pulsing 0.5s infinite;
}

@keyframes pulsing {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 51, 87, .5);
        transform: scale(1.1);
    }
    100% {
        box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
        transform: scale(1);
    }
}

@-webkit-keyframes pulsing {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 51, 87, .5);
        transform: scale(1.1);
    }
    100% {
        box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
        transform: scale(1);
    }
}

.persistent-video-inner {
    position: 'absolute';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: '#000';
}

.persistent-video-trigger {
    position: absolute;
    right: 0px;
    bottom: 0px;
    color: rgb(255, 255, 255);
    background: none;
    border: none;
    text-shadow: rgba(0, 0, 0, 0.1) 1px 1px;
    padding: 0.7em 1em 0.8em;
    font-size: 0.75em;
    line-height: 1em;
    opacity: 1;
    display: none;
    visibility: none;
}

[data-allow-persistent="true"] .vjs-control-bar {
    padding-right: 30px;
}

.vjs-has-started+.persistent-video-trigger {
    display: block;
    visibility: visible;
    opacity: 1;
    transition: visibility 1s, opacity 1s;
}

.vjs-has-started.vjs-user-inactive.vjs-playing+.persistent-video-trigger {
    transition: visibility 1s, opacity 1s;
    opacity: 0;
}

.video-js .vjs-big-play-button {
    font-size: 3em;
    line-height: 60px;
    height: 60px;
    width: 60px;
    margin-left: -30px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    margin-top: -30px;
    cursor: pointer;
    opacity: 1;
    border: 0.06666em solid #fff;
    background-color: #2B333F;
    background-color: rgba(43, 51, 63, 0.3);
    border-radius: 0.3em;
    transition: all 0.4s;
    border-radius: 50%;
}

@media only screen and (max-width: 1024px) {
	.persistent-video-trigger {
		display: none !important;
	}
}