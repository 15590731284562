/*======================================
=            Locked Overlay            =
======================================*/
.page-poll {
	#locked-overlay {
		position: absolute;
		z-index: 9999;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: #fff;
	}

	#locked-overlay-content h3 {
		font-weight: bold;
		font-size: 2.2rem;
		text-transform: uppercase;
	}

	#locked-overlay-content p {
		font-size: 1.6rem;
	}

	.form-check-label:hover {
		cursor: pointer;
	}
}