.page-session-zoom {
	#training-welcome .badge {
		font-size: 12px;
		font-weight: bold;
		text-transform: uppercase;
		border-radius: 0 0 0 0;
		padding: 5px 15px 5px 15px;
	}

	.attendee-bubble-row {
		position: relative;
		padding-right: 14.286%;
	}
	.attendee-bubble-row:before,
	.attendee-bubble-row:after {
		content: '';
		display: table;
	}
	.attendee-bubble-row:after {
		clear: both;
	}

	.bubble-item-other {
		position: absolute;
		right: 0;
		top: 0;
	}

	.bubble-item {
		float: left;
		text-align: center;
		width: 14.286%;
	}

	.bubble-photo {
		display: inline-block;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		-webkit-border-radius: 50%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		background-color: #eee;
		border: 1px solid #ccc;
	}

	.bubble-text {
		font-weight: 300;
		font-size: 13px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.bubble-photo.end-item {
		background: #cacaca;
		font-weight: bold;
		line-height: 60px;
		font-size: 14px;
	}


	#zoom-info-container .direction-detail span {
		display: inline-block;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		-webkit-border-radius: 50%;
		text-align: center;
		line-height: 20px;
		background: #202020;
		color: #fff;
		font-weight: bold;
		font-size: 12px;
	}

	.queue-action {
		text-align: center;
		display: inline-block;
		font-size: 13px;
		font-weight: bold;
		text-transform: uppercase;
	}
	.queue-action i {
		display: block;
		margin-bottom: 5px;
		font-size: 24px;
	}

	#cc-tab-4 .session-chat-item {
		padding: 15px 10px 15px 10px;
		overflow: hidden;
		border-bottom: 1px solid #000;
	}

	#cc-tab-4 .chat-user-details {
		padding-left: 70px;
	}

	#cc-tab-4  .chat-user-details .initials {
		top: 5px;
	}

	#cc-tab-4 .session-chat-item a.mark-answered {
		font-size: 22px;
		display: block;
		text-align: center;
		color: rgb(0,179,121);
		margin-top: 10px;
		border-radius: 3px;
	}

	#cc-tab-4 .session-chat-item a.mark-answered:hover {
		background: rgb(0,179,121);
		color: #fff;
	}

	#asked-questions {
		min-height: 400px;
		margin-bottom: 100px;
	}
	/*Tabs */
	.simple-tab {
		display: none;
		padding: 20px 0;
	}

	.simple-tab.current-item {
		display: block;
	}

	.simple-tab-nav a {
		display: inline-block;
		text-decoration: none;
		text-align: center;
		padding: 10px 15px;
		color: #000;
	}

	.simple-tab-nav a.current-item {
		border-bottom: 4px solid #000;
		font-weight: bold;
	}
}